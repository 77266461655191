import { AxiosError } from 'axios';
import { showErrorPage } from './historyWrapper';
import { ErrorDetails } from '../models/errorDetails';

const errorResponseHandler = (error: AxiosError) => {
  let refId = '';

  if (error.response) {
    refId = (error.response.data as ErrorDetails).ReferenceId;
  }

  if (refId === '') {
    // if get here without refId write to console because server-side did not handle the error
    console.log('Untracked error:')
    if (error.response) {
      console.error(error.response);
    }
    else {
      console.error(error);
    }
  }

  // redirect
  showErrorPage(refId);
}

export default errorResponseHandler;