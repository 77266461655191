import { BodyStyleInfo } from "./bodyStyleInfo";

export enum AssignedDriverType {
  NotSet = '',
  Principal = 'Principal',
  MostFrequent = 'Most Frequent'
}

export enum VehicleEntryMode {
  YearMakeModel,
  Vin
}

export class Vehicle {
  id: string = '';
  entryMode: VehicleEntryMode = VehicleEntryMode.YearMakeModel;
  year: number = 0;
  make: string = '';
  model: string = '';
  bodyStyle: BodyStyleInfo | null = null;
  vin: string = '';
  assignedDriverId: string = '';
  assignedDriverType: AssignedDriverType = AssignedDriverType.NotSet;
  use: string = '';
  milesToWork: number | null = null;
  annualMiles: number | null = null;
  includeCompColl: boolean | null = null;
}

