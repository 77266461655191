import axios, { CancelTokenSource, AxiosError, AxiosResponse } from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Fragment } from 'react';
import { FaBuilding, FaCar, FaHome } from 'react-icons/fa';
import { GiFamilyHouse } from 'react-icons/gi';
import { connect } from 'react-redux';
import { Button, Col, Container, Input, Row } from 'reactstrap';
import '../custom.scss';
import './StartQuotePage.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ApplicationState } from '../store';
import { State } from '../store/Site/state';
import { homeownersType } from '../store/Quote/state';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../store/Stepper/actions';
import { Action as ValidationAction, actionCreators as ValidationActions } from '../store/Validation/actions';
import { ThunkDispatch } from 'redux-thunk';
import { updateCrqPage } from '../services/historyWrapper';
import { getBaseUrl, getQueryStringLob } from '../services/helper';
import errorResponseHandler from '../services/errorHandling';
import { useMediaQuery } from 'react-responsive';
import { StepType, stepPage } from '../store/Stepper/state';
import { subscribeToQuoteChanges } from '../store/configureStore';
import Pdf from '../Documents/ConsumerRateQuotes_TermsAndConditions.pdf';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Carousel from 'nuka-carousel';
import { CarrierImages } from '../models/carrierImages';

export class AddressSuggestion {
  display: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  zipCode: string = '';
  verified: boolean = false;
  hasSuites: boolean = false;
}

type StartQuotePageProps =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface StartQuotePageState {
  isPersonalAuto: boolean,
  homeowners: homeownersType,
  fullAddress: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  zip: string,
  addressVerified: boolean,
  addressHasSuites: boolean,
  isStartButtonEnabled: boolean,
  hideAutoLabel: boolean,
  hideHomeownersLabel: boolean,
  addressState: string,
  isStateValid: boolean,
  suggestions: AddressSuggestion[],
  errorText: string,
  isLoading: boolean,
  autoFocus: boolean,
  carrierImages: CarrierImages[]
}

class StartQuotePage extends React.PureComponent<StartQuotePageProps, StartQuotePageState> {
  constructor(props: any) {
    super(props);

    const isAutoFocus = getQueryStringLob(window.location).length > 0;

    let isPersonalAuto = false;
    let isHideAuto: boolean = true;
    let isHideHomeowners: boolean = true;
    let site = this.props.site;
    for (let i: number = 0; i < site.linesOfBusiness!.length; i++) {
      if (site.linesOfBusiness![i].abbreviation === 'PA') {
        isHideAuto = false;
      }
      if (site.linesOfBusiness![i].abbreviation === 'HO') {
        isHideHomeowners = false;
      }
    }
    if (!isHideAuto && isHideHomeowners) {
      // no reason to show auto stuff if homeowners is not shown
      isHideAuto = true;
      // go ahead and select auto because input should be hidden
      isPersonalAuto = true;
    }

    this.state = {
      isPersonalAuto: isPersonalAuto,
      homeowners: homeownersType.None,
      fullAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zip: '',
      addressVerified: false,
      addressHasSuites: false,
      isStartButtonEnabled: false,
      hideAutoLabel: isHideAuto,
      hideHomeownersLabel: isHideHomeowners,
      addressState: '',
      isStateValid: true,
      suggestions: [],
      errorText: '',
      isLoading: false,
      autoFocus: isAutoFocus,
      carrierImages: []
    };

    this.handleAuto = this.handleAuto.bind(this);
    this.handleHomeowners = this.handleHomeowners.bind(this);
    this.enableStartButton = this.enableStartButton.bind(this);
    this.clearSuggestions = this.clearSuggestions.bind(this);
    this.continueQuote = this.continueQuote.bind(this);
    this.addressAutoFill = this.addressAutoFill.bind(this);
  }

  componentDidMount() {
    //For lob startup parameters
    var queryStringLob = getQueryStringLob(window.location);
    if (queryStringLob.includes('auto')) {
      this.setState({ isPersonalAuto: true }, () => this.updateAutoIcons(this.state.isPersonalAuto));
      queryStringLob = queryStringLob.slice(5);
    }

    switch (queryStringLob) {
      case 'home':
        this.setState({ homeowners: homeownersType.Homeowner }, () => this.updateHomeownerIcons(this.state.homeowners));
        break;
      case 'rent':
        this.setState({ homeowners: homeownersType.Renters }, () => this.updateHomeownerIcons(this.state.homeowners));
        break;
      case 'condo':
        this.setState({ homeowners: homeownersType.CondoOwner }, () => this.updateHomeownerIcons(this.state.homeowners));
        break;
    }

    // if starting a new quote from a quote where errors were visible, we need to clear them
    this.props.updateErrorsVisible(false);

    // gets agency carriers for images
    axios.get(`${getBaseUrl()}/carrierImages/${this.props.site.id}`)
      .then((response) => {
        this.setState({
          carrierImages: response.data,
        });
      })
      .catch((error: AxiosError) => {
        if (!axios.isCancel(error)) {
          errorResponseHandler(error);
        }
      });
  }

  render() {
    return (
      <Container>
        {this.renderHeaderText()}
        <this.renderLOBSelectionIcons /> {/*Must be a react function to use media query*/}
        {this.renderAddressInput()}

        {this.props.stepper.selected !== StepType.None &&
          <Button color="link" className="align-bottom pt-2" onClick={this.continueQuote}>
            Continue existing quote
          </Button>
        }

        <this.renderCarrierImages />
        {this.renderDisclaimer()}
      </Container>
    );
  }

  renderHeaderText() {
    let autoHeader = '';
    let introMsg = this.props.site.introScreenMsg || '';

    if (this.state.hideHomeownersLabel) {
      autoHeader = 'Auto ';
    }

    //Add "Auto" to header label when auto only
    let mainMsg = `Get Free ${autoHeader}Insurance Quotes`;
    let selectMsg = `Select your Line of Business - you can bundle auto with homeowners or renters or condo!`;

    return (
      <Fragment>
        <div className='text-center mt-2 mb-3 start-quote-page__header-text-main'>{mainMsg}</div>

        {introMsg !== '' &&
          <div data-testid='introMsg' className='text-center mt-2 mb-3 start-quote-page__header-text-intro'>{introMsg}</div>
        }

        {/* Only show when home or package */}
        {!this.state.hideHomeownersLabel &&
          <div data-testid='selectHeader' className='text-center mt-2 mb-3 text-muted start-quote-page__header-text-select'>{selectMsg}</div>
        }
      </Fragment>
    )
  }

  renderLOBSelectionIcons = () => {
    return (
      <Fragment>
        <Row className='mx-1 mx-md-0 justify-content-around' data-testid='lobIcons'>
          <Col sm='12' md='12' className='bg bg-light py-3 rounded justify-content-around'>
            <Row>
              <Col sm='6' md='3' className='btn-group btn-group-toggle' data-toggle='buttons' hidden={this.state.hideAutoLabel} data-testid='autoIcon'>
                <label className="btn start-quote-page__btn-lob w-100 h-100" id='autoLabel' data-testid='autoLabel'>
                  <FaCar size={64} />
                  <div><input type="checkbox" name="personalAuto" id="auto" onChange={this.handleAuto} checked={this.state.isPersonalAuto} data-testid='autoCheckbox' />Auto</div>
                </label>
              </Col>

              <Col sm='6' md='3' className='btn-group btn-group-toggle' data-toggle='buttons' hidden={this.state.hideHomeownersLabel} data-testid='homeIcon'>
                <label className="btn start-quote-page__btn-lob w-100 h-100 homeOwnerLabels" id='homeLabel' data-testid='homeLabel'>
                  <FaHome size={64} />
                  <div><input type="checkbox" name="homeowners" id="home" value={homeownersType.Homeowner}
                    onChange={this.handleHomeowners} checked={this.state.homeowners === homeownersType.Homeowner} />Homeowners</div>
                </label>
              </Col>

              <Col sm='6' md='3' className='btn-group btn-group-toggle' data-toggle='buttons' hidden={this.state.hideHomeownersLabel} data-testid='rentersIcon'>
                <label className="btn start-quote-page__btn-lob w-100 h-100 homeOwnerLabels" id='rentersLabel' data-testid='rentersLabel'>
                  <FaBuilding size={64} />
                  <div><input type="checkbox" name="homeowners" id="renters" value={homeownersType.Renters}
                    onChange={this.handleHomeowners} checked={this.state.homeowners === homeownersType.Renters} />Renters</div>
                </label>
              </Col>

              <Col sm='6' md='3' className='btn-group btn-group-toggle' data-toggle='buttons' role='group' hidden={this.state.hideHomeownersLabel} data-testid='condoIcon'>
                <label className="btn start-quote-page__btn-lob w-100 h-100 text-nowrap homeOwnerLabels" id='condoLabel' data-testid='condoLabel'>
                  <GiFamilyHouse size={64} />
                  <div><input type="checkbox" name="homeowners" id="condo" value={homeownersType.CondoOwner}
                    onChange={this.handleHomeowners} checked={this.state.homeowners === homeownersType.CondoOwner} /><span className='start-quote-page__text-overflow-center'>Condo Owners</span></div>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    )
  }

  renderAddressInput() {
    return (
      <Fragment>
        <Row className='mt-3 pl-3'>
          <Col className="m-0 p-0" xs={this.state.addressHasSuites ? 7 : 10} sm={this.state.addressHasSuites ? 9 : 11}>
            <AsyncTypeahead
              id="address2"
              isLoading={this.state.isLoading}
              minLength={5}
              onSearch={this.addressAutoFill}
              labelKey="display"
              options={this.state.suggestions}
              placeholder="Enter Your Home Address"
              flip={true}
              delay={250}
              autoFocus={this.state.autoFocus}
              // always return true and trust the server side code for options.  otherwise, options that do not match typed text will not display.
              filterBy={() => true}
              onChange={(suggestion) => this.clearSuggestions(suggestion[0] as AddressSuggestion)}
            />
          </Col>
          <Col className='m-0 p-0' xs={this.state.addressHasSuites ? 2 : 0}>
            <Input className='border-left-0 border-right-0' hidden={!this.state.addressHasSuites} color='primary' id='suiteInput' maxLength={10}
              placeholder='Apt/Unit' name='addressLine2' onChange={(e) => this.handleChange(e)}></Input>
          </Col>
          <Col className='m-0 p-0' xs='2' sm='1'>
            <Button disabled={!this.state.isStartButtonEnabled} color='primary' id='startButton' data-testid='startButton'
              onClick={() => this.updateQuote()}> Start
            </Button>
          </Col>
        </Row>

        <div>
          <small className='text-danger w-100'>
            {this.state.errorText}
          </small>
        </div>
      </Fragment>
    )
  }

  renderDisclaimer() {
    return (
      <div className='text-center mt-4' data-testid='disclaimerText'>
        <p className='text-muted' style={{ fontSize: '0.6rem' }}>
          We may use information from you and other sources, such as your driving record, claims and credit histories to provide you
          with an accurate quote and other important information either via this website, email, phone or text messages. By continuing,
          you (i) authorize us to pull your credit report, (ii) acknowledge that you have reviewed our
          <a href='https://www.vertafore.com/product-privacy-statement' target='_blank' rel='noopener noreferrer'> Privacy Statement </a>
          and <a href={Pdf} target='_blank' rel='noopener noreferrer'> Terms of Use</a>,
          and (iii) consent to the use and processing of all such information.
        </p>
      </div>
    )
  }

  renderCarrierImages = () => {
    var isMobile = useMediaQuery({ maxWidth: 480 });
    var isTablet = useMediaQuery({ maxWidth: 768 });

    return (
      <div className='mt-1' data-testid='carrierImages'>
        <Carousel
          autoplay={true}
          autoplayInterval={2500}
          wrapAround={true}
          disableEdgeSwiping={true}
          dragging={false}
          speed={500}
          withoutControls={true}
          pauseOnHover={false}
          slidesToShow={isMobile ? 2 : (isTablet ? 3 : 4)}
          cellSpacing={isMobile ? 30 : (isTablet ? 10 : 0)}
        >
          {
            this.state.carrierImages.map((image, i) => {
              return (<img className='carrier__image' src={'data:image/image;base64, ' + image.carrierImage} alt={i + 'Slide'} key={i + 'slide'} />);
            })
          }
        </Carousel>
      </div>
    )
  }

  handleAuto(event: { target: { checked: any; }; }) {
    this.setState({
      isPersonalAuto: !this.state.isPersonalAuto
    }, () => this.verifyLOBinState(this.state.addressState));

    this.updateAutoIcons(event.target.checked);
  }

  updateAutoIcons(value: boolean) {
    if (value) {
      document.getElementById('autoLabel')!.classList.add('active');
    } else {
      document.getElementById('autoLabel')!.classList.remove('active');
    }
  }

  handleHomeowners = (event: { target: { value: any; }; }) => {
    if (event.target.value === this.state.homeowners) {
      let index = event.target.value as number;
      let homeOwnerLabels = document.getElementsByClassName('homeOwnerLabels')!;
      let homeOwnerLabel = homeOwnerLabels.item(index - 1)!;
      homeOwnerLabel.classList.remove('active');

      this.setState({
        homeowners: homeownersType.None
      }, () => this.verifyLOBinState(this.state.addressState))
      return;
    };

    this.setState({
      homeowners: event.target.value
    }, () => this.verifyLOBinState(this.state.addressState))

    this.updateHomeownerIcons(event.target.value);
  }

  updateHomeownerIcons(value: string) {
    switch (value) {
      case '1':
        document.getElementById('homeLabel')!.classList.add('active');
        document.getElementById('rentersLabel')!.classList.remove('active');
        document.getElementById('condoLabel')!.classList.remove('active');
        break;
      case '2':
        document.getElementById('homeLabel')!.classList.remove('active');
        document.getElementById('rentersLabel')!.classList.add('active');
        document.getElementById('condoLabel')!.classList.remove('active');
        break;
      case '3':
        document.getElementById('homeLabel')!.classList.remove('active');
        document.getElementById('rentersLabel')!.classList.remove('active');
        document.getElementById('condoLabel')!.classList.add('active');
        break;
    }
  }

  cancelTokenSource: CancelTokenSource | null = null;

  addressAutoFill(query: string) {
    let addressSuggestions: AddressSuggestion[] = [];

    this.setState({
      isLoading: true,
      suggestions: addressSuggestions
    });

    // cancel the previous request if there was one
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }

    this.cancelTokenSource = axios.CancelToken.source();

    axios.get(getBaseUrl() + '/AddressSuggestions?inputAddress=' + encodeURI(query), {
      cancelToken: this.cancelTokenSource.token
    }).then((response) => {
      let address;
      for (let i: number = 0; i < response.data.results.length; i++) {
        address = response.data.results[i].address;

        addressSuggestions[i] = new AddressSuggestion();
        addressSuggestions[i].display = address.addressLine1 + ', ' +
          address.city + ', ' +
          address.state + '  ' +
          address.postalCode.substring(0, 5);
        addressSuggestions[i].address = address.addressLine1;
        addressSuggestions[i].city = address.city;
        addressSuggestions[i].state = address.state;
        addressSuggestions[i].zipCode = address.postalCode;
        addressSuggestions[i].verified = address.verified;
        addressSuggestions[i].hasSuites = address.suiteCount > 1;
      }

      this.setState({
        suggestions: addressSuggestions,
        isLoading: false
      })

      this.cancelTokenSource = null;
    })
      .catch(error => {
        if (!axios.isCancel(error)) {
          this.setState({
            isLoading: false
          })
          errorResponseHandler(error);
        }
      });
  }

  clearSuggestions(suggestion: AddressSuggestion) {
    suggestion = suggestion || '';

    let addressLine1 = '';
    let city = '';
    let addressState = '';
    let zip = '';
    let addressVerified = false;
    let addressHasSuites = false;

    if (suggestion.display != null && suggestion.display.length > 0) {
      //we can assume it is in this format due to selecting a suggestion
      addressLine1 = suggestion.address;
      city = suggestion.city;
      addressState = suggestion.state;
      zip = suggestion.zipCode;
      addressVerified = suggestion.verified;
      addressHasSuites = suggestion.hasSuites;
    }

    this.setState({
      suggestions: [],
      fullAddress: suggestion.display,
      addressLine1,
      city,
      addressState,
      zip,
      addressVerified,
      addressHasSuites
    }, () =>
      this.verifyLOBinState(this.state.addressState)
    )
  }

  enableStartButton() {
    if (this.state.fullAddress != null && this.state.fullAddress.length > 5 && this.state.addressState !== '' && this.state.city !== '' && this.state.zip.length >= 5 &&
      (this.state.isPersonalAuto === true || this.state.homeowners !== homeownersType.None)
      && this.state.isStateValid) {
      this.setState({
        isStartButtonEnabled: true
      })
    }
    else {
      this.setState({
        isStartButtonEnabled: false
      })
    }
  }

  verifyLOBinState(state: string) {
    let indexPA: number = this.props.site.linesOfBusiness!.findIndex(lineOfBusiness => lineOfBusiness.abbreviation === 'PA');
    let indexHO: number = this.props.site.linesOfBusiness!.findIndex(lineOfBusiness => lineOfBusiness.abbreviation === 'HO');
    let indexPP: number = this.props.site.linesOfBusiness!.findIndex(lineOfBusiness => lineOfBusiness.abbreviation === 'PP');
    let statesPA: State[] = [];
    let statesHO: State[] = [];
    let statesPP: State[] = [];

    if (indexPA > -1) {
      statesPA = this.props.site.linesOfBusiness![indexPA].states;
    }
    if (indexHO > -1) {
      statesHO = this.props.site.linesOfBusiness![indexHO].states;
    }
    if (indexPP > -1) {
      statesPP = this.props.site.linesOfBusiness![indexPP].states;
    }

    let isValid = false;
    let newErrorText = 'Online quoting isn\'t available for that state, please contact the agency directly.';

    if ((this.state.fullAddress != null && this.state.fullAddress.length < 8) || this.state.addressState === '') {
      this.setState({
        errorText: ''
      }, () => this.enableStartButton())
      return;
    }

    let statesToCheck: State[] = [];
    if (this.state.isPersonalAuto === true && this.state.homeowners !== homeownersType.None) {
      statesToCheck = statesPP;
    }
    else if (this.state.isPersonalAuto === true) {
      statesToCheck = statesPA;
    }
    else if (this.state.homeowners !== homeownersType.None) {
      statesToCheck = statesHO;
    }

    if (statesToCheck.length > 0) {
      for (let i: number = 0; i < statesToCheck.length; i++) {
        if (state === statesToCheck[i].abbreviation || state === statesToCheck[i].name.toUpperCase()) {
          isValid = true;
          newErrorText = '';
          break;
        }
      }

      this.setState({
        isStateValid: isValid,
        errorText: newErrorText
      }, () => this.enableStartButton());
      return;
    }
    this.setState({
      isStateValid: isValid,
      errorText: 'Please choose a line of business.'
    }, () => this.enableStartButton())
  }

  continueQuote() {
    subscribeToQuoteChanges();
    updateCrqPage(stepPage[this.props.stepper.selected]);
  }

  handleChange(event: { target: { name: string; value: string; }; }) {
    event.target.value = event.target.value.replace(/[^a-zA-Z0-9 #-]/g, '');
    let { name, value } = event.target

    this.setState({
      ...this.state,
      [name]: value
    });
  }

  formatAddressLine2(address: string) {
    let whitelist: string[] = ['Apartment', 'Apt', 'Suite', 'Ste', 'Unit', '#'];
    let addNumber: boolean = true;

    if (address.length === 0) {
      addNumber = false;
    }
    else {
      for (const text of whitelist) {
        if (address.startsWith(text)) {
          addNumber = false;
          break;
        }
      }
    }

    if (addNumber) {
      address = '#' + address;
    }

    return address;
  }

  updateQuote() {
    axios.get(getBaseUrl() + '/AgencyLicense/' + this.props.site.id + '/' + this.state.addressState)
      .then((response: AxiosResponse<string>) => {
        subscribeToQuoteChanges();

        this.props.startQuote(this.state.isPersonalAuto, this.state.homeowners, this.state.fullAddress,
          this.state.addressState, this.state.addressLine1, this.formatAddressLine2(this.state.addressLine2), this.state.city,
          this.state.zip, this.state.addressVerified, response.data);

        this.props.changeStep(StepType.None, StepType.ApplicantDrivers);
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  stepper: state.stepper
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction | ValidationAction>) => ({
  startQuote: (isPersonalAuto: boolean, homeowners: homeownersType, fullAddress: string, addressState: string, addressLine1: string,
    addressLine2: string, city: string, zip: string, addressVerified: boolean, agencyLicNum: string) =>
    dispatch(QuoteActions.startQuote(isPersonalAuto, homeowners, fullAddress, addressState, addressLine1, addressLine2, city, zip, addressVerified, agencyLicNum)),
  changeStep: (current: StepType, selected: StepType) => dispatch(StepperActions.changeStep(current, selected)),
  updateErrorsVisible: (errorsVisible: boolean) => dispatch(ValidationActions.updateErrorsVisible(errorsVisible))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(StartQuotePage as any);