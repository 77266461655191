import React, { Fragment } from 'react';
import { Form, FormGroup, Label, Input, InputGroup, Row, Col, FormFeedback, ButtonGroup } from 'reactstrap';
import './CoveragePage.scss';
import '../../custom.scss';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import Swipeable from '../Swipeable';
import { ApplicationState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { QuestionOption } from '../../store/QuestionOptions/state';
import PopularPriorCarriers from './PopularPriorCarriers';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { getCurrentDate, hasLob } from '../../services/helper';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import classNames from 'classnames';
import { Coverages, LineOfBusinessType, homeownersType } from '../../store/Quote/state';
import { isNil } from 'lodash';
import HelpText from '../HelpText';
import WithMediaQuery from '../WithMediaQuery';

type OwnProps = {
  onPreviousClick?: () => void,
  onNextClick?: () => void,
  isDesktop: boolean
}

type QuestionGroup1Props =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface QuestionGroup1State {
  priorCarrier: string,
  packagePriorCarriersDifferent: boolean | null,
  curCovLevel: string,
  curPolicyExpDate: string,
  curEstPrem: number | null,
  tabKey: string,
  isIE: boolean | null
}

class QuestionGroup1 extends React.PureComponent<QuestionGroup1Props, QuestionGroup1State> {
  constructor(props: any) {
    super(props);

    let coverages = this.props.quote.coverages

    this.state = {
      priorCarrier: coverages.priorCarrier,
      packagePriorCarriersDifferent: coverages.packagePriorCarriersDifferent,
      curCovLevel: coverages.curCovLevel,
      // console logs uncontrolled to controlled warning for this one state only without making sure that it is not undefined first time it renders
      curPolicyExpDate: coverages.curPolicyExpDate || '',
      curEstPrem: coverages.curEstPrem,
      tabKey: '',
      isIE: null
    }

    this.isValid = this.isValid.bind(this);
    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.carrierClickHandler = this.carrierClickHandler.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);

    this.props.setIsValidFunc(this.isValid);
  }

  componentWillUnmount() {
    this.props.updateCoverages({
      ...this.props.quote.coverages,
      priorCarrier: this.state.priorCarrier,
      packagePriorCarriersDifferent: this.state.packagePriorCarriersDifferent,
      curCovLevel: this.state.curCovLevel,
      curPolicyExpDate: this.state.curPolicyExpDate,
      curEstPrem: this.state.curEstPrem
    });
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  componentDidMount() {
    if (this.state.isIE === null) {
      this.setState({ isIE: window.navigator.userAgent.indexOf("MSIE ") > 0 || navigator.appVersion.indexOf('Trident/') > -1 })
    }
  }

  getValidationObject = (): any => {
    return {
      priorCarrier: this.state.priorCarrier,
      packagePriorCarriersDifferent: this.state.packagePriorCarriersDifferent,
      curCovLevel: this.state.curCovLevel,
      curPolicyExpDate: this.state.curPolicyExpDate
    };
  };

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow) {
    return this.props.validation(this.getValidationObject(), validationLevel.Coverages).isPageValid(showErrors);
  }

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  handleChange(event: { target: { name: string; value: string; maxLength: string | number; }; }) {
    let { name, value, maxLength } = event.target
    let saveValue: string | number | boolean;

    if (!isNil(maxLength) && maxLength > -1) {
      const max = Number(maxLength);
      if (value.length > max) value = value.substring(0, max);
    }

    // I wanted to be able to determine the type of the state object property at runtime
    // but I could not find a way to do that when some property values can initially be null.
    switch (name) {
      case 'packagePriorCarriersDifferent':
        saveValue = value.toLowerCase() === "true";
        break;
      case 'curEstPrem':
        value = value.replace(/\D/g, ''); //remove unwanted characters from copy & paste
        saveValue = Number(value);
        break;
      default:
        saveValue = value;
    }

    this.setState({
      ...this.state,
      [name]: saveValue
    });
  }

  carrierClickHandler(carrier: string) {
    this.setState({
      priorCarrier: carrier
    });
  }

  handleDate(event: { target: { name: string; value: string; }; }) {
    const { name } = event.target;

    //fallback for IE 10 and other browsers that might not support date as an input type
    if ((document.getElementsByName(name)[0]! as HTMLInputElement).type === 'text') {
      let dateDisplay = event.target.value;
      if (this.state.curPolicyExpDate.length < dateDisplay.length) {
        dateDisplay = dateDisplay.replace(/[^0-9]/g, '').slice(0, 8);
        if (dateDisplay.length >= 5) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2, 4) + '/' + dateDisplay.slice(4);
        }
        else if (dateDisplay.length >= 3) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2);
        }
        if (dateDisplay.length === 2 || dateDisplay.length === 5) {
          dateDisplay = dateDisplay + '/';
        }
      }
      event.target.value = dateDisplay;
    }

    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  }

  getPriorCarrierDisplay(text: string) {
    text = text || '';
    if (this.props.quote.lineOfBusiness === LineOfBusinessType.Package) {
      text = text.replace('Current Insurance', 'Current Auto Insurance');
    }
    return text;
  }

  homeNameLookup = (key: homeownersType): string => {
    const homeLookup: { [key: string]: string } = {
      [homeownersType.Homeowner]: 'home',
      [homeownersType.Renters]: 'renters',
      [homeownersType.CondoOwner]: 'condo',
      [homeownersType.None]: ''
    }
    return homeLookup[key];
  };

  render() {
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Coverages);

    const { hasAuto, hasHome } = hasLob(this.props.quote);

    const priorInvalid = isFieldInvalid(validationConfig.coverages.priorCarrier)
    const showAutoQuestions = hasAuto && this.state.priorCarrier !== '' && this.state.priorCarrier !== 'None';
    const showCurrentPremium = (this.state.priorCarrier !== '' && this.state.priorCarrier !== 'None') || this.state.packagePriorCarriersDifferent;

    let priorCoverageErrors = getErrorText(validationConfig.coverages.priorCarrier);
    if (priorCoverageErrors !== null) {
      priorCoverageErrors = this.getPriorCarrierDisplay(priorCoverageErrors[0].props.children);
    }

    let estimatedPremiumLabel = 'Current estimated annual premium';
    if (hasAuto && hasHome) {
      estimatedPremiumLabel = `Current estimated annual auto/${this.homeNameLookup(this.props.quote.homeowners)} premium`;
    }

    let priorCompanyDifferentText = '';
    let priorCompanyDifferentError: any;
    if (hasHome) {
      priorCompanyDifferentText = this.props.quote.homeowners !== homeownersType.Homeowner ?
        (this.props.quote.homeowners === homeownersType.Renters ? 'Is current renters insurance company different than auto'
          : 'Is current condo insurance company different than auto') : validationConfig.coverages.packagePriorCarriersDifferent.display;
      priorCompanyDifferentError = priorCompanyDifferentText + ' is required!';
    }

    const setTabKey = (key: string) => {
      this.setState({ tabKey: key });
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      // shift + tab
      if (e.which === 9 && e.shiftKey) {
        setTabKey('carrier');
      }
    };

    const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
      // don't allow decimal or enter (enter causes form to submit on this control and puts form data in url and cause site not found page)
      (e.key === '.' || e.which === 13) && e.preventDefault()
    };

    return (
      <Fragment>
        <h3 className='text-center'>Current Insurance</h3>
        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              <FormGroup>
                <Label for={validationConfig.coverages.priorCarrier.fieldName}>{this.getPriorCarrierDisplay(validationConfig.coverages.priorCarrier.display)}:</Label>
                <div style={{ height: 'auto' }} className={classNames('form-control', { 'is-invalid py-0 pl-0': priorInvalid, 'border border-0 bg-light p-0': !priorInvalid })}>
                  <PopularPriorCarriers carrier={this.state.priorCarrier} onClick={this.carrierClickHandler}
                    tabKey={this.state.tabKey} setTabKey={setTabKey} isDesktop={this.props.isDesktop} />
                </div>
                <FormFeedback>{priorCoverageErrors}</FormFeedback>
              </FormGroup>

              {showAutoQuestions &&
                <Fragment>
                  <FormGroup>
                    <ValidationLabel config={validationConfig.coverages.curCovLevel} />
                    <HelpText text={validationConfig.coverages.curCovLevel.helpText} helpId='curCovLevel' />
                    <div>
                      <select value={this.state.curCovLevel}
                        onChange={this.handleChange} {...getFieldProps(validationConfig.coverages.curCovLevel, 'coverage-page__curCovLevel custom-select')}
                        onKeyDown={onKeyDown}>
                        <option value=''>{'<select>'}</option>
                        {
                          (this.props.questionOptions["PriorCoverage"] || []).map((option: QuestionOption, index: number) => (
                            <option key={index} value={option.value}>{option.text}</option>
                          ))
                        }
                      </select>
                      <FormFeedback>{getErrorText(validationConfig.coverages.curCovLevel)}</FormFeedback>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ValidationLabel config={validationConfig.coverages.curPolicyExpDate} style={{ whiteSpace: 'normal' }} />
                    <Input type='date'
                      {...getFieldProps(validationConfig.coverages.curPolicyExpDate)}
                      value={this.state.curPolicyExpDate}
                      placeholder='mm/dd/yyyy'
                      min='1900-01-01'
                      max={getCurrentDate(1)}
                      onChange={this.handleDate}>
                    </Input>
                    <FormFeedback>{getErrorText(validationConfig.coverages.curPolicyExpDate)}</FormFeedback>
                  </FormGroup>
                </Fragment>
              }

              {
                (this.props.quote.lineOfBusiness === LineOfBusinessType.Package) &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.coverages.packagePriorCarriersDifferent}>{priorCompanyDifferentText}?</ValidationLabel>
                  <div>
                    <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.coverages.packagePriorCarriersDifferent) })} >
                      {
                        ([{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }] as QuestionOption[])
                          .map((option: QuestionOption, index: number) => (
                            <label
                              {...(!showAutoQuestions && { onKeyDown })}
                              className={classNames('btn btn-option coverage-page__pkgPriorCarriersDiff', { active: option.value === this.state.packagePriorCarriersDifferent + '' })}
                              data-toggle='buttons'
                              key={index}>
                              <input type="radio" name={validationConfig.coverages.packagePriorCarriersDifferent.fieldName} onChange={this.handleChange} value={option.value}
                              />
                              {option.text}
                            </label>
                          ))
                      }
                    </ButtonGroup>
                    <FormFeedback className={classNames({ 'd-block': isFieldInvalid(validationConfig.coverages.packagePriorCarriersDifferent) })}>
                      {priorCompanyDifferentError}
                    </FormFeedback>
                  </div>
                </FormGroup>
              }

              {showCurrentPremium &&
                <FormGroup>
                  <Label for='curEstPrem'>{estimatedPremiumLabel}: <small>(optional)</small></Label>
                  <InputGroup className='pl-0 coverage-page__curEstPrem'>
                    <div className='input-group-prepend'><span className='input-group-text'>$</span></div>
                    <Input type={this.state.isIE ? 'text' : 'number'}
                      data-testid='curEstPrem'
                      pattern='\d*'
                      name='curEstPrem'
                      id='curEstPrem'
                      value={this.state.curEstPrem ? this.state.curEstPrem : ''}
                      maxLength={7}
                      onChange={this.handleChange}
                      {...{ onKeyPress }}
                      {...(!showAutoQuestions && { onKeyDown })}>
                    </Input>
                  </InputGroup>
                </FormGroup>
              }
            </Form>
          </Swipeable>

        </div>
        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Fragment >
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote,
  questionOptions: state.questionOptions.options || {}
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateCoverages: (coverages: Coverages) =>
    dispatch(QuoteActions.updateCoverages(coverages)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
});

export default WithMediaQuery([{ PropName: "isDesktop", Query: { minWidth: 768 } }])(validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup1)));