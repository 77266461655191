import React, { Fragment } from 'react';
import DriverPagePersonalQuestions from './DriverPagePersonalQuestions';
import DriverPagePrivateQuestions from './DriverPagePrivateQuestions';
import { Driver } from '../../store/Quote/state';
import Tabs from '../Tabs';
import { ApplicationState } from '../../store';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { Action as ValidationAction, actionCreators as ValidationActions } from '../../store/Validation/actions';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import Stepper from '../Stepper';
import { hasLob } from '../../services/helper';
import { StepType } from '../../store/Stepper/state';
import DriverPageIncidents from './DriverPageIncidents';
import { validationConfig } from '../../services/validationConfig';

export enum QuestionGroups {
  personal = 1,
  private = 2,
  incidents = 3
};

type DriverPageProps =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface DriverPageState {
  currentQuestionGroup: number,
  currentTabIndex: number
}

class DriverPage extends React.PureComponent<DriverPageProps, DriverPageState> {
  constructor(props: any) {
    super(props);

    // add first driver if there isn't one
    if (props.drivers.length === 0) {
      props.addDriver();
    }

    let startQuestionGroup = QuestionGroups.personal;
    let startTabIndex = 0;

    if (this.props.isBackPage) {
      if (this.props.validationErrors && this.props.validationErrors.length > 0) {
        let error = this.props.validationErrors[0];
        startTabIndex = (error.hierarchy.index as number);
        this.setTotalGroups(this.props.quote.drivers[startTabIndex].hasIncidents || false);
        startQuestionGroup = validationConfig[error.level][error.field].questionGroup;
      }
      else {
        startTabIndex = this.props.drivers.length - 1;
        this.setTotalGroups(this.props.quote.drivers[startTabIndex].hasIncidents || false);
        startQuestionGroup = this.totalGroups;
      }

      this.props.clearIsBackPage();
    }
    else {
      this.setTotalGroups(props.drivers.length > 0 ? this.props.quote.drivers[startTabIndex].hasIncidents || false : false);
    }

    this.state = {
      currentQuestionGroup: startQuestionGroup,
      currentTabIndex: startTabIndex
    }

    this.tabClickHandler = this.tabClickHandler.bind(this);
    this.addClickHandler = this.addClickHandler.bind(this);
    this.removeClickHandler = this.removeClickHandler.bind(this);
    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.setTotalGroups = this.setTotalGroups.bind(this);
  }

  totalGroups: number = 1;

  setTotalGroups(hasIncidents: boolean) {
    if (hasIncidents) {
      this.totalGroups = 3;
    }
    else {
      this.totalGroups = 2;
    }
  }

  tabClickHandler(tabIndex: number) {
    this.setState({
      currentQuestionGroup: QuestionGroups.personal,
      currentTabIndex: tabIndex
    });
  }

  addClickHandler() {
    const newTabIndex = this.props.drivers.length;

    this.props.addDriver();

    this.setState({
      currentQuestionGroup: QuestionGroups.personal,
      currentTabIndex: newTabIndex
    })
  }

  removeClickHandler(tabIndex: number) {
    // if the tab being removed is before the current tab then back up one (so user will stay on current driver)
    // if the tab being removed is the last tab and is also the current tab then back up one (user will go to the new last driver)
    let newTabIndex = this.state.currentTabIndex;
    if ((tabIndex < this.state.currentTabIndex) ||
      (tabIndex === this.props.drivers.length - 1 && tabIndex === this.state.currentTabIndex)) {
      newTabIndex = newTabIndex - 1;
    }

    this.props.removeDriver(tabIndex);

    this.props.updateErrorsVisible(false);

    this.setState({
      currentQuestionGroup: QuestionGroups.personal,
      currentTabIndex: newTabIndex
    });
  }

  previousClickHandler() {
    this.props.updateErrorsVisible(false);

    if (this.state.currentQuestionGroup === 1) {
      if (this.state.currentTabIndex === 0) {
        // no where to go, so do nothing
      }
      else {
        const newCurrentTabIndex = this.state.currentTabIndex - 1;

        this.setTotalGroups(this.props.drivers[newCurrentTabIndex].hasIncidents || false);

        this.setState({
          currentTabIndex: newCurrentTabIndex,
          currentQuestionGroup: this.totalGroups
        });
      }
    }
    else {
      this.setState({
        currentQuestionGroup: this.state.currentQuestionGroup - 1
      });
    }
  }

  nextClickHandler() {
    if (this.state.currentQuestionGroup === this.totalGroups) {
      if (this.state.currentTabIndex + 1 === this.props.drivers.length) {
        // last driver / last page, goto next step
        const { hasAuto } = hasLob(this.props.quote);
        this.props.changeStep(StepType.ApplicantDrivers, hasAuto ? StepType.Vehicles : StepType.HomeCharacteristics);
      }
      else {
        this.setState({
          currentTabIndex: this.state.currentTabIndex + 1,
          currentQuestionGroup: 1
        });
      }
    }
    else {
      this.setState({
        currentQuestionGroup: this.state.currentQuestionGroup + 1
      });
    }
  }

  render() {
    let tabItems = this.props.drivers.map((driver: Driver, index: number) => {
      if (driver.firstName !== '') {
        return `${driver.firstName}`;
      }
      else {
        return "Driver " + (index + 1);
      }
    });
    const { hasAuto } = hasLob(this.props.quote);

    return (
      <Fragment>
        <Stepper />

        {hasAuto &&
          <Tabs currentIndex={this.state.currentTabIndex}
            items={tabItems}
            itemType="Driver"
            onTabClick={this.tabClickHandler}
            onRemoveClick={this.removeClickHandler}
          />
        }

        {(this.state.currentQuestionGroup === QuestionGroups.personal) &&
          this.props.drivers[this.state.currentTabIndex] &&
          <DriverPagePersonalQuestions currentIndex={this.state.currentTabIndex}
            key={this.props.drivers[this.state.currentTabIndex].id}
            onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler} onAddClick={this.addClickHandler} />
        }
        {(this.state.currentQuestionGroup === QuestionGroups.private) &&
          this.props.drivers[this.state.currentTabIndex] &&
          <DriverPagePrivateQuestions currentIndex={this.state.currentTabIndex}
            key={this.props.drivers[this.state.currentTabIndex].id}
            onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler}
            onAddClick={this.addClickHandler}
            handleIncidents={this.setTotalGroups} />
        }
        {(this.state.currentQuestionGroup === QuestionGroups.incidents) &&
          this.props.drivers[this.state.currentTabIndex] &&
          <DriverPageIncidents currentIndex={this.state.currentTabIndex}
            key={this.props.drivers[this.state.currentTabIndex].id}
            onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler}
            onAddClick={this.addClickHandler} />
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote,
  drivers: state.quote.drivers,
  isBackPage: state.stepper.isBackPage,
  validationErrors: state.validation.errors
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction | ValidationAction>) => ({
  addDriver: () => dispatch(QuoteActions.addDriver()),
  removeDriver: (index: number) => dispatch(QuoteActions.removeDriver(index)),
  changeStep: (current: StepType, selected: StepType) => dispatch(StepperActions.changeStep(current, selected)),
  clearIsBackPage: () => dispatch(StepperActions.clearIsBackPage()),
  updateErrorsVisible: (errorsVisible: boolean) => dispatch(ValidationActions.updateErrorsVisible(errorsVisible))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(DriverPage as any);