import React, { memo } from 'react';
import { getBaseUrl } from '../services/helper';

interface SiteSealInfo {
  hostname: string;
  cid: string;
  tag: string;
}

interface DigiCertSealState {
  siteInfo: SiteSealInfo | null;
}

class DigiCertSeal extends React.PureComponent<{}, DigiCertSealState> {

  constructor(props: any) {
    super(props);

    this.digicertSeal = React.createRef();

    this.state = {
      siteInfo: this.getSiteInfo()
    };
  }

  digicertSeal: React.RefObject<HTMLDivElement>;

  componentDidMount() {
    if (this.state.siteInfo) {
      const script = `
var __dcid = __dcid || [];
__dcid.push({"cid":"${this.state.siteInfo.cid}","tag":"${this.state.siteInfo.tag}"});
(function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
`;

      const s = document.createElement('script');
      s.setAttribute('data-testid', 'DigiCertSealScript')
      s.type = 'text/javascript';
      s.async = true;
      s.innerHTML = script;

      if (this.digicertSeal.current) {
        this.digicertSeal.current.appendChild(s);
      }
    }
  }

  siteSeals: SiteSealInfo[] = [
    {
      hostname: 'secure.ratingcrqstg.vertafore.com',
      cid: 'DigiCertClickID_qXlbTYmd',
      tag: 'qXlbTYmd'
    },
    {
      hostname: 'secure.consumerratequotes.com',
      cid: 'DigiCertClickID_KqOwldZ3',
      tag: 'KqOwldZ3'
    }
  ];

  getSiteInfo(): SiteSealInfo | null {
    var hostname = window.location.hostname.toLowerCase();

    let siteInfo: SiteSealInfo[] = this.siteSeals.filter(siteInfo => siteInfo.hostname === hostname);

    return siteInfo[0] || null;
  }

  public render() {
    return (
      <div ref={this.digicertSeal}>
        {/* render just the image if using an internal certificate */}
        {!this.state.siteInfo &&
          <img src={getBaseUrl() + '/images/digicertseal.png'} alt='DigiCert Seal' />
        }
        {/* render DigiCert script if using DigiCert certificate */}
        {this.state.siteInfo &&
          <div id={this.state.siteInfo.cid} data-testid="DigiCertSealDiv"></div>
        }
      </div>
    )
  }
}

export default memo(DigiCertSeal);
