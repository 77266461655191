import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import moment from 'moment';
import { ErrorInfo, ErrorHierachy } from '../store/Validation/state';
import { isString, isArray, isNil, cloneDeep } from 'lodash';
import { ValidationQuoteInfo, getValidationLevel } from './validation';
import { validationConfig, validationLevel } from './validationConfig';
import { Vehicle, VehicleEntryMode } from '../store/Quote/Vehicle/vehicle';

export class validator {
  constructor(rootObject: any) {
    this.rootObject = rootObject;
  }
  rootObject: any;
  validateIteration = (quoteInfo: ValidationQuoteInfo, objToValidate: any, level: validationLevel, errors: ErrorInfo[], errorHierachy: ErrorHierachy): void => {
    if (isArray(objToValidate)) {
      // if conditionIndex is not specified, iterate over all items
      // else just do the one that was requested
      if (quoteInfo.conditionIndex === undefined) {
        objToValidate.forEach((checkObj, index) => {
          // apply iteration index to conditionIndex
          const iterationQuoteInfo = {
            ...quoteInfo,
            conditionIndex: index
          };

          const iterationHierarchy = cloneDeep(errorHierachy);
          this.getLastHierarchy(iterationHierarchy).index = index;

          this.validateFields(iterationQuoteInfo, checkObj, level, index, errors, iterationHierarchy);
        });
      }
      else {
        const iterationHierarchy = cloneDeep(errorHierachy);
        this.getLastHierarchy(iterationHierarchy).index = quoteInfo.conditionIndex;

        // the note below applies here too.
        this.validateFields(quoteInfo, objToValidate[quoteInfo.conditionIndex], level, quoteInfo.conditionIndex || 0, errors, iterationHierarchy);
      }
    }
    else {
      // conditionIndex and index are different here
      // no iteration means no index in object to validate, 
      // but might sent basic object that really was part of an array.
      // In that case, use the conditionIndex provided (if the caller told us the position in the array) or just use 0 if they didn't
      this.validateFields(quoteInfo, objToValidate, level, quoteInfo.conditionIndex || 0, errors, errorHierachy);
    }
  };
  validateFields = (quoteInfo: ValidationQuoteInfo, objToValidate: any, level: validationLevel, index: number, errors: ErrorInfo[], errorHierachy: ErrorHierachy): void => {
    // tack lob onto object to narrow down parameter passing and help out validators
    const newObjToValidate = {
      ...objToValidate,
      ...quoteInfo
    };
    for (let fieldName in objToValidate) {
      if (isArray(newObjToValidate[fieldName])) {
        // when validating a child array, validate all items
        let newQuoteInfo = {
          ...quoteInfo,
          conditionIndex: undefined
        };

        const fleldLevel = getValidationLevel(fieldName); 
        if (fleldLevel !== validationLevel.None) {
          const newLevelHierarchy = cloneDeep(errorHierachy);
          this.getLastHierarchy(newLevelHierarchy).child = new ErrorHierachy(fleldLevel, undefined);
          this.validateIteration(newQuoteInfo, newObjToValidate[fieldName], fleldLevel, errors, newLevelHierarchy);
        }
      }
      else if (typeof newObjToValidate[fieldName] === 'object') {
        const fleldLevel = getValidationLevel(fieldName);

        // if the the object is null or doesn't have its own validation, treat it like a normal field
        if (isNil(newObjToValidate[fieldName]) || fleldLevel === validationLevel.None) {
          // normal field
          let fieldValue = this.getCleanString(newObjToValidate[fieldName]);
          this.validateField(level, index, fieldName, fieldValue, errors, newObjToValidate, errorHierachy);
        }
        else {
          // if the object has its own validation, run that
          const fleldLevel = getValidationLevel(fieldName);
          if (fleldLevel !== validationLevel.None) {
            this.validateFields(quoteInfo, newObjToValidate[fieldName], fleldLevel, index, errors, errorHierachy);
          }
        }
      }
      else {
        // normal field
        let fieldValue = this.getCleanString(newObjToValidate[fieldName]);
        this.validateField(level, index, fieldName, fieldValue, errors, newObjToValidate, errorHierachy);
      }
    }
  };
  getCleanString = (value: any): string => {
    if (!isNil(value) && !isString(value)) {
      value = value + '';
    }
    return value || '';
  };
  validateField = (level: validationLevel, index: number, fieldName: string, fieldValue: string, errors: ErrorInfo[], objToValidate: any, errorHierachy: ErrorHierachy): void => {
    let newError: ErrorInfo = {
      level: level,
      index,
      field: fieldName,
      errors: [],
      hierarchy: errorHierachy
    };
    const fieldConfig = validationConfig[level][fieldName] || {};
    for (let validatorName in fieldConfig.validators) {
      const validatorConfig = fieldConfig.validators[validatorName];
      // move display name into validator config to keep from passing more props around
      validatorConfig.display = fieldConfig.display;
      const errorMessage = this.runValidator(validatorName, validatorConfig, fieldValue, objToValidate);
      if (errorMessage) {
        newError.errors.push(errorMessage);
      }
    }
    if (newError.errors.length > 0) {
      errors.push(newError);
    }
  };
  runValidator = (validatorName: string, validatorConfig: any, fieldValue: string, objToValidate: any) => {
    const validator = this.validators[validatorName];
    return validator(validatorConfig, fieldValue || '', objToValidate);
  };
  isValidatorConditionMet = (config: any, objToValidate: any): boolean => {
    const conditions: any[] = config.conditions || [];
    let isConditionValid: boolean = true;
    if (conditions.length > 0) {
      for (let condition of conditions) {
        if (typeof condition === 'function') {
          isConditionValid = condition.call(this, objToValidate, this.rootObject)
        }
        else {
          for (let validatorName in condition.config) {
            const validatorConfig = condition.config[validatorName];
            // display as fieldname for a more meaning message during debug. condition messages will never be shown.
            validatorConfig.display = validationConfig.field;
            // make sure we have a string coming into validator
            const fieldValue = this.getCleanString(objToValidate[condition.field]);
            isConditionValid = this.runValidator(validatorName, validatorConfig, fieldValue, objToValidate) === null;

            if (!isConditionValid) {
              break;
            }
          }
        }

        if (!isConditionValid) {
          break;
        }
      };
    }
    return isConditionValid;
  };
  buildValidationMessage = (displayValue: string, validationMessage: string): string => {
    displayValue = displayValue || '';
    return displayValue.replace('?', '').trim() + ' ' + validationMessage.trim();
  };

  getLastHierarchy = (errorHierachy: ErrorHierachy): ErrorHierachy => {
    if (errorHierachy) {
      while (errorHierachy.child) {
        errorHierachy = errorHierachy.child;
      }
    }
    return errorHierachy;
  }

  validators: any = {
    // condition will apply if the value is in the array
    appliesTo: (config: any, value: string) => {
      const options = config.options.map((v: string) => v.toLowerCase()) || [];
      // just need a string to apply to
      return options.indexOf(value.toLowerCase()) === -1 ? 'appliesTo' : null;
    },
    // condition will apply if the value is NOT in the array
    skip: (config: any, value: string) => {
      const options = config.options.map((v: string) => v.toLowerCase()) || [];
      // just need a string to skip
      return options.indexOf(value.toLowerCase()) > -1 ? 'skip' : null;
    },
    // validators are from https://github.com/validatorjs/validator.js
    isRequired: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (this.isValidatorConditionMet(config, objToValidate)) {
        if (isEmpty(value) || value === '<select>') {
          message = this.buildValidationMessage(config.display, 'is required!');
        }
      }
      return message;
    },
    // seeing this used in special conditions. leaving commented out for not because cannot unit test until in config file
    //isValidOption: (config: any, value: string, objToValidate: any) => {
    //  let message = null;
    //  if (value.length > 0 && this.isValidatorConditionMet(config, objToValidate)) {
    //    const options = config.options.map((v: string) => v.toLowerCase()) || [];
    //    message = !options.include(value.toLowerCase()) ? `${config.display} is not a valid option!` : null;
    //  }
    //  return message;
    //},
    isValidEmail: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (value.length > 0 && this.isValidatorConditionMet(config, objToValidate)) {
        message = !isEmail(value) ? `${config.display} is not valid!` : null;
      }
      return message;
    },
    isValidDate: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (value.length > 0 && this.isValidatorConditionMet(config, objToValidate)) {
        if (value.indexOf('/') > -1) {
          // coming from IE to get here
          const parts = value.split('/');
          value = `${parts[2]}-${parts[0]}-${parts[1]}`;
        }
        const isValid = moment(value, 'YYYY-MM-DD', true).isValid();
        if (isValid) {
          const checkDate = new Date(value);
          const minDate = new Date(config.min);
          const maxDate = new Date(config.max);
          if (checkDate.getTime() < minDate.getTime()) {
            message = this.buildValidationMessage(config.display, 'is below minimum date!');
          }
          else if (checkDate.getTime() > maxDate.getTime()) {
            message = this.buildValidationMessage(config.display, 'is above maximum date!');
          }
        }
        else {
          message = this.buildValidationMessage(config.display, 'is not valid!');
        }
      }
      return message;
    },
    isValidNumber: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (value.length > 0 && this.isValidatorConditionMet(config, objToValidate)) {
        let number: number = Number(value);
        if (config.min) {
          let min: Number;
          if (typeof config.min === 'function') {
            min = config.min.call(this, objToValidate);
          }
          else {
            min = config.min;
          }
          if (number < min) {
            if (number === 0) {
              message = this.buildValidationMessage(config.display, 'is required!');
            }
            else {
              message = this.buildValidationMessage(config.display, `is below minimum value (${min})!`);
            }
          }
        }
        // the isNil(message) check here prevents checking max if we already found a problem with min
        if (config.max && isNil(message)) {
          let max: Number;
          if (typeof config.max === 'function') {
            max = config.max.call(this, objToValidate);
          }
          else {
            max = config.max;
          }
          if (number > max) {
            message = this.buildValidationMessage(config.display, `is above maximum value (${max})!`);
          }
        }
      }
      return message;
    },
    isValidPhone: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (value.length > 0 && this.isValidatorConditionMet(config, objToValidate)) {
        message = !isMobilePhone(value, 'en-US') ? this.buildValidationMessage(config.display, 'is not valid!') : null;
      }
      return message;
    },
    isValidSsn: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (value.length > 0 && this.isValidatorConditionMet(config, objToValidate)) {
        const pattern = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
        message = !pattern.test(value) ? this.buildValidationMessage(config.display, 'is not valid!') : null;
      }
      return message;
    },
    isValidAssignedDriver: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (value.length > 0 && this.isValidatorConditionMet(config, objToValidate)) {
        if (!isNil(this.rootObject.drivers) && !isNil(this.rootObject.vehicles)) {
          // MA rules are different than other states
          if (this.rootObject.addressState !== 'MA') {
            let { drivers, vehicles } = this.rootObject;

            if (vehicles.length >= drivers.length) {
              // each driver must be assigned to a vehicle
              for (let driverIndex = 0; driverIndex < drivers.length; driverIndex++) {
                if (vehicles.findIndex((vehicle: Vehicle) => vehicle.assignedDriverId === drivers[driverIndex].id) === -1) {
                  message = "Each driver must operate a vehicle!";
                  break;
                }
              }
            }
            else {
              // cannot have the same driver assigned to 2+ vehicles
              for (let vehicleIndex = 0; vehicleIndex < vehicles.length; vehicleIndex++) {
                if (vehicles.filter((vehicle: Vehicle) => vehicle.assignedDriverId === vehicles[vehicleIndex].assignedDriverId).length > 1) {
                  message = "Each driver can only operate one vehicle!";
                  break;
                }
              }
            }
          }
        }
      }

      return message;
    },
    isValidVin: (config: any, value: string, objToValidate: any) => {
      let message = null;
      if (objToValidate.entryMode === VehicleEntryMode.Vin && value.length > 0 && value.length < 17) {
        message = this.buildValidationMessage(config.display, 'must be 17 characters long!');
      }
      return message;
    }
  };
}
