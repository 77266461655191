import React, { Fragment } from 'react';
import { Row, Col, Label, Input, Form, FormGroup, FormFeedback, ButtonGroup } from 'reactstrap';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import AddButton from '../AddButton';
import Swipeable from '../Swipeable';
import { contactMethod, Driver, Incident } from '../../store/Quote/state';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { ApplicationState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import { QuestionOption } from '../../store/QuestionOptions/state';
import './DriverPage.scss'
import validation, { ValidationProps, ShowErrors } from '../validation';
import classNames from 'classnames';
import { getCurrentDate, hasLob, getBaseUrl } from '../../services/helper';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import axios, { AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling';

type LeadOption = {
  category: string,
  leads: string[]
}

type OwnProps = {
  currentIndex: number,
  onPreviousClick?: () => void,
  onNextClick?: () => void,
  onAddClick?: () => void
}

type DriverPagePersonalQuestionsProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface DriverPagePersonalQuestionsState {
  firstName: string,
  middleInitial: string,
  lastName: string,
  email: string,
  phone: string,
  preferredContact: contactMethod,
  dateOfBirth: string,
  relationship: string,
  leadOptions: LeadOption[],
  lead: string,
  isAndroid: boolean
}

class DriverPagePersonalQuestions extends React.PureComponent<DriverPagePersonalQuestionsProps, DriverPagePersonalQuestionsState> {
  constructor(props: any) {
    super(props);

    let currentDriver = this.props.drivers[this.props.currentIndex];

    if (!currentDriver) {
      throw new Error('driver was not found');
    }

    this.state = {
      firstName: currentDriver.firstName,
      middleInitial: currentDriver.middleInitial,
      lastName: currentDriver.lastName,
      email: currentDriver.email,
      phone: currentDriver.phone,
      preferredContact: currentDriver.preferredContact,
      dateOfBirth: currentDriver.dateOfBirth,
      relationship: currentDriver.relationship,
      leadOptions: [],
      lead: currentDriver.lead,
      isAndroid: false
    }

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.addClickHandler = this.addClickHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleContact = this.handleContact.bind(this);
    this.getCurrentActiveContact = this.getCurrentActiveContact.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isValidForAdd = this.isValidForAdd.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
    this.getValidationObjectForAdd = this.getValidationObjectForAdd.bind(this);

    this.props.setIsValidFunc(this.isValid);
  }

  getValidationObjectForAdd = (): any => {
    const basic = this.getValidationObject();
    let currentDriver = this.props.drivers[this.props.currentIndex];

    return {
      ...currentDriver,
      ...basic,
      incidents: (currentDriver!.hasIncidents === true && currentDriver!.incidents.length === 0) ? [new Incident()] : currentDriver!.incidents
    };
  }

  getValidationObject = (): any => {
    return {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      preferredContact: this.state.preferredContact,
      relationship: this.state.relationship,
      dateOfBirth: this.state.dateOfBirth
    };
  };


  componentWillUnmount() {
    this.updateDriver();
  }

  componentDidMount() {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      this.setState({
        isAndroid: isAndroid
      })
    }

    //get lead options
    axios.get(getBaseUrl() + `/leadOptions/${this.props.site.id}`)
      .then((response) => {
        var leadOptions: LeadOption[] = [];
        for (var i = 0; i < Object.keys(response.data).length; i++) {
          var newOption: LeadOption = {category: '', leads: []}
          newOption.category = Object.keys(response.data)[i];
          newOption.leads = response.data[newOption.category];
          leadOptions.push(newOption);
        }

        this.setState({
          leadOptions: leadOptions
        }, () => {
            //check if lead options changed since last time
            var hasChanged = true;
            for (var item of leadOptions) {
              if (item.leads.includes(this.state.lead)) {
                hasChanged = false;
                break;
              } 
            }

            if (hasChanged) {
              this.setState({
                lead: ''
              })
            }
        })
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });    
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  addClickHandler() {
    if (this.props.onAddClick) {
      this.props.onAddClick();
    }
  }

  updateDriver() {
    let currentDriver = this.props.drivers[this.props.currentIndex];

    if (currentDriver) {
      this.props.updateDriver({
        ...currentDriver,
        firstName: this.state.firstName,
        middleInitial: this.state.middleInitial,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        preferredContact: this.state.preferredContact,
        relationship: this.state.relationship,
        dateOfBirth: this.state.dateOfBirth,
        lead: this.state.lead
      });
    }
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObject(), validationLevel.Drivers).isPageValid(showErrors, this.props.currentIndex);
  }

  isValidForAdd(): boolean {
    return this.props.validation(this.getValidationObjectForAdd(), validationLevel.Drivers).isPageValid(ShowErrors.NeverShow, this.props.currentIndex);
  }

  handleChange(event: { target: { name: string; value: string; }; }, filter: string) {
    let { name, value } = event.target

    if (filter === 'letters') {
      value = value.replace(/[^A-Za-z'-\s]/ig, '').trimLeft();
      value = value.split(' ').map(s => s.substring(0, 1).toUpperCase() + s.substring(1)).join(' ');
      value = value.split('-').map(s => s.substring(0, 1).toUpperCase() + s.substring(1)).join('-');
      value = value.split('\'').map(s => s.substring(0, 1).toUpperCase() + s.substring(1)).join('\'');
    }

    this.setState({
      ...this.state,
      [name]: value
    });
  }

  handleContact(event: { target: { value: string; }; }) {
    this.setState({
      preferredContact: event.target.value as contactMethod
    })

    switch (event.target.value) {
      case contactMethod.Email:
        document.getElementById('emailLabel')!.classList.add('active');
        document.getElementById('phoneLabel')!.classList.remove('active');
        document.getElementById('textLabel')!.classList.remove('active');
        return
      case contactMethod.Phone:
        document.getElementById('emailLabel')!.classList.remove('active');
        document.getElementById('phoneLabel')!.classList.add('active');
        document.getElementById('textLabel')!.classList.remove('active');
        return
      case contactMethod.Text:
        document.getElementById('emailLabel')!.classList.remove('active');
        document.getElementById('phoneLabel')!.classList.remove('active');
        document.getElementById('textLabel')!.classList.add('active');
        return
    }
  }

  handleDate(event: { target: { name: string; value: string; }; }) {
    const { name } = event.target;

    //fallback for IE 10 and other browsers that might not support date as an input type
    if ((document.getElementsByName(name)[0]! as HTMLInputElement).type === 'text' || this.state.isAndroid) {
      let dateDisplay = event.target.value;
      if (this.state.dateOfBirth.length < dateDisplay.length) {
        dateDisplay = dateDisplay.replace(/[^0-9]/g, '').slice(0, 8);
        if (dateDisplay.length >= 5) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2, 4) + '/' + dateDisplay.slice(4);
        }
        else if (dateDisplay.length >= 3) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2);
        }
        if (dateDisplay.length === 2 || dateDisplay.length === 5) {
          dateDisplay = dateDisplay + '/';
        }
      }
      event.target.value = dateDisplay;    
    }

    this.setState({
      ...this.state,
      [name]: event.target.value
    });;
  }

  getCurrentActiveContact() {
    let active: string[] = ['', '', ''];
    switch (this.state.preferredContact) {
      case contactMethod.Email:
        active[0] = 'active';
        return active;
      case contactMethod.Phone:
        active[1] = 'active';
        return active;
      case contactMethod.Text:
        active[2] = 'active';
        return active;
      default:
        return active;
    }
  }

  render() {
    let active: string[] = this.getCurrentActiveContact();
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Drivers);
    const contactInvalid = isFieldInvalid(validationConfig.drivers.preferredContact, this.props.currentIndex);
    const { hasAuto } = hasLob(this.props.quote);

    return (
      <Fragment>
        <h3 className='text-center'>
          Let's get your info
        </h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              <div className='form-row'>
                <FormGroup className='col-md-7'>
                  <ValidationLabel config={validationConfig.drivers.firstName} index={this.props.currentIndex} />
                  <Input onChange={(e) => this.handleChange(e, 'letters')}
                    value={this.state.firstName}
                    pattern='[A-Za-z]'
                    {...getFieldProps(validationConfig.drivers.firstName, undefined, this.props.currentIndex)}
                    maxLength={50}
                    autoFocus></Input>
                  <FormFeedback>{getErrorText(validationConfig.drivers.firstName, this.props.currentIndex)}</FormFeedback>
                </FormGroup>
                <FormGroup className='col-md-5'>
                  <Label className='text-nowrap'>Middle Initial: <small>(optional)</small></Label>
                  <Input style={{ width: '3em' }}
                    onChange={(e) => this.handleChange(e, 'letters')}
                    value={this.state.middleInitial}
                    name='middleInitial'
                    maxLength={1}></Input>
                </FormGroup>
              </div>
              <div className='form-row'>
                <FormGroup className='col-md-9'>
                  <ValidationLabel config={validationConfig.drivers.lastName} index={this.props.currentIndex} />
                  <Input onChange={(e) => this.handleChange(e, 'letters')}
                    value={this.state.lastName} pattern='[A-Za-z]'
                    {...getFieldProps(validationConfig.drivers.lastName, undefined, this.props.currentIndex)}
                    maxLength={50}></Input>
                  <FormFeedback>{getErrorText(validationConfig.drivers.lastName, this.props.currentIndex)}</FormFeedback>
                </FormGroup>
              </div>
              {this.props.currentIndex === 0 ?
                <Fragment>
                  <div className='form-row'>
                    <FormGroup className='col-md-9'>
                      <ValidationLabel config={validationConfig.drivers.email} index={this.props.currentIndex} />
                      <Input type='email'
                        onChange={(e) => this.handleChange(e, '')}
                        value={this.state.email}
                        {...getFieldProps(validationConfig.drivers.email, undefined, this.props.currentIndex)}
                        maxLength={50}></Input>
                      <FormFeedback>{getErrorText(validationConfig.drivers.email, this.props.currentIndex)}</FormFeedback>
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <ValidationLabel config={validationConfig.drivers.phone} index={this.props.currentIndex} />
                    <NumberFormat
                      type='tel'
                      autoComplete="tel-national"
                      format="(###) ###-####"
                      mask=' '
                      {...getFieldProps(validationConfig.drivers.phone, 'form-control', this.props.currentIndex)}
                      style={{ width: '11rem' }}
                      onChange={(e: any) => this.handleChange(e, '')}
                      value={this.state.phone} />
                    <FormFeedback>{getErrorText(validationConfig.drivers.phone, this.props.currentIndex)}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <ValidationLabel config={validationConfig.drivers.preferredContact} index={this.props.currentIndex} />
                    <div>
                      <ButtonGroup className={classNames('btn-group-toggle driver-page__contact', { 'is-invalid': contactInvalid })}>
                        <label className={'btn btn-option ' + active[0]} id='emailLabel' data-toggle='buttons'>
                          <input type="radio" name={validationConfig.drivers.preferredContact.fieldName} id="email" onChange={this.handleContact} value={contactMethod.Email} />Email
                        </label>
                        <label className={'btn btn-option ' + active[1]} id='phoneLabel' data-toggle='buttons'>
                          <input type="radio" name={validationConfig.drivers.preferredContact.fieldName} id="phone" onChange={this.handleContact} value={contactMethod.Phone} />Phone
                        </label>
                        <label className={'btn btn-option ' + active[2]} id='textLabel' data-toggle='buttons'>
                          <input type="radio" name={validationConfig.drivers.preferredContact.fieldName} id="text" onChange={this.handleContact} value={contactMethod.Text} />Text
                        </label>
                      </ButtonGroup>
                      <FormFeedback>{getErrorText(validationConfig.drivers.preferredContact, this.props.currentIndex)}</FormFeedback>
                    </div>
                  </FormGroup>
                </Fragment>
                :
                <FormGroup>
                  <ValidationLabel config={validationConfig.drivers.relationship} index={this.props.currentIndex} />
                  <select {...getFieldProps(validationConfig.drivers.relationship, 'custom-select', this.props.currentIndex)}
                    onChange={(e) => this.handleChange(e, '')}
                    value={this.state.relationship}>
                    <option>{'<select>'}</option>
                    {
                      (this.props.questionOptions["Relation"] || []).map((option: QuestionOption, index: number) => (
                        <option key={index} value={option.value}>{option.text}</option>
                      ))
                    }
                  </select>
                  <FormFeedback>{getErrorText(validationConfig.drivers.relationship, this.props.currentIndex)}</FormFeedback>
                </FormGroup>
              }
              <FormGroup className={this.state.isAndroid ? 'w-50' : ''}>
                <ValidationLabel config={validationConfig.drivers.dateOfBirth} index={this.props.currentIndex} />
                <Input type={this.state.isAndroid ? 'text' : 'date'}
                  onChange={this.handleDate}
                  inputMode='numeric'
                  placeholder='mm/dd/yyyy'
                  value={this.state.dateOfBirth}
                  min='1900-01-01'
                  max={getCurrentDate()}              
                  {...getFieldProps(validationConfig.drivers.dateOfBirth, undefined, this.props.currentIndex)}></Input>
                <FormFeedback>{getErrorText(validationConfig.drivers.dateOfBirth, this.props.currentIndex)}</FormFeedback>
              </FormGroup> 

              {this.props.currentIndex === 0 && this.state.leadOptions.length > 0 &&
                <FormGroup>
                  <Label className='text-nowrap'>How Did You Hear About Us? <small>(optional)</small></Label>
                  <select
                    name='lead'
                    className='custom-select'
                    onChange={(e) => this.handleChange(e, '')}
                    value={this.state.lead}>
                    <option value=''>{'<select>'}</option>
                      {this.state.leadOptions.map((header, index) => {
                        return <optgroup key={index} label={header.category}>
                          {header.leads.map((option, optionKey) => {
                            return <option key={optionKey} value={option}>{option}</option>
                          })}
                        </optgroup>;
                      })}
                  </select>
                </FormGroup>
              }
            </Form>
          </Swipeable>              
        </div>

        {hasAuto &&
          <Col className='text-right pr-0'>
            <AddButton isDisabled={!this.isValidForAdd()} onClick={this.addClickHandler}
              isHidden={this.props.drivers.length === 4}>
                Driver
            </AddButton>
          </Col>
        }
        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.props.currentIndex > 0 ? this.previousClickHandler : undefined} />
          </Col>
        </Row>

      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  quote: state.quote,
  drivers: state.quote.drivers,
  questionOptions: state.questionOptions.options
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateDriver: (driver: Driver) => dispatch(QuoteActions.updateDriver(driver)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
})

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(DriverPagePersonalQuestions));
