import { createBrowserHistory } from 'history'
import { getQueryStringPage } from './helper';

// Create browser history to use in the Redux store and everywhere else
const history = createBrowserHistory();

export const updateCrqPage = (page: string) => {
  const crqPage = getQueryStringPage(window.location);
  let newRoute;

  if (crqPage) {
    newRoute = window.location.pathname + window.location.search.replace(/([?|&]crqPage=)[^&]+/i, '$1' + page);
  }
  else {
    newRoute = window.location.search.length > 0 ? window.location.search + '&' : '?';
    newRoute = window.location.pathname + newRoute + 'crqPage=' + page;
  }

  history.push(newRoute);
};

export const clearCrqPage = () => {
  const crqPage = getQueryStringPage(window.location);

  if (crqPage) {
      //clear param value
      let newParams = window.location.search.replace(/([?|&]crqPage=)[^&]+/i, '');

      if (newParams.length > 0 && newParams.indexOf('&') === 0) {
        newParams = '?' + newParams.substring(1);
      }

      const newRoute = window.location.pathname + newParams;
      history.push(newRoute);
    }
};

export const showErrorPage = (refId: string) => {
  const crqPage = getQueryStringPage(window.location);
  let newRoute;

  if (crqPage) {
    newRoute = window.location.pathname + window.location.search.replace(/([?|&]crqPage=)[^&]+/i, '$1errorpage');
  }
  else {
    //helps error boundary code
    newRoute = window.location.search.length > 0 ? window.location.search + '&' : '?';
    newRoute = window.location.pathname + newRoute + 'crqPage=errorpage';
  }

  const locationState: any = history.location.state || {};

  history.push(newRoute, { ...locationState, 'crqRefId': refId });
};

export default history;