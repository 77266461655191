import React, { Fragment } from 'react';
import { Row, Col, Form, FormGroup, ButtonGroup, Label, Input, FormFeedback } from 'reactstrap';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import Swipeable from '../Swipeable';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import { getCurrentDate, hasLob } from '../../services/helper';
import { Coverages, LineOfBusinessType } from '../../store/Quote/state';
import { QuestionOption } from '../../store/QuestionOptions/state';
import HelpText from '../HelpText';

type OwnProps = {
  onPreviousClick?: () => void,
  onNextClick?: () => void
}

type QuestionGroup2Props =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface QuestionGroup2State {
  [x: string]: any;
  policyStartDate: string,
  bundleAutoHome: boolean | null,
  paperlessBilling: boolean,
  smokeDetector: boolean,
  fireExtinguisher: boolean,
  burglarAlarm: boolean,
  deadBoltLocks: boolean,
  smokersInHousehold: boolean | null
}

class QuestionGroup2 extends React.PureComponent<QuestionGroup2Props, QuestionGroup2State> {
  constructor(props: any) {
    super(props);

    const { coverages } = this.props.quote;


    this.state = {
      // console logs uncontrolled to controlled warning for this one state only without making sure that it is not undefined first time it renders
      policyStartDate: coverages.policyStartDate || '',
      bundleAutoHome: coverages.bundleAutoHome,
      paperlessBilling: coverages.paperlessBilling,
      smokeDetector: coverages.smokeDetector,
      fireExtinguisher: coverages.fireExtinguisher,
      burglarAlarm: coverages.burglarAlarm,
      deadBoltLocks: coverages.deadBoltLocks,
      smokersInHousehold: coverages.smokersInHousehold
    }

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);

    this.props.setIsValidFunc(this.isValid);
  }

  getValidationObject = (): any => {
    return {
      policyStartDate: this.state.policyStartDate,
      bundleAutoHome: this.state.bundleAutoHome,
      paperlessBilling: this.state.paperlessBilling,
      smokersInHousehold: this.state.smokersInHousehold
    };
  };

  componentWillUnmount() {
    this.props.updateCoverages({
      ...this.props.quote.coverages,
      policyStartDate: this.state.policyStartDate,
      bundleAutoHome: this.props.quote.lineOfBusiness === LineOfBusinessType.Package ? true : this.state.bundleAutoHome,
      paperlessBilling: this.state.paperlessBilling,
      smokeDetector: this.state.smokeDetector,
      fireExtinguisher: this.state.fireExtinguisher,
      burglarAlarm: this.state.burglarAlarm,
      deadBoltLocks: this.state.deadBoltLocks,
      smokersInHousehold: this.state.smokersInHousehold
    });
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow) {
    return this.props.validation(this.getValidationObject(), validationLevel.Coverages).isPageValid(showErrors);
  }

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  handleChange(key: string, value: string | number | boolean) {
    this.setState({
      ...this.state,
      [key]: value
    });
  }

  handleDate(event: { target: { name: string; value: string; }; }) {
    const { name } = event.target;

    //fallback for IE 10 and other browsers that might not support date as an input type
    if ((document.getElementsByName(name)[0]! as HTMLInputElement).type === 'text') {
      let dateDisplay = event.target.value;
      if (this.state.policyStartDate.length < dateDisplay.length) {
        dateDisplay = dateDisplay.replace(/[^0-9]/g, '').slice(0, 8);
        if (dateDisplay.length >= 5) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2, 4) + '/' + dateDisplay.slice(4);
        }
        else if (dateDisplay.length >= 3) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2);
        }
        if (dateDisplay.length === 2 || dateDisplay.length === 5) {
          dateDisplay = dateDisplay + '/';
        }
      }
      event.target.value = dateDisplay;
    }

    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  }

  render() {
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Coverages);
    const bundleInvalid = isFieldInvalid(validationConfig.coverages.bundleAutoHome);
    const paperlessInvalid = isFieldInvalid(validationConfig.coverages.paperlessBilling);
    const { hasHome } = hasLob(this.props.quote);

    return (
      <Fragment>
        <h3 className='text-center'>Policy and Discounts</h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              <FormGroup>
                <ValidationLabel config={validationConfig.coverages.policyStartDate} />
                <HelpText text={validationConfig.coverages.policyStartDate.helpText} helpId='policyStartDate'/>
                <Input type='date'
                  {...getFieldProps(validationConfig.coverages.policyStartDate)}
                  value={this.state.policyStartDate}
                  placeholder='mm/dd/yyyy'
                  min={getCurrentDate()}
                  max={getCurrentDate(1)}
                  autoFocus
                  onChange={this.handleDate}></Input>
                <FormFeedback>{getErrorText(validationConfig.coverages.policyStartDate)}</FormFeedback>
              </FormGroup>

              {this.props.quote.lineOfBusiness !== LineOfBusinessType.Package &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.coverages.bundleAutoHome} />
                  <HelpText text={validationConfig.coverages.bundleAutoHome.helpText} helpId='bundleAutoHome'/>
                  <div>
                    <ButtonGroup className={classNames('btn-group-toggle coverage-page__interested', { 'is-invalid': bundleInvalid })}>
                      <label className={classNames('btn btn-option', { active: this.state.bundleAutoHome === false })} data-toggle='buttons' data-testid='bundleButtonNo'>
                        <input type="radio" name={validationConfig.coverages.bundleAutoHome.fieldName} value="false" onChange={() => this.handleChange('bundleAutoHome', false)} />
                        No
                      </label>
                      <label className={classNames('btn btn-option', { active: this.state.bundleAutoHome === true })} data-toggle='buttons' data-testid='bundleButtonYes'>
                        <input type="radio" name={validationConfig.coverages.bundleAutoHome.fieldName} value="true" onChange={() => this.handleChange('bundleAutoHome', true)} />
                        Yes
                      </label>
                    </ButtonGroup>
                    <FormFeedback>{getErrorText(validationConfig.coverages.bundleAutoHome)}</FormFeedback>
                  </div>
                </FormGroup>
              }

              <FormGroup>
                <ValidationLabel config={validationConfig.coverages.paperlessBilling} />
                <HelpText text={validationConfig.coverages.paperlessBilling.helpText} helpId='paperlessBilling' />
                <div>
                  <ButtonGroup className={classNames('btn-group-toggle coverage-page__interested', { 'is-invalid': paperlessInvalid })}>
                    <label className={classNames('btn btn-option', { active: this.state.paperlessBilling === false })} data-toggle='buttons' data-testid='paperlessButtonNo'>
                      <input type="radio" name={validationConfig.coverages.paperlessBilling.fieldName} value="false" onChange={() => this.handleChange('paperlessBilling', false)} />
                        No
                      </label>
                    <label className={classNames('btn btn-option', { active: this.state.paperlessBilling === true })} data-toggle='buttons' data-testid='paperlessButtonYes'>
                      <input type="radio" name={validationConfig.coverages.paperlessBilling.fieldName} value="true" onChange={() => this.handleChange('paperlessBilling', true)} />
                        Yes
                      </label>
                  </ButtonGroup>
                  <FormFeedback>{getErrorText(validationConfig.coverages.paperlessBilling)}</FormFeedback>
                </div>
              </FormGroup>

              {hasHome &&
                <Fragment>
                  <FormGroup>
                    <Label>Protective Device Credits <small>(select all that apply)</small>:</Label>
                    <ButtonGroup vertical className='btn-group-toggle d-block form-control p-0 coverage-page__protectiveDevices'>
                      <label className={classNames('btn btn-option', { active: this.state.smokeDetector })} data-toggle='buttons' data-testid='smokeDetector'>
                        <input type="checkbox" checked={this.state.smokeDetector} onChange={(e) => this.handleChange('smokeDetector', e.target.checked)} />
                          Smoke Detector
                      </label>
                      <label className={classNames('btn btn-option', { active: this.state.fireExtinguisher })} data-toggle='buttons' data-testid='fireExtinguisher'>
                        <input type="checkbox" checked={this.state.fireExtinguisher} onChange={(e) => this.handleChange('fireExtinguisher', e.target.checked)} />
                          Fire Extinguisher
                      </label>
                      <label className={classNames('btn btn-option', { active: this.state.burglarAlarm })} data-toggle='buttons' data-testid='burglarAlarm'>
                        <input type="checkbox" checked={this.state.burglarAlarm} onChange={(e) => this.handleChange('burglarAlarm', e.target.checked)} />
                          Burglar Alarm
                      </label>
                      <label className={classNames('btn btn-option', { active: this.state.deadBoltLocks })} data-toggle='buttons' data-testid='deadBoltLocks'>
                        <input type="checkbox" checked={this.state.deadBoltLocks} onChange={(e) => this.handleChange('deadBoltLocks', e.target.checked)} />
                          Dead Bolt Locks
                      </label>
                    </ButtonGroup>
                    <FormFeedback>{getErrorText(validationConfig.coverages.bundleAutoHome)}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <ValidationLabel config={validationConfig.coverages.smokersInHousehold} />
                    <div>
                      <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.coverages.smokersInHousehold) })} >
                        {
                          ([{ text: 'No', value: 'false' }, { text: 'Yes', value: 'true' }] as QuestionOption[])
                            .map((option: QuestionOption, index: number) => (
                              <label className={classNames('btn btn-option', { active: option.value === this.state.smokersInHousehold + '' })} data-toggle='buttons' key={index}>
                                <input type="radio" name={validationConfig.coverages.smokersInHousehold.fieldName} onChange={(e) => this.handleChange('smokersInHousehold', e.target.value === 'true')} value={option.value} />
                                {option.text}
                              </label>
                            ))
                        }
                      </ButtonGroup>
                      <FormFeedback>{getErrorText(validationConfig.coverages.smokersInHousehold)}</FormFeedback>
                    </div>
                  </FormGroup>

                </Fragment>
              }
            </Form>
          </Swipeable>
        </div>

        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateCoverages: (coverages: Coverages) =>
    dispatch(QuoteActions.updateCoverages(coverages)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
});

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup2));