import React from 'react'
import { Form, Button, Row, Col } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa'
import { updateCrqPage } from '../services/historyWrapper';
import { ThunkDispatch } from 'redux-thunk';
import { homeownersType } from '../store/Quote/state';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../store/Quote/actions';
import { connect } from 'react-redux';

type TimedOutProps =
  ReturnType<typeof mapDispatchToProps>;

class TimedOut extends React.PureComponent<TimedOutProps> {
  componentDidMount() {
    // clear quote info
    this.props.startQuote( false, homeownersType.None, '', '', '', '', '', '', false, '');
  }

  public render() {
    const gotoStartQuotePage = () => {
      updateCrqPage('startquotepage');
    }

    return (
      <div className='row justify-content-center align-items-center mt-5'>
        <Form className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light'>
          <Row className=''>
            <Col sm='3' className='align-center mt-5 mt-sm-0 d-flex'>
              <FaExclamationTriangle
                size={70}
                className='text-danger m-auto d-block'
              />
            </Col>
            <Col sm='9' >
              <h5 className='text-center mt-3 mt-sm-5'>Your current session has timed out.</h5>
              <div className='mt-3 mb-5 text-center'>
                <Button data-testid='startNewSession' className='mr-sm-2' size='sm' onClick={gotoStartQuotePage}>Start New Session</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction>) => ({
  startQuote: (isPersonalAuto: boolean, homeowners: homeownersType, fullAddress: string, addressState: string, addressLine1: string,
    addressLine2: string, city: string, zip: string, addressVerified: boolean, agencyLicNum: string) =>
    dispatch(QuoteActions.startQuote(isPersonalAuto, homeowners, fullAddress, addressState, addressLine1, addressLine2, city, zip, addressVerified, agencyLicNum)),
})

export default connect(
  null, mapDispatchToProps
)(TimedOut as any);
