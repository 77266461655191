import { Site } from './Site/state';
import { reducer as SiteReducer } from './Site/reducers'
import { QuestionOptions } from './QuestionOptions/state';
import { reducer as QuestionOptionsReducer } from './QuestionOptions/reducers'
import { QuoteState } from './Quote/state';
import { reducer as QuoteReducer } from './Quote/reducers';
import { Stepper } from './Stepper/state';
import { reducer as StepperReducer } from './Stepper/reducers';
import { Validation } from './Validation/state';
import { reducer as ValidationReducer } from './Validation/reducers';
import { StartupParameters } from './StartupParameters/state';
import { reducer as StartupParametersReducer } from './StartupParameters/reducers';

// The top-level state object
export interface ApplicationState {
  site: Site;
  questionOptions: QuestionOptions;
  quote: QuoteState;
  stepper: Stepper;
  validation: Validation;
  startupParameters: StartupParameters;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  site: SiteReducer,
  questionOptions: QuestionOptionsReducer,
  quote: QuoteReducer,
  stepper: StepperReducer,
  validation: ValidationReducer,
  startupParameters: StartupParametersReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
