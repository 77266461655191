import { Stepper, initialState } from './state';
import { Actions as StepActions, Action as StepAction } from './actions';
import { Actions as QuoteActions, Action as QuoteAction } from '../Quote/actions';
import { Action, Reducer } from 'redux';

export const reducer: Reducer<Stepper> = (state: Stepper = initialState, incomingAction: Action): Stepper => {
  const action = incomingAction as StepAction | QuoteAction;
  switch (action.type) {
    case StepActions.CHANGE_STEP:
      return {
        active: action.active,
        complete: action.complete,
        selected: action.selected,
        isValid: action.isValid,
        isBackPage: action.isBackPage
      }
    case StepActions.SET_SELECTED:
      return {
        ...state,
        selected: action.selected,
        isValid: action.isValid,
        isBackPage: false
      }
    case StepActions.SET_ISVALID_FUNC:
      return {
        ...state,
        isValid: action.isValid
      }
    case StepActions.CLEAR_IS_BACK_PAGE:
      return {
        ...state,
        isBackPage: false
      };
    case QuoteActions.START_QUOTE:
      return {
        ...initialState
      }
    default:
      return state;
  }
};