import React, { Fragment } from 'react';
import { GoTrashcan } from 'react-icons/go';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { Label } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '../custom.scss';
import './Tabs.scss';
import classNames from 'classnames';
import WithMediaQuery from './WithMediaQuery';

interface TabsProps {
  currentIndex?: number,
  items?: string[],
  itemType?: string,
  onTabClick?: (tabIndex: number) => void,
  onRemoveClick?: (tabIndex: number) => void,
  isDesktop: boolean
  isTablet: boolean,
  isMobile: boolean
}

interface TabsState {
  firstIndex: number,
  numberToDisplay: number
}

class Tabs extends React.PureComponent<TabsProps, TabsState> {
  constructor(props: any) {
    super(props);

    const { isTablet, isDesktop, items = [] } = this.props;
    let firstIndex = this.props.currentIndex!;
    let numberToDisplay = 1;

    if (isTablet) {
      if (firstIndex > 0 && firstIndex === items.length - 1) {
        // firstIndex cannot be last item for tablet
        firstIndex--;
      }
      numberToDisplay = 2;
    }
    else if (isDesktop) {
      firstIndex = 0;
      numberToDisplay = 4;
    }

    this.state = {
      firstIndex,
      numberToDisplay
    };
  }

  componentDidUpdate(prevProps: TabsProps) {
    const { items = [] } = this.props;
    const { items: prevItems = [] } = prevProps;

    if (this.props.isMobile) {
      // add happened, set new one as first index
      if (items.length > prevItems.length) {
        this.setState({
          firstIndex: items.length - 1
        });
      }

      // view size changed
      if (prevProps.isMobile !== this.props.isMobile) {
        let firstIndex = this.props.currentIndex!;

        if (this.state.numberToDisplay === 2) {
          // if 2nd displayed is not currentIndex, just use 1st display whether currentIndex or not.
          if ((this.state.firstIndex + 1) <= items.length) {
            if ((this.state.firstIndex + 1) !== this.props.currentIndex!) {
              firstIndex = this.state.firstIndex;
            }
          }
        }

        this.setState({
          firstIndex,
          numberToDisplay: 1
        });
      }

      // always default to current index when change items
      if (this.props.currentIndex! !== prevProps.currentIndex!) {
        this.setState({
          firstIndex: this.props.currentIndex!
        });
      }
    }

    if (this.props.isTablet) {
      // add happened, set new one inside current view
      if (items.length > prevItems.length) {
        this.setState({
          firstIndex: items.length - 2
        });
      }

      // view size changed
      if (prevProps.isTablet !== this.props.isTablet) {
        let firstIndex = -1;

        if (this.state.numberToDisplay === 4) {
          // first is current selected
          firstIndex = this.props.currentIndex!;
        }
        else if (this.state.numberToDisplay === 1) {
          // first is what is currently visible
          firstIndex = this.state.firstIndex;
        }

        // backup 1 index if firstIndex is last position
        if (items.length >= 2 && firstIndex === items.length - 1) {
          firstIndex--;
        }

        this.setState({
          firstIndex,
          numberToDisplay: 2
        });
      }

      // always keep current index in view when change items
      if (this.props.currentIndex! !== prevProps.currentIndex!) {
        let firstIndex = this.state.firstIndex;

        if (this.props.currentIndex! < firstIndex || this.props.currentIndex! > firstIndex + 1) {
          firstIndex = this.props.currentIndex!;
        }

        // backup 1 index if firstIndex is last position
        if (items.length >= 2 && firstIndex === items.length - 1) {
          firstIndex--;
        }

        this.setState({
          firstIndex
        });
      }
    }

    // view size changed
    if (this.props.isDesktop && prevProps.isDesktop !== this.props.isDesktop) {
      this.setState({
        firstIndex: 0,
        numberToDisplay: 4
      });
    }
  }

  render() {
    const { currentIndex = 0,
      items = [],
      itemType = '',
      onTabClick = () => null,
      onRemoveClick = () => null,
      isTablet = false,
      isMobile = false } = this.props;

    const prev = () => {
      if (this.state.firstIndex > 0) {
        const firstIndex = this.state.firstIndex - 1;

        this.setState({
          firstIndex
        });

        // keep selected index in view
        if (isMobile) {
          onTabClick(firstIndex);
        }
        else if (isTablet && currentIndex > firstIndex + 1) {
          onTabClick(firstIndex + 1);
        }
      }
    }

    const next = () => {
      const adjust = isTablet ? 2 : 1;

      if (this.state.firstIndex < items.length - adjust) {
        const firstIndex = this.state.firstIndex + 1;

        this.setState({
          firstIndex
        });

        // keep selected index in view
        if (isMobile || (isTablet && currentIndex < firstIndex)) {
          onTabClick(firstIndex);
        }
      }
    }

    const removeHandler = (index: number) => {
      let firstIndex = -1;

      if (isMobile) {
        // can only remove the in view index so move to the previous one
        firstIndex = this.state.firstIndex - 1;
      }
      else if (isTablet) {
        // -3 for the 2 length and extra 1 off for 0-base index
        if (items.length > 2 && index > items.length - 3) {
          firstIndex = this.state.firstIndex - 1;
        }
        this.setState({
          firstIndex: this.state.firstIndex - 1
        });
      }

      if (firstIndex > -1) {
        this.setState({
          firstIndex
        });
      }

      onRemoveClick(index);
    }

    const showPrevArrow = (isMobile && items.length > 1 && this.state.firstIndex > 0) ||
      (isTablet && items.length > 2 && this.state.firstIndex > 0);

    const showNextArrow = (isMobile && items.length > 1 && this.state.firstIndex < items.length - 1) ||
      (isTablet && items.length > 2 && this.state.firstIndex < items.length - 2);

    return (
      <div className="d-flex justify-content-around btn-group btn-group-toggle mt-3 mb-3" data-toggle='buttons'>
        {showPrevArrow &&
          <Label className='btn border border-primary tabs__arrow' data-testid='prevArrowTest'><IoMdArrowDropleft size='30' />
            <input className='d-none' type='button' onClick={prev}></input>
          </Label>}

        {items.map((item: string, index: number) => {
          let className = classNames("btn tabs__btn border border-primary", {
            "active": index === currentIndex,
            "border-right-0": index > 0
          });

          let classNameRemove = classNames("btn tabs__btn border border-primary border-left-0 tabs__add-remove", {
            "active": index === currentIndex
          });

          if (index >= this.state.firstIndex && index <= (this.state.firstIndex + this.state.numberToDisplay - 1)) {
            return (
              <Fragment key={'frag' + index}>
                <Label className={className} >{item}
                  <input className='d-none' type='button' onClick={() => onTabClick(index)} data-testid={'tabButtonTest' + index}></input>
                </Label>
                {index > 0 &&
                  <Label className={classNameRemove} key={'remove' + this.props.itemType + index} aria-label={"Remove " + itemType}>
                    <GoTrashcan size='20' />
                  <input data-testid={'removeButtonTest' + index} className='d-none' type='button' onClick={() => removeHandler(index)}></input>
                  </Label>
                }
              </Fragment>
            );
          }
          else {
            return null;
          }
        })}

        {showNextArrow &&
          <Label className='btn border border-primary tabs__arrow' ><IoMdArrowDropright size='30' data-testid='nextArrowTest'/>
            <input className='d-none' type='button' onClick={next}></input>
          </Label>}
      </div>
    );
  }
}

export default WithMediaQuery([{ PropName: "isDesktop", Query: { minWidth: 992 } },
{ PropName: "isTablet", Query: { minWidth: 577, maxWidth: 991 } },
{ PropName: "isMobile", Query: { maxWidth: 576 } }])(Tabs);
