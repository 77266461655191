import React, { Fragment } from 'react';
import { Row, Col, Label, Form, FormGroup, Input, FormFeedback, ButtonGroup, InputGroup } from 'reactstrap';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { ApplicationState } from '../../store';
import { HomeCharacteristics, HomeLossType, HomeLoss } from '../../store/Quote/state';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import Swipeable from '../Swipeable';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import { isNil } from 'lodash';

import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import classNames from 'classnames';
import { GoTrashcan } from 'react-icons/go';
import { getCurrentDate } from '../../services/helper';
import AddButton from '../AddButton';

type OwnProps = {
  onPreviousClick?: () => void,
  onNextClick?: () => void
};

type QuestionGroupProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface QuestionGroupState {
  [x: string]: any;
  homeLosses: HomeLoss[],
  isIE: boolean | null
}

class QuestionGroup3 extends React.PureComponent<QuestionGroupProps, QuestionGroupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      homeLosses: this.props.homeCharacteristics.homeLosses.length < 1 ? [new HomeLoss()] : this.props.homeCharacteristics.homeLosses,
      isIE: null
    };

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.onAddLoss = this.onAddLoss.bind(this);
    this.onRemoveLoss = this.onRemoveLoss.bind(this);
    this.renderClaim = this.renderClaim.bind(this);

    this.handleLossChange = this.handleLossChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDate = this.handleDate.bind(this);

    this.isValid = this.isValid.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
    this.getValidationObjectList = this.getValidationObjectList.bind(this);
    this.props.setIsValidFunc(this.isValid);
  }

  componentWillUnmount() {
    this.updateHomeCharacteristics();
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  componentDidMount() {
    if (this.state.isIE === null) {
      this.setState({ isIE: window.navigator.userAgent.indexOf("MSIE ") > 0 || navigator.appVersion.indexOf('Trident/') > -1 })
    }
  }

  getValidationObject = (index: number): any => {
    return {
      lossCause: this.state.homeLosses[index].lossCause,
      lossDate: this.state.homeLosses[index].lossDate
    };
  };

  getValidationObjectList = (): any => {
    let list: any = []

    for (var i = 0; i < this.state.homeLosses.length; i++) {
      list.push(this.getValidationObject(i));
    }

    return list;
  }

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  updateHomeCharacteristics() {
    this.props.updateHomeCharacteristics({
      ...this.props.homeCharacteristics,
      homeLosses: this.state.homeLosses
    });
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObjectList(), validationLevel.HomeLosses).isPageValid(showErrors);
  }

  handleChange(event: { target: { name: string; value: string; maxLength?: string | number }; }, index: number) {
    let { value, maxLength, name } = event.target;

    if (!isNil(maxLength) && maxLength > -1) {
      const max = Number(maxLength);
      if (value.length > max) value = value.substring(0, max);
    }

    if (name === 'lossAmountPaid') {
      value = value.replace(/\D/g, ''); //remove unwanted characters from copy & paste
    }

    let updatedHomeLosses: HomeLoss[] = [...this.state.homeLosses];
    let updatedHomeLoss: HomeLoss = { ...updatedHomeLosses[index] };
    updatedHomeLoss.lossAmountPaid = Number(value);
    updatedHomeLosses[index] = updatedHomeLoss;

    this.setState({
      homeLosses: updatedHomeLosses
    })
  }

  handleLossChange(event: { target: { name: string; value: string; }; }, index: number) {
    let updatedHomeLosses: HomeLoss[] = [...this.state.homeLosses];
    let updatedHomeLoss: HomeLoss = { ...updatedHomeLosses[index] };
    updatedHomeLoss.lossCause = event.target.value as HomeLossType;
    updatedHomeLosses[index] = updatedHomeLoss;

    this.setState({
      homeLosses: updatedHomeLosses
    })
  }

  handleDate(event: { target: { name: string; value: string; }; }, index: number) {
    const { name } = event.target;

    //fallback for IE 10 and other browsers that might not support date as an input type
    if ((document.getElementsByName(name)[index]! as HTMLInputElement).type === 'text') {
      let dateDisplay = event.target.value;
      if (this.state.homeLosses[index].lossDate.length < dateDisplay.length) {
        dateDisplay = dateDisplay.replace(/[^0-9]/g, '').slice(0, 8);
        if (dateDisplay.length >= 5) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2, 4) + '/' + dateDisplay.slice(4);
        }
        else if (dateDisplay.length >= 3) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2);
        }
        if (dateDisplay.length === 2 || dateDisplay.length === 5) {
          dateDisplay = dateDisplay + '/';
        }
      }
      event.target.value = dateDisplay;
    }

    let updatedHomeLosses: HomeLoss[] = [...this.state.homeLosses];
    let updatedHomeLoss: HomeLoss = { ...updatedHomeLosses[index] };
    updatedHomeLoss.lossDate = event.target.value;
    updatedHomeLosses[index] = updatedHomeLoss;

    this.setState({
      homeLosses: updatedHomeLosses
    })
  }

  onRemoveLoss(index: number) {
    let homeLosses: HomeLoss[] = this.state.homeLosses;
    homeLosses.splice(index, 1)
    this.forceUpdate();
  }

  onAddLoss() {
    let homeLosses: HomeLoss[] = this.state.homeLosses;
    let newHomeLoss: HomeLoss = new HomeLoss();
    homeLosses.push(newHomeLoss);
    this.forceUpdate();
  }

  renderClaim(index: number) {
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(index), validationLevel.HomeLosses);
    const lossCause = isFieldInvalid(validationConfig.homeLosses.lossCause, index);
    
    const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
      // don't allow decimal or enter (enter causes form to submit on this control and puts form data in url and cause site not found page)
      (e.key === '.' || e.which === 13) && e.preventDefault()
    };

    return (
      <Fragment key={index}>
        <Row>
          <Col className='text-left'>
            <h5>
              Claim #{index + 1}
            </h5>
          </Col>
          <Col className='text-right'>
            <Label className={classNames('btn border border-dark', { 'd-none': this.state.homeLosses.length === 1 })}>
              <GoTrashcan size='20' />
              <input className='d-none' type='button' onClick={() => this.onRemoveLoss(index)} data-testid={index === 0 ? 'removeLoss' : `removeLoss_${index}`}></input>
            </Label>
          </Col>
        </Row>
        <FormGroup>
          <Label>{validationConfig.homeLosses.lossCause.display}:</Label>
          <div>
            <ButtonGroup className={classNames('btn-group-toggle ', { 'is-invalid': lossCause, 'border border-0 bg-light': !lossCause })}>
              <label className={'btn btn-option ' + (this.state.homeLosses[index].lossCause === HomeLossType.Fire ? 'active' : '')}
                id='fireLabel' data-toggle='buttons'
                data-testid={index === 0 ? 'lossCause-fire' : `lossCause-fire_${index}`}>
                <div><input type="radio" name={validationConfig.homeLosses.lossCause.fieldName} id="fire" onChange={(e) => this.handleLossChange(e, index)}
                  value={HomeLossType.Fire} />Fire</div>
              </label>
              <label className={'btn btn-option ' + (this.state.homeLosses[index].lossCause === HomeLossType.Flood ? 'active' : '')}
                id='floodLabel' data-toggle='buttons'
                data-testid={index === 0 ? 'lossCause-flood' : `lossCause-flood_${index}`}>
                <div><input type="radio" name={validationConfig.homeLosses.lossCause.fieldName} id="flood" onChange={(e) => this.handleLossChange(e, index)}
                  value={HomeLossType.Flood} />Flood</div>
              </label>
              <label className={'btn btn-option ' + (this.state.homeLosses[index].lossCause === HomeLossType.Theft ? 'active' : '')}
                id='theftLabel' data-toggle='buttons'
                data-testid={index === 0 ? 'lossCause-theft' : `lossCause-theft_${index}`}>
                <div><input type="radio" name={validationConfig.homeLosses.lossCause.fieldName} id="theft" onChange={(e) => this.handleLossChange(e, index)}
                  value={HomeLossType.Theft} />Theft</div>
              </label>
              <label className={'btn btn-option ' + (this.state.homeLosses[index].lossCause === HomeLossType.Other ? 'active' : '')}
                id='otherLabel' data-toggle='buttons'
                data-testid={index === 0 ? 'lossCause-other' : `lossCause-other_${index}`}>
                <div><input type="radio" name={validationConfig.homeLosses.lossCause.fieldName} id="other" onChange={(e) => this.handleLossChange(e, index)}
                  value={HomeLossType.Other} />Other</div>
              </label>
            </ButtonGroup>
            <FormFeedback>{getErrorText(validationConfig.homeLosses.lossCause, index)}</FormFeedback>
          </div>
        </FormGroup>

        <FormGroup>
          <ValidationLabel config={validationConfig.homeLosses.lossDate} index={index} />
          <Input type='date'
            data-testid={index === 0 ? 'lossDate' : `lossDate_${index}`}
            onChange={(e) => this.handleDate(e, index)}
            placeholder='mm/dd/yyyy'
            value={this.state.homeLosses[index].lossDate}
            min='2000-01-01'
            max={getCurrentDate()}
            {...getFieldProps(validationConfig.homeLosses.lossDate, undefined, index)}></Input>
          <FormFeedback>{getErrorText(validationConfig.homeLosses.lossDate, index)}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for='lossAmountPaid'>Amount Paid: <small>(optional)</small></Label>
          <InputGroup style={{ 'width': '11rem' }}>
            <div className='input-group-prepend'><span className='input-group-text'>$</span></div>
            <Input type={this.state.isIE ? 'text' : 'number'}
              value={this.state.homeLosses[index].lossAmountPaid ? this.state.homeLosses[index].lossAmountPaid! : ''}
              id='lossAmountPaid'
              data-testid={index === 0 ? 'lossAmountPaid' : `lossAmountPaid_${index}`}
              name='lossAmountPaid'
              maxLength={7}
              pattern='\d*'
              onChange={(e) => this.handleChange(e, index)}
              {...{ onKeyPress }}>
            </Input>
          </InputGroup>
        </FormGroup>
      </Fragment>
    )
  }

  render() {
    const items = [];
    for (var i = 0; i < this.state.homeLosses.length; i++) {
      items.push(this.renderClaim(i))
    }

    return (
      <Fragment>
        <h3 className='text-center mb-3'>
          Claims
        </h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>

              {
                items
              }

              <Col xs='3' sm='5' md='7' className='text-left pl-0 mb-3'>
                <AddButton isDisabled={false} onClick={this.onAddLoss}
                  isHidden={this.state.homeLosses.length === 7}
                  title="Add Claim">
                  Claim
                </AddButton>
              </Col>
            </Form>
          </Swipeable>
        </div>

        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Fragment>
    )
  }

}

const mapStateToProps = (state: ApplicationState) => ({
  homeCharacteristics: (state.quote.homeCharacteristics as HomeCharacteristics)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateHomeCharacteristics: (homeCharacteristics: HomeCharacteristics) => dispatch(QuoteActions.updateHomeCharacteristics(homeCharacteristics)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
});

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup3));
