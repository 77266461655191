import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import '../custom.scss';
import './HelpText.scss';
import { Tooltip } from 'react-tooltip';
import { MdHelp } from 'react-icons/md';
import { Middleware, MiddlewareState, offset, flip } from '@floating-ui/react';
interface HelpTextProps {
  text: string,
  helpId: string
}

class HelpText extends React.PureComponent<HelpTextProps> {
  tooltip: any;

  setPositionX: Middleware = {
    name: 'setPositionX',
    fn: this.getTooltipPosition
  }

  middlewares: Middleware[] = [
    offset(10),
    flip({
      fallbackAxisSideDirection: 'start',
    }),
    this.setPositionX
  ]

  getTooltipPosition({ x, y }: MiddlewareState) {
    //override tooltip position to be centered horizontally
    x = (window.innerWidth / 2) - 125;

    return { x, y };
  }

  render() {
    return (
      <Fragment>
        <span
          data-tooltip-id={this.props.helpId + 'Id'}
          data-testid={this.props.helpId + 'HelpId'}
          className='ml-1 text-primary'
          style={{ cursor: 'pointer' }}>
          <MdHelp size={20} />
        </span>

        <Tooltip
          id={this.props.helpId + 'Id'}
          clickable={true}
          place='right'
          variant='light'
          openOnClick={true}
          globalCloseEvents={{
            clickOutsideAnchor: true,
            scroll: true,
            resize: true
          }}
          className='help-text__display'
          ref={el => this.tooltip = el}
          positionStrategy="fixed"
          middlewares={this.middlewares}
          opacity={1}
        >
          <div>
            <p className='mt-1' dangerouslySetInnerHTML={{__html: this.props.text}}></p>

            <div className='text-center'>
              <Button
                color='primary'
                data-testid={'closeTestId'}
                onClick={() => {
                  this.tooltip.close();
                }}>
                Close
              </Button>
            </div>
          </div>
        </Tooltip>
      </Fragment>
    )
  }
}

export default HelpText;
