import { homeownersType } from '../store/Quote/state';
import { ErrorInfo, ErrorHierachy } from '../store/Validation/state';
import { validator } from './validator';
import { validationConfig, validationLevel } from './validationConfig';
import { isNil } from 'lodash';

export const getValidationLevel = (level: string): validationLevel => {
  level = level.substring(0, 1).toUpperCase() + level.substring(1);

  let levelValue = validationLevel[level as keyof typeof validationLevel];
  if (levelValue === undefined) {
    levelValue = validationLevel.None;
  }
  return levelValue;
};

export class ValidationQuoteInfo {
  lob: string = '';
  homeowners: homeownersType = homeownersType.None;
  addressState: string = '';
  conditionIndex: number | undefined = undefined;
}

export const validate = (quoteInfo: ValidationQuoteInfo, objToValidate: any, level: validationLevel): ErrorInfo[] => {
  let errors: ErrorInfo[] = [];

  let validatorInstance = new validator(objToValidate);

  if (level === validationLevel.All) {
    // index only applies when not doing All
    quoteInfo.conditionIndex = undefined;

    for (let configLevel in validationConfig) {
      const groupLevel: validationLevel = getValidationLevel(configLevel);
      // skip children of other groups.  they should be picked up in recursive logic.
      if (groupLevel !== validationLevel.Incidents && groupLevel !== validationLevel.HomeLosses) {
        const checkObj = objToValidate[groupLevel];

        if (!isNil(checkObj)) {
          let errorHierachy = new ErrorHierachy(groupLevel, undefined);
          validatorInstance.validateIteration(quoteInfo, checkObj, groupLevel, errors, errorHierachy);
        }
      }
    }
  }
  else {
    // if cannot find specific object, then send whole object because a generic object was pass for validation
    const checkObj = objToValidate[level] || objToValidate;

    let errorHierachy = new ErrorHierachy(level, undefined);

    validatorInstance.validateIteration(quoteInfo, checkObj, level, errors, errorHierachy);
  }

  return errors;
};

export const getErrors = (fieldName: string, index: number, errors: ErrorInfo[], level: validationLevel): string[] => {
  const temp: ErrorInfo[] = errors.filter(e => e.level === level && e.index === index && e.field === fieldName);
  return temp.length > 0 ? temp[0].errors : [];
};

