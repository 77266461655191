import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Row, Col, Form } from 'reactstrap';
import PreviousNextButtons from '../PreviousNextButtons';
import WithMediaQuery from '../WithMediaQuery';
import YearList from './YearList';
import MakeList from './MakeList';
import ModelList from './ModelList';
import BodyStyleList from './BodyStyleList';
import VinEntry from './VinEntry';

import { Vehicle, VehicleEntryMode } from '../../store/Quote/Vehicle/vehicle';
import { BodyStyleInfo } from '../../store/Quote/Vehicle/bodyStyleInfo';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import AddButton from '../AddButton';
import Swipeable from '../Swipeable';
import { isEqual } from 'lodash';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationLevel } from '../../services/validationConfig';

import { getVehicleMakeImageUrls } from '../../services/imageList';

import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import { AgencyInfoPosition } from '../../store/Site/state';

enum YearMakeModelSection {
  Year,
  Make,
  Model,
  BodyStyle
}

type OwnProps = {
  isLgScreen: boolean,
  isFromPrevious: boolean,
  currentIndex: number,
  onPreviousClick?: () => void,
  onNextClick?: () => void,
  onAddClick?: () => void
}

type QuestionGroupProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

interface QuestionGroupState {
  currentSection: YearMakeModelSection,
  entryMode: VehicleEntryMode,
  year: number,
  make: string,
  model: string,
  bodyStyle: BodyStyleInfo | null,
  vin: string,
  tabKey: string
}

class QuestionGroup1 extends React.PureComponent<QuestionGroupProps, QuestionGroupState> {
  constructor(props: any) {
    super(props);

    let currentVehicle = this.props.vehicles[this.props.currentIndex];

    if (!currentVehicle) {
      throw new Error('vehicle was not found');
    }

    this.state = {
      currentSection: this.getCurrentSection(currentVehicle, this.props.isLgScreen || this.props.isFromPrevious),
      entryMode: currentVehicle.entryMode,
      year: currentVehicle.year,
      make: currentVehicle.make,
      model: currentVehicle.model,
      bodyStyle: currentVehicle.bodyStyle,
      vin: currentVehicle.vin, 
      tabKey: ''
    };

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.addClickHandler = this.addClickHandler.bind(this);

    this.yearClickHandler = this.yearClickHandler.bind(this);
    this.vinEntrykHandler = this.vinEntrykHandler.bind(this);

    this.vinChangeHandler = this.vinChangeHandler.bind(this);
    this.yearMakeModelEntrykHandler = this.yearMakeModelEntrykHandler.bind(this);

    this.makeClickHandler = this.makeClickHandler.bind(this);
    this.modelClickHandler = this.modelClickHandler.bind(this);
    this.bodyStyleClickHandler = this.bodyStyleClickHandler.bind(this);

    this.isValid = this.isValid.bind(this);
    this.isValidForAdd = this.isValidForAdd.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
    this.getValidationObjectForAdd = this.getValidationObjectForAdd.bind(this);
    this.props.setIsValidFunc(this.isValid);
  }

  componentDidMount() {
    getVehicleMakeImageUrls().forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }

  componentWillUnmount() {
    this.updateVehicle();
  }

  componentDidUpdate(prevProps: QuestionGroupProps) {
    // when changing from small to large screen, need to recompute current section so all the right inputs get displayed
    if (this.props.isLgScreen && prevProps.isLgScreen !== this.props.isLgScreen) {
      this.setState({
        currentSection: this.getCurrentSection(this.state, true)
      })
    }

    this.isValid(ShowErrors.ShowIfVisible);
  }

  getValidationObjectForAdd = (): any => {
    const basic = this.getValidationObject();
    let currentVehicle = this.props.vehicles[this.props.currentIndex];

    return {
      ...currentVehicle,
      ...basic
    };
  };

  getValidationObject = (): any => {
    return {
      entryMode: this.state.entryMode,
      year: this.state.year,
      make: this.state.make,
      model: this.state.model,
      bodyStyle: this.state.bodyStyle,
      vin: this.state.vin
    };
  };

  previousClickHandler() {
    if (this.props.isLgScreen ||
      (this.state.entryMode === VehicleEntryMode.YearMakeModel && this.state.currentSection === YearMakeModelSection.Year) ||
      this.state.entryMode === VehicleEntryMode.Vin) {
      if (this.props.onPreviousClick) {
        this.props.onPreviousClick();
      }
    }
    else {
      // reset validation
      this.props.validation({}, validationLevel.Vehicles).setErrorVisibility(false);

      this.setState({
        currentSection: this.state.currentSection - 1
      })
    }
  }

  nextClickHandler() {
    // in these scenarios, the last input is visible so do the normal next
    if (this.props.isLgScreen ||
      (this.state.entryMode === VehicleEntryMode.YearMakeModel && this.state.currentSection === YearMakeModelSection.BodyStyle) ||
      this.state.entryMode === VehicleEntryMode.Vin) {
      if (this.props.onNextClick && this.isValid()) {
        this.props.onNextClick();
      }
    }

    // in this scenario, the current section is less than the first invalid one so advance to next section
    else if (this.state.currentSection < this.getCurrentSection(this.state, true)) {
      this.setState({
        currentSection: this.state.currentSection + 1
      });
    }

    // else the current section is invalid so show validation
    else {
      this.isValid()
    }
  }

  addClickHandler() {
    if (this.props.onAddClick) {
      this.props.onAddClick();
    }
  }

  updateVehicle() {
    let currentVehicle = this.props.vehicles[this.props.currentIndex];

    if (currentVehicle) {
      this.props.updateVehicle({
        ...currentVehicle,
        entryMode: this.state.entryMode,
        year: this.state.year,
        make: this.state.make,
        model: this.state.model,
        bodyStyle: this.state.bodyStyle,
        vin: this.state.vin
      });
    }
  }

  isValidForAdd(): boolean {
    return this.props.validation(this.getValidationObjectForAdd(), validationLevel.Vehicles).isPageValid(ShowErrors.NeverShow);
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObject(), validationLevel.Vehicles).isPageValid(showErrors, this.props.currentIndex);
  }

  yearClickHandler(year: number) {
    // reset validation
    this.props.validation({}, validationLevel.Vehicles).setErrorVisibility(false);

    if (year !== this.state.year) {
      this.setState({
        year: year,
        make: '',
        model: '',
        bodyStyle: null,
        vin: ''
      });
    };

    this.setState({
      currentSection: YearMakeModelSection.Make
    });
  }

  vinEntrykHandler() {
    // reset validation
    this.props.validation({}, validationLevel.Vehicles).setErrorVisibility(false);

    this.setState({
      entryMode: VehicleEntryMode.Vin
    })

    // if VIN isn't a full VIN, clear any selected vehicle info so validation on vin entry works properly
    if (this.state.vin.length < 17) {
      this.setState({
        year: 0,
        make: '',
        model: '',
        bodyStyle: null,
        vin: ''
      })
    }
  }

  yearMakeModelEntrykHandler() {
    // reset validation
    this.props.validation({}, validationLevel.Vehicles).setErrorVisibility(false);

    this.setState({
      entryMode: VehicleEntryMode.YearMakeModel,
      currentSection: this.getCurrentSection(this.state, this.props.isLgScreen)
    });
  }

  makeClickHandler(make: string) {
    // reset validation
    this.props.validation({}, validationLevel.Vehicles).setErrorVisibility(false);

    if (make !== this.state.make) {
      this.setState({
        make: make,
        model: '',
        bodyStyle: null,
        vin: ''
      });
    }

    this.setState({
      currentSection: YearMakeModelSection.Model
    });
  }

  modelClickHandler(model: string) {
    // reset validation
    this.props.validation({}, validationLevel.Vehicles).setErrorVisibility(false);

    if (model !== this.state.model) {
      this.setState({
        model: model,
        bodyStyle: null,
        vin: ''
      });
    }

    this.setState({
      currentSection: YearMakeModelSection.BodyStyle
    });
  }

  bodyStyleClickHandler(bodyStyle: BodyStyleInfo) {
    // reset validation
    this.props.validation({}, validationLevel.Vehicles).setErrorVisibility(false);

    if (!isEqual(bodyStyle, this.state.bodyStyle)) {
      this.setState({
        bodyStyle: bodyStyle,
        vin: bodyStyle.vinDesc
      });
    }
  }

  vinChangeHandler(vin: string, year: number, make: string, model: string, bodyStyle: BodyStyleInfo | null) {
    this.setState({
      vin,
      year,
      make,
      model,
      bodyStyle
    })
  }

  getCurrentSection(currentVehicle: Vehicle | QuestionGroupState, startFromEnd: boolean): YearMakeModelSection {
    let currentSection: YearMakeModelSection = YearMakeModelSection.Year;

    // (large screen, user clicked back, or need first invalid), need to find the actual last section
    // other cases can just default to Year
    if (startFromEnd) {
      if (currentVehicle.year !== 0) {
        currentSection = YearMakeModelSection.Make;
      }
      if (currentVehicle.make !== '') {
        currentSection = YearMakeModelSection.Model;
      }
      if (currentVehicle.model !== '') {
        currentSection = YearMakeModelSection.BodyStyle;
      }
    }

    return currentSection
  }

  render() {
    const { isLgScreen } = this.props;
    const isLeftHeader = this.props.site.agencyInfoPosition === AgencyInfoPosition.Left ? true : false

    const yearClass = classNames({
      'bg-light': true,
      'd-lg-block': true,
      'd-none': this.state.currentSection !== YearMakeModelSection.Year
    });
    const makeClass = classNames({
      'bg-light': true,
      'd-lg-block': this.state.currentSection === YearMakeModelSection.Make ||
        this.state.currentSection === YearMakeModelSection.Model ||
        this.state.currentSection === YearMakeModelSection.BodyStyle,
      'd-none': this.state.currentSection !== YearMakeModelSection.Make
    });
    const modelClass = classNames({
      'bg-light': true,
      'd-lg-block': this.state.currentSection === YearMakeModelSection.Model ||
        this.state.currentSection === YearMakeModelSection.BodyStyle,
      'd-none': this.state.currentSection !== YearMakeModelSection.Model
    });
    const bodyStyleClass = classNames({
      'bg-light': true,
      'd-lg-block': this.state.currentSection === YearMakeModelSection.BodyStyle,
      'd-none': this.state.currentSection !== YearMakeModelSection.BodyStyle
    });

    // if on large device, just use the year as the key.  We don't need to rerender the swipeable when the section changes
    // else if on a small device, change the key for the swipeable.  We need to rerender the swipeble when the section changes 
    // so that it starts in the correct position instead of sliding back in
    let swipeableKey = YearMakeModelSection.Year;
    if (!isLgScreen) {
      swipeableKey = this.state.currentSection;
    }

    const setTabKey = (key: string) => {
      this.setState({ tabKey: key });
    };

    return (
      <Form>
        <h3 className='text-center'>
          Let's get your vehicle
        </h3>

        <Swipeable className='col-12' onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler} key={swipeableKey}>
          <Row className='row justify-content-center align-items-center pt-2'>
            {
              this.state.entryMode === VehicleEntryMode.YearMakeModel &&
              <Fragment>
                <Col xs="12" sm="9" md="7" lg="2" className={yearClass}>
                  <YearList currentIndex={this.props.currentIndex} year={this.state.year} onClick={this.yearClickHandler} isLgScreen={isLgScreen}
                    tabKey={this.state.tabKey} setTabKey={setTabKey} onVinClick={this.vinEntrykHandler} visible={isLgScreen || this.state.currentSection === YearMakeModelSection.Year} />
                </Col>
                <Col xs="12" sm="9" md="7" lg={isLeftHeader ? "4" : "3"} className={makeClass}>
                  <MakeList currentIndex={this.props.currentIndex} year={this.state.year} make={this.state.make} onClick={this.makeClickHandler}
                    tabKey={this.state.tabKey} setTabKey={setTabKey} visible={isLgScreen || this.state.currentSection === YearMakeModelSection.Make} />
                </Col>
                <Col xs="12" sm="9" md="7" lg="3" className={modelClass}>
                  <ModelList currentIndex={this.props.currentIndex} year={this.state.year} make={this.state.make} model={this.state.model} onClick={this.modelClickHandler} isLgScreen={isLgScreen}
                    tabKey={this.state.tabKey} setTabKey={setTabKey} visible={isLgScreen || this.state.currentSection === YearMakeModelSection.Model} />
                </Col>
                <Col xs="12" sm="9" md="7" lg={isLeftHeader ? "3" : "4"} className={bodyStyleClass}>
                  <BodyStyleList currentIndex={this.props.currentIndex} year={this.state.year} make={this.state.make} model={this.state.model} bodyStyle={this.state.bodyStyle} onClick={this.bodyStyleClickHandler} isLgScreen={isLgScreen}
                    tabKey={this.state.tabKey} setTabKey={setTabKey} visible={isLgScreen || this.state.currentSection === YearMakeModelSection.BodyStyle} />
                </Col>
              </Fragment>
            }
            {
              this.state.entryMode === VehicleEntryMode.Vin &&
              <VinEntry currentIndex={this.props.currentIndex} vin={this.state.vin} year={this.state.year} make={this.state.make} model={this.state.model} bodyStyle={this.state.bodyStyle}
                onChange={this.vinChangeHandler} onYearMakeModelClick={this.yearMakeModelEntrykHandler} />
            }
          </Row>
        </Swipeable>
        <Col className='text-right pr-0'>
          <AddButton isDisabled={!this.isValidForAdd()} onClick={this.addClickHandler}
            isHidden={this.props.vehicles.length === 4}>
            Vehicle
          </AddButton>
        </Col>
        <hr />
        <Row>         
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Form>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  vehicles: state.quote.vehicles,
  site: state.site
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateVehicle: (vehicle: Vehicle) => dispatch(QuoteActions.updateVehicle(vehicle)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
})

export default WithMediaQuery([{ PropName: "isLgScreen", Query: { minWidth: 992 } }])(validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup1)));
