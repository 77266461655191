import { Site, initialState } from './state';
import { Actions as SiteActions, Action as SiteAction } from './actions';
import { Action, Reducer } from 'redux';

export const reducer: Reducer<Site> = (state: Site = initialState, incomingAction: Action): Site => {
  const action = incomingAction as SiteAction;

  switch (action.type) {
    case SiteActions.REQUEST_SITE:
      return {
        ...state,
        isLoading: true
      };
    case SiteActions.RECEIVE_SITE:
      return {
        ...action.site,
        isLoading: false
      };
    case SiteActions.REQUEST_SITE_BY_SITE_KEY:
      return {
        ...state,
        isLoading: true
      }
  }

  return state;
};
