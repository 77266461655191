import React from 'react';
import { Label } from 'reactstrap';

interface ValidationLabelProps extends React.PropsWithChildren {
  config: any,
  index?: number,
  className?: any,
  style?: React.CSSProperties
}

class ValidationLabel extends React.PureComponent<ValidationLabelProps> {
  render() {
    let { 
      config,
      index = 0,
      className,
      style,
      children
    } = this.props;

    let id = config.fieldName;
    if (index > 0) {
      id = id + '_' + index;
    }

    let display = config.display.trim();
    if (!display.endsWith('?')) {
      display = display + ':';
    }

    let attributes: any = {}
    if (className) {
      attributes['className'] = className
    }
    if (style) {
      attributes['style'] = style;
    }

    return (
      <Label for={id} {...attributes}>
        {children || display}
      </Label>
    )
  }
}

export default ValidationLabel;
