import React, { Fragment } from 'react';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { Action as ValidationAction, actionCreators as ValidationActions } from '../../store/Validation/actions';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import QuestionGroup1 from './QuestionGroup1';
import QuestionGroup2 from './QuestionGroup2';
import QuestionGroup3 from './QuestionGroup3';
import Stepper from '../Stepper';
import { hasLob } from '../../services/helper';
import { StepType } from '../../store/Stepper/state';
import { validationConfig } from '../../services/validationConfig';

type HomePageProps =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface HomePageState {
  currentQuestionGroup: number
}

class HomePage extends React.PureComponent<HomePageProps, HomePageState> {
  constructor(props: any) {
    super(props);

    let startQuestionGroup = 1;

    this.setTotalGroups(props.quote.homeCharacteristics!.renovated, props.quote.homeCharacteristics!.hasHomeLosses);

    if (this.props.isBackPage) {
      if (this.props.validationErrors && this.props.validationErrors.length > 0) {
        let error = this.props.validationErrors[0];
        startQuestionGroup = validationConfig[error.level][error.field].questionGroup;
      }
      else {
        startQuestionGroup = this.totalGroups;
      }
      this.props.clearIsBackPage();
    }

    this.state = {
      currentQuestionGroup: startQuestionGroup
    }

    this.setTotalGroups = this.setTotalGroups.bind(this);

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
  }

  totalGroups: number = 1;
  hasHomeLosses: boolean = false;
  renovated: boolean = false;
  
  setTotalGroups(renovated: boolean, hasHomeLosses: boolean) {
    this.totalGroups = 1;

    if (renovated) {
      this.renovated = true;
      this.totalGroups = 2;
    }
    else {
      this.renovated = false;
    }
    if (hasHomeLosses) {
      this.hasHomeLosses = true
      this.totalGroups = 3;
    }
    else {
      this.hasHomeLosses = false;    
    }
  }

  previousClickHandler() {
    const { hasAuto } = hasLob(this.props.quote);

    this.props.updateErrorsVisible(false);

    if (this.state.currentQuestionGroup === 1) {
      this.props.changeStep(StepType.HomeCharacteristics, hasAuto ? StepType.Vehicles : StepType.ApplicantDrivers);
    }
    else {
      let previousQuestionGroup = this.state.currentQuestionGroup - 1;

      // skip renovations group if not renovated
      if (previousQuestionGroup === 2 && !this.renovated) {
        previousQuestionGroup = previousQuestionGroup - 1;
      }

      this.setState({
        currentQuestionGroup: previousQuestionGroup
      });
    }
  }

  nextClickHandler() {
    if (this.state.currentQuestionGroup === this.totalGroups) {
      this.props.changeStep(StepType.HomeCharacteristics, StepType.Coverages);
    }
    else {
      let nextQuestionGroup = this.state.currentQuestionGroup + 1;

      // skip renovations group if not renovated
      if (nextQuestionGroup === 2 && !this.renovated) {
        nextQuestionGroup = nextQuestionGroup + 1;
      }

      this.setState({
        currentQuestionGroup: nextQuestionGroup
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Stepper />

        {(this.state.currentQuestionGroup === 1) &&
          <QuestionGroup1 
            onRenovatedChange={this.setTotalGroups}
            onHomeLossesChange={this.setTotalGroups}
            onPreviousClick={this.previousClickHandler}
            onNextClick={this.nextClickHandler} />
        }
        {(this.state.currentQuestionGroup === 2) && 
          <QuestionGroup2
            onPreviousClick={this.previousClickHandler}
            onNextClick={this.nextClickHandler} />
        }
        {(this.state.currentQuestionGroup === 3) &&
          <QuestionGroup3
            onPreviousClick={this.previousClickHandler}
            onNextClick={this.nextClickHandler} />
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote,
  isBackPage: state.stepper.isBackPage,
  validationErrors: state.validation.errors
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, StepperAction | ValidationAction>) => ({
  changeStep: (current: StepType, selected: StepType) => dispatch(StepperActions.changeStep(current, selected)),
  clearIsBackPage: () => dispatch(StepperActions.clearIsBackPage()),
  updateErrorsVisible: (errorsVisible: boolean) => dispatch(ValidationActions.updateErrorsVisible(errorsVisible))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(HomePage as any);