import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import '../custom.scss';
import './ConfirmBox.scss';
import { Tooltip } from 'react-tooltip';
import { Middleware, MiddlewareState, flip, offset } from '@floating-ui/react';

interface ConfirmBoxProps {
  text: string,
  yesButton_Text: string,
  noButton_Text: string,
  yesButton_onClick: () => void,
  fieldName: string;
  fieldcolor: string;
  fieldsize: string;
  confirmId: string;
}

class ConfirmBox extends React.PureComponent<ConfirmBoxProps> {
  tooltip: any;

  setPositionX: Middleware = {
    name: 'setPositionX',
    fn: this.getTooltipPosition
  };

  middlewares: Middleware[] = [
    offset(10),
    flip({
      fallbackAxisSideDirection: 'start',
    }),
    this.setPositionX
  ]

  getTooltipPosition({ x, y }: MiddlewareState) {
    //override tooltip position to be centered horizontally
    x = (window.innerWidth / 2) - 125;

    return { x, y };
  }

  render() {
    return (
      <Fragment>
        <span
          data-tooltip-id={this.props.confirmId}
          data-testid='testConfirmId'
          className='ml-1 text-primary'
          style={{ cursor: 'pointer' }}>
          <Button color={ this.props.fieldcolor } size={this.props.fieldsize}>{this.props.fieldName}</Button>                 
        </span>

        <Tooltip
          id={this.props.confirmId}
          clickable={true}
          place='right'
          variant='light'
          openOnClick={true}
          globalCloseEvents={{
            clickOutsideAnchor: true,
            scroll: true,
            resize: true
          }}
          className='confirm-box__display'
          ref={el => this.tooltip = el}
          positionStrategy="fixed"
          middlewares={this.middlewares}
          opacity={1}
        >
          <div>   
            <p className='mt-1'>{this.props.text}</p>
            <div className='text-center'>
              <Button
                data-testid='confirmYesId'
                color='primary'
                onClick={() => {
                  this.props.yesButton_onClick();
                  this.tooltip.close();
                }}>
                {this.props.yesButton_Text}
              </Button>   
              <Button className='ml-3 ml-md-3'
                data-testid='confirmNoId'
                onClick={() => {
                  this.tooltip.close();
                }}>
                {this.props.noButton_Text}
              </Button>
            </div>
          </div>
        </Tooltip>
      </Fragment>
    )
  }
}

export default ConfirmBox;
