import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import AgencyHeader from './AgencyHeader';
import ErrorBoundary from './ErrorBoundary'
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { AgencyInfoPosition } from '../store/Site/state';
import { useMediaQuery } from 'react-responsive';
import AgencyFooter from './AgencyFooter';

type LayoutProps =
  ReturnType<typeof mapStateToProps> &
  React.PropsWithChildren;

class Layout extends React.Component<(LayoutProps)>  {
  MobileLayout = () => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });

    if (isMobile) {
      return (
        <Container>
          <ErrorBoundary>
            <AgencyHeader />
            {/* additional boundary so that agency header will be display for anything inside of it */}
            <ErrorBoundary>
              <Container>
                {this.props.children}
              </Container>
            </ErrorBoundary>
            <AgencyFooter />
          </ErrorBoundary>
        </Container>
      )
    }
    else {
      return null;
    }
  }

  DesktopLayout = () => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    let site = this.props.site;

    if (isMobile) {
      return null;
    }

    if (site.agencyInfoPosition === AgencyInfoPosition.Left) {
      return (
        <Container>
          <ErrorBoundary>
            <Row>
              <Col xs='2'>
                <AgencyHeader />
              </Col>
              <Col xs='10'>
                {/* additional boundary so that agency header will be display for anything inside of it */}
                <ErrorBoundary>
                  <Container>
                    {this.props.children}
                  </Container>
                </ErrorBoundary>
              </Col>
            </Row>
            <AgencyFooter />
          </ErrorBoundary>
        </Container>
      );
    }
    else {
      return (
        <Container>
          <ErrorBoundary>
            <AgencyHeader />
            {/* additional boundary so that agency header will be display for anything inside of it */}
            <ErrorBoundary>
              <Container>
                {this.props.children}
              </Container>
            </ErrorBoundary>
            <AgencyFooter />
          </ErrorBoundary>
        </Container>
      );
    }
  }

  render() {
    return (
      <Fragment>
        <this.DesktopLayout />
        <this.MobileLayout />
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site
});

export default connect(
  mapStateToProps
)(Layout);
