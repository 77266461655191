import { AppThunkAction } from './../';
import { QuestionOption } from './state';
import axios, { AxiosResponse, AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling';
import * as Promise from 'promise';
import { getBaseUrl } from '../../services/helper';
import { LineOfBusinessType } from '../Quote/state';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export enum Actions {
  REQUEST_QUESTION_OPTIONS = 'REQUEST_QUESTION_OPTIONS',
  RECEIVE_QUESTION_OPTIONS = 'RECEIVE_QUESTION_OPTIONS'
}

interface RequestQuestionOptionsAction {
  type: Actions.REQUEST_QUESTION_OPTIONS,
  lineOfBusiness: LineOfBusinessType,
  state: string,
  agencyId: number
}

interface ReceiveQuestionOptionsAction {
  type: Actions.RECEIVE_QUESTION_OPTIONS;
  questionOptions: Record<string, QuestionOption[]>;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type Action = RequestQuestionOptionsAction | ReceiveQuestionOptionsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

var requestQuestionOptions = (lineOfBusiness: LineOfBusinessType, state: string, agencyId: number): AppThunkAction<Action> => (dispatch, getState) => {
  // Only load data if it's something we don't already have (and are not already loading)
  const appState = getState();
  if (appState && appState.questionOptions && !appState.questionOptions.isLoading &&
    (Object.keys(appState.questionOptions.options).length === 0 ||
      appState.questionOptions.lineOfBusiness !== lineOfBusiness ||
      appState.questionOptions.state !== state ||
      appState.questionOptions.agencyId !== agencyId)) {
    dispatch({
      type: Actions.REQUEST_QUESTION_OPTIONS,
      lineOfBusiness,
      state,
      agencyId
    });

    return axios.get(getBaseUrl() + `/options/${lineOfBusiness}/${state}/${agencyId}`)
      .then((response: AxiosResponse<Record<string, QuestionOption[]>>) => {
        dispatch(receiveQuestionOptions(response.data));
      })
      .catch((error: AxiosError) => {
        dispatch(receiveQuestionOptions({} as Record<string, QuestionOption[]>));
        if (error.response && error.response.status !== 404) {
          errorResponseHandler(error);
        }
      });
  }
  else {
    return Promise.resolve();
  }
};

var receiveQuestionOptions = (questionOptions: Record<string, QuestionOption[]>): ReceiveQuestionOptionsAction => {
  return {
    type: Actions.RECEIVE_QUESTION_OPTIONS,
    questionOptions: questionOptions
  }
};

export const actionCreators = {
  requestQuestionOptions,
  receiveQuestionOptions
};

