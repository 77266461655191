import React from 'react';
import { connect } from 'react-redux';
import StartQuotePage from './StartQuotePage';
import TestPage from './TestPage';
import { getQueryStringPage } from '../services/helper';
import ErrorPage from './ErrorPage';
import history, { updateCrqPage } from '../services/historyWrapper';
import DriverPage from './DriverPage/DriverPage';
import VehiclePage from './VehiclePage/VehiclePage';
import HomePage from './HomePage/HomePage';
import CoveragePage from './CoveragePage/CoveragePage';
import RatePage from './RatePage/RatePage';
import { ApplicationState } from '../store';
import TimedOut from './TimedOut';
import IdleTimer from 'react-idle-timer';

const PAGES = {
  testpage: <TestPage />,
  startquotepage: <StartQuotePage />,
  driverpage: <DriverPage />,
  vehiclepage: <VehiclePage />,
  homepage: <HomePage />,
  coveragepage: <CoveragePage />,
  ratepage: <RatePage />,
  errorpage: <ErrorPage />,
  timedoutpage: <TimedOut />
};

type OwnProps = {
  timeout: number;
};

type MasterPageProps =
  OwnProps &
  ReturnType<typeof mapStateToProps>;

interface MasterPageState {
  crqPage: string
}

class MasterPage extends React.PureComponent<MasterPageProps, MasterPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      crqPage: getQueryStringPage(history.location) || 'startquotepage'
    }

    history.listen(location => {
      this.setState({
        crqPage: getQueryStringPage(history.location) || 'startquotepage'
      });
    });

    this.onIdle = this.onIdle.bind(this);
  }

  timedOutPageIgnoreList = ['timedoutpage', 'startquotepage'];

  onIdle = () => {
    if (!this.timedOutPageIgnoreList.includes(this.state.crqPage)) {
      updateCrqPage('timedoutpage');
    }
  };

  render() {
    const gotoStartQuotePage = (e: any) => {
      e.preventDefault();
      updateCrqPage('startquotepage');
    }

    const gotoTestPage = (e: any) => {
      e.preventDefault();
      updateCrqPage('testpage');
    }

    return (
      <div>
        <IdleTimer
          onIdle={this.onIdle}
          timeout={this.props.timeout}
          debounce={250}
        />

        {(PAGES as any)[this.state.crqPage]}

        {this.props.site.showTestOptions &&
          <div className='mt-5'>
            <span>Test Links:&nbsp;&nbsp;</span>
            <a href='/' data-testid='gotoQuoteStartPage' onClick={gotoStartQuotePage} tabIndex={-1}>Quote Start Page</a>
            <span> | </span>
            <a href='/' data-testid='gotoTestPage' onClick={gotoTestPage} tabIndex={-1}>Test Page</a>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site
});

export default connect(
  mapStateToProps
)(MasterPage);
