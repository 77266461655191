import axios, { AxiosResponse, AxiosError } from 'axios';
import { getBaseUrl } from './helper';
import { QuoteState, RateStatus } from '../store/Quote/state';
import { Stepper, StepType } from '../store/Stepper/state';
import errorResponseHandler from './errorHandling';
import { debounce, isEqual, cloneDeep } from 'lodash';

var lastQuoteState: QuoteState | null = null;

// the export here is only for unit testing.  This function should never be used directly.  Use the default export instead
export const submitService = (agencyId: number, quote: QuoteState, stepper: Stepper) => {
  if (!isEqual(quote, lastQuoteState)) {
    // keep a copy of quote since changes to quote would also be reflected in lastQuoteState and might cause a change to not actually save.
    lastQuoteState = cloneDeep(quote);

    if (shouldSubmit(quote, stepper)) {
      return axios.post(getBaseUrl() + `/submit/${agencyId}`, quote)
        .then((response: AxiosResponse<string>) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          errorResponseHandler(error);
        });
    }
  }
  // if not actually submitting, return a resolved promise;
  return Promise.resolve()
}

const shouldSubmit = (quote: QuoteState, stepper: Stepper) => {
  return (
    // need a driver with a name
    quote.drivers.length > 0 &&
    quote.drivers[0].firstName !== '' &&
    quote.drivers[0].lastName !== '' &&
    // only submit unrated quotes;  quotes that are rated or in progress will be submitted as part of the rate process
    quote.rateStatus === RateStatus.NeedsRated && 
    stepper.selected !== StepType.Rates
  );
}

export default debounce(submitService, 500)



