import { useState, ReactNode } from 'react';
import { useSwipeable, SwipeEventData } from 'react-swipeable'
import WithMediaQuery from './WithMediaQuery';

interface SwipeableProps {
  isMdScreen: boolean,
  isLgScreen: boolean,
  className?: string,
  onSwipeLeft?: Function,
  onSwipeRight?: Function,
  children?: ReactNode;
}

export const Swipeable = (props: SwipeableProps) => {
  const [marginLeft, setMarginLeft] = useState(0);
  const [transitionTime, setTransitionTime] = useState('0ms');

  let maxSwipeAmount: number;
  let swipeTriggerThreshold: number;

  if (props.isLgScreen) {
    maxSwipeAmount = 150;
    swipeTriggerThreshold = 100;
  }
  else if (props.isMdScreen) {
    maxSwipeAmount = 110;
    swipeTriggerThreshold = 75;
  }
  else {
    maxSwipeAmount = 60;
    swipeTriggerThreshold = 40;
  }

  const onSwiping = (eventData: SwipeEventData) => {
    let marginLeft = eventData.deltaX;
    if (marginLeft > maxSwipeAmount) {
      marginLeft = maxSwipeAmount;
    }
    else if (marginLeft < -maxSwipeAmount) {
      marginLeft = -maxSwipeAmount;
    }
    setMarginLeft(marginLeft);
    setTransitionTime('0ms');
  };

  const onSwipedLeft = (eventData: SwipeEventData) => {
    if (props.onSwipeLeft && Math.abs(eventData.deltaX) > swipeTriggerThreshold) {
      props.onSwipeLeft();
    }
  };

  const onSwipedRight = (eventData: SwipeEventData) => {
    if (props.onSwipeRight && eventData.deltaX > swipeTriggerThreshold) {
      props.onSwipeRight();
    }
  };

  const onSwiped = (eventData: SwipeEventData) => {
    setMarginLeft(0);
    setTransitionTime('500ms');
  };

  let { className } = props;

  let attributes: any = {};

  if (className) {
    attributes.className = className;
  }

  const handlers = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
    onSwiped,
    onSwiping
  });

  return (

    <div {...attributes}
      style={{
        marginLeft: `${marginLeft}px`,
        marginRight: `${-1 * marginLeft}px`,
        transition: `margin ${transitionTime}`
      }}
      {...handlers}
    >
      {props.children}
    </div>
  );
};

export default WithMediaQuery([
  { PropName: "isMdScreen", Query: { minWidth: 768, maxWidth: 991 } },
  { PropName: "isLgScreen", Query: { minWidth: 992 } }
])(Swipeable);
