import React from 'react';
import history from '../services/historyWrapper';
import { Row, Col, Container } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa';

class ErrorPage extends React.PureComponent {

  render() {
    const locationState: any = history.location.state || {};
    const refId = locationState.crqRefId || '';
    let refIdLine;

    if (refId !== '') {
      // only show the line when refId is something
      // if get here without refId, error handling wrote to console because server-side did not handle the error
      refIdLine = <h6 data-testid="reference-id" className='text-secondary text-center text-sm-left'>Reference ID:  {refId}</h6>;
    }

    return (
      <Container>
        <div className="rounded px-3 px-sm-4 py-3 py-sm-5 bg-light mt-4">
          <Row>
            <Col sm='3' className='align-center mb-4 mb-sm-0 d-flex'>
              <FaExclamationTriangle
                size={70}
                className='text-danger m-auto d-block'
              />
            </Col>
            <Col sm='9'>
              <h3 className='mb-4 text-center text-sm-left'>Error</h3>
              <h5 className='text-center text-sm-left'>Unable to complete your quote at this time, please try again later.</h5>
              {refIdLine}
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default ErrorPage;