import React, { Fragment } from 'react';
import { Vehicle } from '../../store/Quote/Vehicle/vehicle';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { Action as ValidationAction, actionCreators as ValidationActions } from '../../store/Validation/actions';

import Tabs from '../Tabs';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import QuestionGroup1 from './QuestionGroup1';
import QuestionGroup2 from './QuestionGroup2';
import Stepper from '../Stepper';
import { hasLob } from '../../services/helper';
import { StepType } from '../../store/Stepper/state';
import { validationConfig } from '../../services/validationConfig';

type VehiclePageProps =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface VehiclePageState {
  currentQuestionGroup: number,
  currentTabIndex: number,
  isFromPrevious: boolean
}

class VehiclePage extends React.PureComponent<VehiclePageProps, VehiclePageState> {
  constructor(props: any) {
    super(props);

    // add first vehicle if there isn't one
    if (props.vehicles.length === 0) {
      props.addVehicle();
    }

    let startQuestionGroup = 1;
    let startTabIndex = 0;

    if (this.props.isBackPage) {
      if (this.props.validationErrors && this.props.validationErrors.length > 0) {
        let error = this.props.validationErrors[0];
        startTabIndex = (error.hierarchy.index as number);
        startQuestionGroup = validationConfig[error.level][error.field].questionGroup;
      }
      else {
        startTabIndex = this.props.vehicles.length - 1;
        startQuestionGroup = this.totalGroups;
      }

      this.props.clearIsBackPage();
    }

    this.state = {
      currentQuestionGroup: startQuestionGroup,
      currentTabIndex: startTabIndex,
      isFromPrevious: false
    }

    this.tabClickHandler = this.tabClickHandler.bind(this);
    this.addClickHandler = this.addClickHandler.bind(this);
    this.removeClickHandler = this.removeClickHandler.bind(this);
    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
  }

  totalGroups: number = 2;

  tabClickHandler(tabIndex: number) {
    this.setState({
      currentQuestionGroup: 1,
      currentTabIndex: tabIndex,
      isFromPrevious: false
    });
  }

  addClickHandler() {
    const newTabIndex = this.props.vehicles.length;

    this.props.addVehicle();

    this.setState({
      currentQuestionGroup: 1,
      currentTabIndex: newTabIndex,
      isFromPrevious: false
    })
  }

  removeClickHandler(tabIndex: number) {
    // if the tab being removed is before the current tab then back up one (so user will stay on current vehicle)
    // if the tab being removed is the last tab and is also the current tab then back up one (user will go to the new last vehicle)
    let newTabIndex = this.state.currentTabIndex;
    if ((tabIndex < this.state.currentTabIndex) ||
      (tabIndex === this.props.vehicles.length - 1 && tabIndex === this.state.currentTabIndex)) {
      newTabIndex = newTabIndex - 1;
    }

    this.props.removeVehicle(tabIndex);

    this.props.updateErrorsVisible(false);

    this.setState({
      currentQuestionGroup: 1,
      currentTabIndex: newTabIndex,
      isFromPrevious: false
    })
  }

  previousClickHandler() {
    this.props.updateErrorsVisible(false);

    if (this.state.currentQuestionGroup === 1) {
      if (this.state.currentTabIndex === 0) {
        this.props.changeStep(StepType.Vehicles, StepType.ApplicantDrivers);
      }
      else {
        this.setState({
          currentTabIndex: this.state.currentTabIndex - 1,
          currentQuestionGroup: this.totalGroups,
          isFromPrevious: true
        });
      }
    }
    else {
      this.setState({
        currentQuestionGroup: this.state.currentQuestionGroup - 1,
        isFromPrevious: true
      });
    }
  }

  nextClickHandler() {
    if (this.state.currentQuestionGroup === this.totalGroups) {
      if (this.state.currentTabIndex + 1 === this.props.vehicles.length) {
        // last vehicle / last page, goto next step
        const { hasHome } = hasLob(this.props.quote);
        this.props.changeStep(StepType.Vehicles, hasHome ? StepType.HomeCharacteristics : StepType.Coverages);
      }
      else {
        this.setState({
          currentTabIndex: this.state.currentTabIndex + 1,
          currentQuestionGroup: 1,
          isFromPrevious: false
        });
      }
    }
    else {
      this.setState({
        currentQuestionGroup: this.state.currentQuestionGroup + 1,
        isFromPrevious: false
      });
    }
  }

  render() {
    let tabItems = this.props.vehicles.map((vehicle: Vehicle, index: number) => {
      if (vehicle.year !== 0 && vehicle.make !== '' && vehicle.model !== '') {
        return `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
      }
      else {
        return "Vehicle " + (index + 1);
      }
    });

    return (
      <Fragment>
        <Stepper />

        <Tabs currentIndex={this.state.currentTabIndex}
          items={tabItems}
          itemType="Vehicle"
          onTabClick={this.tabClickHandler}
          onRemoveClick={this.removeClickHandler}
        />

        {(this.state.currentQuestionGroup === 1) &&
          this.props.vehicles[this.state.currentTabIndex] &&
          <QuestionGroup1 currentIndex={this.state.currentTabIndex}
            key={this.props.vehicles[this.state.currentTabIndex].id}
            isFromPrevious={this.state.isFromPrevious}
            onPreviousClick={this.previousClickHandler}
            onNextClick={this.nextClickHandler}
            onAddClick={this.addClickHandler} />
        }
        {(this.state.currentQuestionGroup === 2) &&
          this.props.vehicles[this.state.currentTabIndex] &&
          <QuestionGroup2 currentIndex={this.state.currentTabIndex}
            key={this.props.vehicles[this.state.currentTabIndex].id}
            onPreviousClick={this.previousClickHandler}
            onNextClick={this.nextClickHandler}
            onAddClick={this.addClickHandler} />
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote,
  vehicles: state.quote.vehicles,
  isBackPage: state.stepper.isBackPage,
  validationErrors: state.validation.errors
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction | ValidationAction>) => ({
  addVehicle: () => dispatch(QuoteActions.addVehicle()),
  removeVehicle: (index: number) => dispatch(QuoteActions.removeVehicle(index)),
  changeStep: (current: StepType, selected: StepType) => dispatch(StepperActions.changeStep(current, selected)),
  clearIsBackPage: () => dispatch(StepperActions.clearIsBackPage()),
  updateErrorsVisible: (errorsVisible: boolean) => dispatch(ValidationActions.updateErrorsVisible(errorsVisible))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(VehiclePage as any);
