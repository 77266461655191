import { StartupParameters } from './state';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export enum Actions {
  SET_STARTUP_PARAMETERS = 'SET_STARTUP_PARAMETERS'
}

interface SetStartupParameters {
  type: Actions.SET_STARTUP_PARAMETERS,
  startupParameters: StartupParameters
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type Action = SetStartupParameters

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

var setStartupParameters = (startupParameters: StartupParameters): SetStartupParameters => {
  return {
    type: Actions.SET_STARTUP_PARAMETERS,
    startupParameters
  };
};

export const actionCreators = {
  setStartupParameters
};

