// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export enum StepType {
  None = 0,
  ApplicantDrivers = 1,
  Vehicles = 2,
  HomeCharacteristics = 3,
  Coverages = 4,
  Rates = 5
}

export const stepPage = {
  0: '',
  1: 'driverpage',
  2: 'vehiclepage',
  3: 'homepage',
  4: 'coveragepage',
  5: 'ratepage',
}

export const initialState: Stepper = {
  active: StepType.None,
  complete: StepType.None,
  selected: StepType.None,
  isValid: () => true,
  isBackPage: false
};

export interface Stepper {
  active: StepType;
  complete: StepType;
  selected: StepType;
  isValid: () => boolean;
  isBackPage: boolean;
}