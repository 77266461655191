import React from 'react';
import { Button } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import '../custom.scss';
import classNames from 'classnames';

interface AddButtonProps extends React.PropsWithChildren {
  onClick?: () => void,
  isDisabled: boolean,
  isHidden: boolean,
  title?: string
}

class AddButton extends React.PureComponent<AddButtonProps> {
  render() {
    let { onClick = () => null,
      isDisabled,
      isHidden } = this.props;

    let attributes: any = {}

    if (this.props.title) {
      attributes.title = this.props.title
    }
    return (
      <Button data-testid='addButtonTest' color={isDisabled ? 'previous' : 'success'} className={classNames('mt-3 text-nowrap', { 'd-none': isHidden })} disabled={isDisabled} onClick={() => onClick()} tabIndex={-1} {...attributes}>
        <FaPlusCircle className={classNames({ 'text-white': !isDisabled, 'text-secondary': isDisabled })} style={{ marginTop: '-4px' }} />
        &nbsp;{this.props.children}
      </Button>
    )
  }
}

export default AddButton;
