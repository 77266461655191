import React from 'react';
import { ApplicationState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action as ValidationAction, actionCreators as ValidationActions } from '../store/Validation/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ErrorInfo } from '../store/Validation/state';
import { validate, getErrors, ValidationQuoteInfo } from '../services/validation';
import { validationLevel } from '../services/validationConfig';
import classNames from 'classnames';
import { isEqual } from 'lodash';

export enum ShowErrors {
  NeverShow,
  ShowIfVisible,
  AlwaysShow
}

export type ValidationProps = {
  validation: (objToValidate: any, level: validationLevel) => {
    isPageValid: (showErrors?: ShowErrors, conditionIndex?: number) => boolean,
    setErrorVisibility: (errorsVisible: boolean, setFocus?: boolean) => void,
    getFieldProps: (fieldConfig: any, className?: string, index?: number) => any,
    isFieldInvalid: (fieldConfig: any, index?: number) => boolean,
    getErrorText: (fieldConfig: any, index?: number) => any,
  }
}

type OwnValidationProps =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;


// This function takes a component...
const validation = (WrappedComponent: any) => {
  // ...and returns another component...
  class Validation extends React.PureComponent<OwnValidationProps> {
    // displayName for React Developer Tools display
    static displayName = `Validation(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    constructor(props: any) {
      super(props);

      this.newProps.validation = this.newProps.validation.bind(this);
    }

    lastObjToValidate: any = null;
    lastQuoteInfo: ValidationQuoteInfo | null = null;
    lastLevel: validationLevel | null = null;
    lastValidationErrors: ErrorInfo[] = [];

    newProps: ValidationProps = {
      validation: (objToValidate: any, level: validationLevel) => {
        const { errors, updateErrorsVisible, updateErrors, lob, homeowners, addressState } = this.props;

        let isPageValid = (showErrors: ShowErrors = ShowErrors.AlwaysShow, conditionIndex: number | undefined = undefined): boolean => {
          const quoteInfo: ValidationQuoteInfo = {
            lob,
            homeowners,
            addressState,
            conditionIndex
          };
          let currentValidationErrors: ErrorInfo[];

          // only run validation if something is different from the last run
          if (!isEqual(objToValidate, this.lastObjToValidate) ||
            !isEqual(quoteInfo, this.lastQuoteInfo) ||
            !isEqual(level, this.lastLevel)) {

            currentValidationErrors = validate(quoteInfo, objToValidate, level);

            // only update last properties if caller intends to show the errors 
            // (allows isPageValid to be called with a different object to validate than the one used when showing errors)
            if (showErrors === ShowErrors.AlwaysShow || showErrors === ShowErrors.ShowIfVisible) {
              this.lastQuoteInfo = quoteInfo;
              this.lastLevel = level;
              this.lastObjToValidate = objToValidate;
              this.lastValidationErrors = currentValidationErrors;
            }
          }
          else {
            currentValidationErrors = this.lastValidationErrors;
          }

          if (showErrors === ShowErrors.AlwaysShow || (showErrors === ShowErrors.ShowIfVisible && this.props.errorsVisible)) {
            // passing showErrors as setFocus argument looks weird but the intent here is that
            // if the caller wants to show errors, then we will set focus to first invalid
            setErrorVisibility(this.lastValidationErrors.length > 0, showErrors === ShowErrors.AlwaysShow);
          }

          return currentValidationErrors.length === 0;
        };

        let setErrorVisibility = (errorsVisible: boolean, setFocus: boolean = false) => {
          updateErrorsVisible(errorsVisible);

          if (errorsVisible) {
            updateErrors(this.lastValidationErrors);
          }
          else {
            updateErrors([]);
          }

          if (errorsVisible && setFocus) {
            for (var i = 0; i < this.lastValidationErrors.length; i++) {
              // should be the first one in list, as long as objectToValidate is setup in correct order.
              const error: ErrorInfo = this.lastValidationErrors[i];
              const elements = (document.getElementsByName(error.field)! as NodeListOf<HTMLElement>);

              if (elements.length > 0) {
                elements[0].focus();
                break;
              }
            }
          }
        }

        // should be used to easily spread properties into an input
        let getFieldProps = (fieldConfig: any, className: string = '', index: number = 0): any => {
          const isInvalid = isFieldInvalid(fieldConfig, index);

          const id = (index === 0) ? fieldConfig.fieldName : `${fieldConfig.fieldName}_${index}`;
          return {
            name: fieldConfig.fieldName,
            id,
            'data-testid': id,
            className: classNames(className, { 'is-invalid': isInvalid })
          };
        };

        let isFieldInvalid = (fieldConfig: any, index: number = 0): boolean => {
          return getErrors(fieldConfig.fieldName, index, errors, level).length > 0;
        };

        let getErrorText = (fieldConfig: any, index: number = 0): any => {
          const temp = getErrors(fieldConfig.fieldName, index, errors, level);

          if (temp.length > 0) {
            return temp.map((error: string, i: number) => {
              return <div key={i}>{error}</div>;
            });
          }
          else {
            return null;
          }
        };

        isPageValid = isPageValid.bind(this);
        setErrorVisibility = setErrorVisibility.bind(this);
        isFieldInvalid = isFieldInvalid.bind(this);
        getFieldProps = getFieldProps.bind(this);
        getErrorText = getErrorText.bind(this);

        return {
          isPageValid,
          setErrorVisibility,
          isFieldInvalid,
          getFieldProps,
          getErrorText
        };
      }
    };

    render() {
      return <WrappedComponent {...this.props} {...this.newProps} />;
    }
  };

  return Validation;
}

const mapStateToProps = (state: ApplicationState) => ({
  errorsVisible: state.validation.errorsVisible,
  errors: state.validation.errors,
  lob: state.quote!.lineOfBusiness,
  homeowners: state.quote!.homeowners,
  addressState: state.quote!.addressState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ValidationAction>) => ({
  updateErrorsVisible: (errorsVisible: boolean) => dispatch(ValidationActions.updateErrorsVisible(errorsVisible)),
  updateErrors: (errors: ErrorInfo[]) => dispatch(ValidationActions.updateErrors(errors))
})

export default compose<any>(connect(mapStateToProps, mapDispatchToProps), (validation as any));
