import { StepType, stepPage } from "./state";
import { AppThunkAction } from "..";
import { hasLob } from "../../services/helper";
import { updateCrqPage } from "../../services/historyWrapper";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum Actions {
  CHANGE_STEP = 'CHANGE_STEP',
  SET_SELECTED = 'SET_SELECTED',
  SET_ISVALID_FUNC = 'SET_ISVALID_FUNC',
  CLEAR_IS_BACK_PAGE = 'CLEAR_IS_BACK_PAGE'
}

export interface SetCompleteAction {
  type: Actions.CHANGE_STEP;
  active: StepType;
  complete: StepType;
  selected: StepType;
  isValid: () => boolean;
  isBackPage: boolean;
}

export interface SetSelectedAction {
  type: Actions.SET_SELECTED;
  selected: StepType;
  isValid: () => boolean;
}

export interface SetIsValidFuncAction {
  type: Actions.SET_ISVALID_FUNC;
  isValid: () => boolean;
}

export interface ClearIsBackPageAction {
  type: Actions.CLEAR_IS_BACK_PAGE;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

export type Action = SetCompleteAction | SetSelectedAction | SetIsValidFuncAction | ClearIsBackPageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const setSelected = (selected: StepType): AppThunkAction<Action> => (dispatch, getState) => {
  const appState = getState();

  const isBackPage = selected < appState.stepper.selected;

  // only validate if going forward
  if (isBackPage || appState.stepper.isValid()) {
    dispatch({
      type: Actions.SET_SELECTED,
      selected,
      // reset so next page will not attempt to process wrong function
      isValid: () => true
    });

    updateCrqPage(stepPage[selected]);
  }
};

const changeStep = (currentStep: StepType, targetStep: StepType): AppThunkAction<Action> => (dispatch, getState) => {
  const appState = getState();

  // if target is behind current, set flag to help out previous button
  const isBackPage = targetStep < currentStep;

  let isValid = true;

  // only validate if going forward and there is an isValid function to run
  if (!isBackPage && appState.stepper.isValid) {
    isValid = appState.stepper.isValid();
  }

  if (isValid) {
    const { hasAuto, hasHome } = hasLob(appState.quote);
    let { active, complete } = appState.stepper;

    // only update complete if going forward and not already set
    if (!isBackPage && currentStep > complete) {
      complete = currentStep;
    }

    if (complete < StepType.Rates) {
      if ((!hasHome && complete === StepType.Vehicles) ||
        (!hasAuto && complete === StepType.ApplicantDrivers)) {
        active = complete + 2;
      }
      else {
        active = complete + 1;
      }
    }

    dispatch({
      type: Actions.CHANGE_STEP,
      active,
      complete: complete,
      selected: targetStep,
      isBackPage,
      // reset so next page will not attempt to process wrong function
      isValid: () => true
    });

    updateCrqPage(stepPage[targetStep]);
  }
};

const setIsValidFunc = (isValid: () => boolean): SetIsValidFuncAction => {
  return {
    type: Actions.SET_ISVALID_FUNC,
    isValid
  }
};

const clearIsBackPage = (): ClearIsBackPageAction => ({
  type: Actions.CLEAR_IS_BACK_PAGE
});

export const actionCreators = {
  setSelected,
  changeStep,
  setIsValidFunc,
  clearIsBackPage
};