import React, { Fragment } from 'react';
import { Row, Col, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { ApplicationState } from '../../store';
import { HomeCharacteristics } from '../../store/Quote/state';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import Swipeable from '../Swipeable';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import { isNil } from 'lodash';

import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';

type OwnProps = {
  onPreviousClick?: () => void,
  onNextClick?: () => void
};

type QuestionGroupProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface QuestionGroupState {
  roofUpdateYear: number | null,
  plumbingUpdateYear: number | null,
  electricalUpdateYear: number | null,
  heatingUpdateYear: number | null
}

class QuestionGroup2 extends React.PureComponent<QuestionGroupProps, QuestionGroupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      roofUpdateYear: this.props.homeCharacteristics!.roofUpdateYear,
      plumbingUpdateYear: this.props.homeCharacteristics!.plumbingUpdateYear,
      electricalUpdateYear: this.props.homeCharacteristics!.electricalUpdateYear,
      heatingUpdateYear: this.props.homeCharacteristics!.heatingUpdateYear
    };

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.isValid = this.isValid.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
    this.props.setIsValidFunc(this.isValid);
  }

  componentWillUnmount() {
    this.updateHomeCharacteristics();
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  getValidationObject = (): any => {
    return {
      // these will end up getting validated (even though there are no question for them here) but
      // the values are needed for validation to work
      yearBuilt: this.props.homeCharacteristics.yearBuilt,
      renovated: this.props.homeCharacteristics.renovated,

      roofUpdateYear: this.state.roofUpdateYear,
      plumbingUpdateYear: this.state.plumbingUpdateYear,
      electricalUpdateYear: this.state.electricalUpdateYear,
      heatingUpdateYear: this.state.heatingUpdateYear
    };
  };

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  updateHomeCharacteristics() {
    this.props.updateHomeCharacteristics({
      ...this.props.homeCharacteristics,
      roofUpdateYear: this.state.roofUpdateYear,
      plumbingUpdateYear: this.state.plumbingUpdateYear,
      electricalUpdateYear: this.state.electricalUpdateYear,
      heatingUpdateYear: this.state.heatingUpdateYear
    });
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObject(), validationLevel.HomeCharacteristics).isPageValid(showErrors);
  }

  handleChange(event: { target: { name: string; value: string; maxLength: string | number; }; }) {
    let { name, value, maxLength } = event.target;
    let saveValue: string | number | boolean | null;

    if (!isNil(maxLength) && maxLength > -1) {
      const max = Number(maxLength);
      if (value.length > max) value = value.substring(0, max);
    }

    // I wanted to be able to determine the type of the state object property at runtime
    // but I could not find a way to do that when some property values can initially be null.
    switch (name) {
      case 'roofUpdateYear':
      case 'plumbingUpdateYear':
      case 'electricalUpdateYear':
      case 'heatingUpdateYear':
        if (value === '') {
          saveValue = null;
        }
        else {
          saveValue = Number(value);
        }
        break;
      default:
        saveValue = value;
    }

    this.setState({
      ...this.state,
      [name]: saveValue
    });
  }

  render() {
    const { getErrorText, getFieldProps } = this.props.validation(this.getValidationObject(), validationLevel.HomeCharacteristics);

    return (
      <Fragment>
        <h3 className='text-center mb-3'>
          Tell us about the renovations
        </h3>
        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              <h6>Answer all that apply</h6>
              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.roofUpdateYear} />
                <Input type='number'
                  pattern='\d*'
                  maxLength={4}
                  onChange={this.handleChange}
                  value={!isNil(this.state.roofUpdateYear) ? this.state.roofUpdateYear : ''}
                  {...getFieldProps(validationConfig.homeCharacteristics.roofUpdateYear, 'col-6 col-md-3')}
                  autoFocus>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.roofUpdateYear)}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.plumbingUpdateYear} />
                <Input type='number'
                  pattern='\d*'
                  maxLength={4}
                  onChange={this.handleChange}
                  value={!isNil(this.state.plumbingUpdateYear) ? this.state.plumbingUpdateYear : ''}
                  {...getFieldProps(validationConfig.homeCharacteristics.plumbingUpdateYear, 'col-6 col-md-3')}>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.plumbingUpdateYear)}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.electricalUpdateYear} />
                <Input type='number'
                  pattern='\d*'
                  maxLength={4}
                  onChange={this.handleChange}
                  value={!isNil(this.state.electricalUpdateYear) ? this.state.electricalUpdateYear : ''}
                  {...getFieldProps(validationConfig.homeCharacteristics.electricalUpdateYear, 'col-6 col-md-3')}>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.electricalUpdateYear)}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.heatingUpdateYear} />
                <Input type='number'
                  pattern='\d*'
                  maxLength={4}
                  onChange={this.handleChange}
                  value={!isNil(this.state.heatingUpdateYear) ? this.state.heatingUpdateYear : ''}
                  {...getFieldProps(validationConfig.homeCharacteristics.heatingUpdateYear, 'col-6 col-md-3')}>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.heatingUpdateYear)}</FormFeedback>
              </FormGroup>
            </Form>
          </Swipeable>
        </div>
        <hr />

        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Fragment>
    );
  }

}

const mapStateToProps = (state: ApplicationState) => ({
  homeCharacteristics: (state.quote.homeCharacteristics as HomeCharacteristics)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateHomeCharacteristics: (homeCharacteristics: HomeCharacteristics) => dispatch(QuoteActions.updateHomeCharacteristics(homeCharacteristics)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
});

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup2));
