import React from 'react';
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '../custom.scss';

interface PreviousNextProps {
  isPreviousEnabled?: boolean,
  isNextEnabled?: boolean,
  onPreviousClick?: () => void,
  onNextClick?: () => void
}

class PreviousNextButtons extends React.PureComponent<PreviousNextProps> {
  render() {
    let {
      isPreviousEnabled = true,
      isNextEnabled = true,
      onPreviousClick = () => null,
      onNextClick = () => null } = this.props;

    return (
      <div className='mt-3 text-right'>
        {/* only show previous button if there is an event handler for it */}
        {this.props.onPreviousClick &&
          <Button color='previous' className={'mr-1 mr-md-3'} disabled={!isPreviousEnabled}
            onClick={() => onPreviousClick()} tabIndex={-1} data-testid='previousButton'>Previous</Button>
        }

        {/* only show next button if there is an event handler for it */}
        {this.props.onNextClick &&
          <Button id='continueButton' color='primary' className='ml-1 ml-md-3' disabled={!isNextEnabled}
            onClick={() => onNextClick()} data-testid='continueButton'>Continue</Button>
        }
      </div>
    )
  }
}

export default PreviousNextButtons;
