import { StartupParameters, initialState } from './state';
import { Actions as StartupParameterActions, Action as StartupParameterAction } from './actions';
import { Action, Reducer } from 'redux';

export const reducer: Reducer<StartupParameters> = (state: StartupParameters = initialState, incomingAction: Action): StartupParameters => {
  const action = incomingAction as StartupParameterAction;

  switch (action.type) {
    case StartupParameterActions.SET_STARTUP_PARAMETERS:
      return {
        ...action.startupParameters
      };
    default:
      return state;
  }
};
