import { QuoteState, initialState, Driver, LineOfBusinessType, homeownersType, HomeCharacteristics, RateStatus } from './state';
import { Vehicle } from './Vehicle/vehicle';
import { Actions as QuoteActions, Action as QuoteAction } from './actions';
import { Action, Reducer } from 'redux';
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash';


export const reducer: Reducer<QuoteState> = (state: QuoteState = initialState, incomingAction: Action): QuoteState => {
  const action = incomingAction as QuoteAction;
  switch (action.type) {
    case QuoteActions.START_QUOTE:
      let lineOfBusiness: LineOfBusinessType;
      let homeCharacteristics: HomeCharacteristics | null = null;

      switch (true) {
        case action.isPersonalAuto && action.homeowners === homeownersType.None:
          lineOfBusiness = LineOfBusinessType.PersonalAuto;
          break;
        case !action.isPersonalAuto && action.homeowners !== homeownersType.None:
          lineOfBusiness = LineOfBusinessType.Homeowners;
          homeCharacteristics = new HomeCharacteristics();
          break;
        case action.isPersonalAuto && action.homeowners !== homeownersType.None:
          lineOfBusiness = LineOfBusinessType.Package;
          homeCharacteristics = new HomeCharacteristics();
          break;
        default:
          lineOfBusiness = LineOfBusinessType.NotSet;
          break;
      }

      return {
        ...(new QuoteState()),
        isPersonalAuto: action.isPersonalAuto,
        homeowners: action.homeowners,
        lineOfBusiness: lineOfBusiness,
        fullAddress: action.fullAddress,
        addressState: action.addressState,
        addressLine1: action.addressLine1,
        addressLine2: action.addressLine2,
        city: action.city,
        zip: action.zip,
        addressVerified: action.addressVerified,
        homeCharacteristics,

        ref1: action.ref1,
        ref2: action.ref2,
        ref3: action.ref3,
        ref4: action.ref4,
        ref5: action.ref5,

        agencyLicense: action.agencyLicense
      }

    case QuoteActions.ADD_DRIVER:
      let newDriver = new Driver();
      newDriver.id = uuid();
      return {
        ...state,
        rateStatus: RateStatus.NeedsRated,
        drivers: [...state.drivers, newDriver]
      }

    case QuoteActions.UPDATE_DRIVER: {
      let drivers = [...state.drivers];

      // find the index of the driver to update
      let index = drivers.findIndex(d => d.id === action.driver.id);

      // update if found, else return existing state
      if (index !== -1) {
        const rateStatus = isEqual(state.drivers[index], action.driver) ? state.rateStatus : RateStatus.NeedsRated;

        let updatedDriver = {
          ...state.drivers[index],
          ...action.driver
        }
        drivers.splice(index, 1, updatedDriver)

        return {
          ...state,
          rateStatus,
          drivers
        }
      }
      else {
        return state;
      }
    }

    case QuoteActions.REMOVE_DRIVER: {
      let drivers = [...state.drivers];
      let vehicles = [...state.vehicles];

      // reset assigned driver for vehicles driven by the driver we are removing
      const driverId = drivers[action.index].id;
      for (var i = 0; i < vehicles.length; i++) {
        if (vehicles[i].assignedDriverId === driverId) {
          vehicles[i] = {
            ...vehicles[i],
            assignedDriverId: ''
          }
        }
      }

      drivers.splice(action.index, 1);

      return {
        ...state,
        rateStatus: RateStatus.NeedsRated,
        drivers,
        vehicles
      }
    }

    case QuoteActions.ADD_VEHICLE:
      let newVehicle = new Vehicle();
      newVehicle.id = uuid();

      return {
        ...state,
        rateStatus: RateStatus.NeedsRated,
        vehicles: [...state.vehicles, newVehicle]
      }

    case QuoteActions.UPDATE_VEHICLE: {
      let vehicles = [...state.vehicles];

      // find the index of the vehicle to update
      let index = vehicles.findIndex(v => v.id === action.vehicle.id);

      // update if found, else return existing state
      if (index !== -1) {
        const rateStatus = isEqual(state.vehicles[index], action.vehicle) ? state.rateStatus : RateStatus.NeedsRated;

        let updatedVehicle = {
          ...state.vehicles[index],
          ...action.vehicle
        }
        vehicles.splice(index, 1, updatedVehicle)

        return {
          ...state,
          rateStatus,
          vehicles
        }
      }
      else {
        return state;
      }
    }

    case QuoteActions.REMOVE_VEHICLE: {
      let vehicles = [...state.vehicles];
      vehicles.splice(action.index, 1);

      return {
        ...state,
        rateStatus: RateStatus.NeedsRated,
        vehicles: vehicles
      }
    }

    case QuoteActions.UPDATE_HOME_CHARACTERISTICS: {
      const rateStatus = isEqual(state.homeCharacteristics, action.homeCharacteristics) ? state.rateStatus : RateStatus.NeedsRated;

      return {
        ...state,
        rateStatus,
        homeCharacteristics: {
          ...action.homeCharacteristics
        }
      }
    }

    case QuoteActions.UPDATE_COVERAGES: {
      const rateStatus = isEqual(state.coverages, action.coverages) ? state.rateStatus : RateStatus.NeedsRated;

      return {
        ...state,
        rateStatus,
        coverages: action.coverages
      }
    }

    case QuoteActions.UPDATE_RATING: {
      return {
        ...state,
        correlationId: action.correlationId,
        rateStatus: action.rateStatus
      }
    }

    default:
      return state;
  }
};