import React from 'react';
import { FaSpinner } from 'react-icons/fa';

import './Loader.scss';

class Loader extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <FaSpinner className="spin" />
        <div className="ml-2">
          Loading...
        </div>  
      </div>
    );
  }
}

export default Loader;