import { Validation, initialState } from './state';
import { Actions as ValidationActions, Action as ValidationAction } from './actions';
import { Action, Reducer } from 'redux';
import { isEqual } from 'lodash';

export const reducer: Reducer<Validation> = (state: Validation = initialState, incomingAction: Action): Validation => {
  const action = incomingAction as ValidationAction;
  switch (action.type) {
    case ValidationActions.UPDATE_ERRORS_VISIBILE: {
      return {
        ...state,
        errorsVisible: action.errorsVisible,
        errors: action.errorsVisible ? state.errors : []
      };
    }

    case ValidationActions.UPDATE_ERRORS: {
      // only update errors if they are actually different
      if (!isEqual(state.errors, action.errors)) {
        return {
          ...state,
          errors: action.errors
        };
      }
      else {
        return state;
      }
    }

    default:
      return state;
  }
};