import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { FaCheck as CheckIcon } from 'react-icons/fa';
import classNames from 'classnames';
import './Stepper.scss';
import { ThunkDispatch } from 'redux-thunk';
import { Action as StepperAction, actionCreators as StepperActions } from '../store/Stepper/actions';
import { StepType } from '../store/Stepper/state';
import { hasLob } from '../services/helper';
import { homeownersType } from '../store/Quote/state';

type StepperProps =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface Step {
  title: string,
  type: StepType
  id: number,
  isActive: boolean,
  isComplete: boolean,
  isSelected: boolean,
  excludeLine: boolean
}

class Stepper extends React.PureComponent<StepperProps> {
  setupSteps = (showAuto: boolean, showHome: boolean) => {
    const { stepper } = this.props;
    let list: Step[] = [];
    let id: number = 1;

    list.push({
      title: showAuto ? 'Drivers' : 'Applicant',
      type: StepType.ApplicantDrivers,
      id: id++,
      isActive: stepper.active >= StepType.ApplicantDrivers,
      isComplete: stepper.complete >= StepType.ApplicantDrivers,
      isSelected: stepper.selected === StepType.ApplicantDrivers,
      excludeLine: false
    });

    if (showAuto) {
      list.push({
        title: 'Vehicles',
        type: StepType.Vehicles,
        id: id++,
        isActive: stepper.active >= StepType.Vehicles,
        isComplete: stepper.complete >= StepType.Vehicles,
        isSelected: stepper.selected === StepType.Vehicles,
        excludeLine: false
      });
    }

    if (showHome) {
      list.push({
        title: `${this.homeNameLookup(this.props.quote.homeowners)} Characteristics`,
        type: StepType.HomeCharacteristics,
        id: id++,
        isActive: stepper.active >= StepType.HomeCharacteristics,
        isComplete: stepper.complete >= StepType.HomeCharacteristics,
        isSelected: stepper.selected === StepType.HomeCharacteristics,
        excludeLine: false
      });
    }

    list.push({
      title: 'Coverages',
      type: StepType.Coverages,
      id: id++,
      isActive: stepper.active >= StepType.Coverages,
      isComplete: stepper.complete >= StepType.Coverages,
      isSelected: stepper.selected === StepType.Coverages,
      excludeLine: false
    });

    list.push({
      title: 'Rates',
      type: StepType.Rates,
      id: id++,
      isActive: stepper.active >= StepType.Rates,
      isComplete: stepper.complete >= StepType.Rates,
      isSelected: stepper.selected === StepType.Rates,
      excludeLine: true
    });

    return list;
  };

  homeNameLookup = (key: homeownersType): string => {
    const homeLookup: { [key: string]: string } = {
      [homeownersType.Homeowner]: 'Home',
      [homeownersType.Renters]: 'Rental',
      [homeownersType.CondoOwner]: 'Condo',
      [homeownersType.None]: ''
    }
    return homeLookup[key];
  };

  stepMaker = (step: Step) => {
    return (
      <React.Fragment key={step.id}>
        <div data-testid={'stepper-button-' + step.type} className={classNames('stepper__step', { 'stepper__step--active': step.isActive, 'stepper__step--selected': step.isSelected })}
          onClick={step.isActive ? () => this.props.setSelected(step.type) : undefined}>
          <div data-testid={'stepper-circle-' + step.type} className='stepper__icon'>
            {step.isComplete ? <CheckIcon data-testid={'stepper-checked-' + step.type} className='mb-1' /> : step.id}
          </div>
          <span>{step.title}</span>
        </div>
        {!step.excludeLine && <div data-testid={'stepper-line-' + step.type} className={classNames('stepper__line', { 'stepper__line--complete': step.isComplete })}></div>}
      </React.Fragment>
    );
  };

  render() {
    const { quote } = this.props;
    const { hasAuto, hasHome } = hasLob(quote);
    const steps = this.setupSteps(hasAuto, hasHome);

    return (
      <section className={classNames('stepper__container', { 'stepper__container--tall': hasHome, 'stepper__container--short': !hasHome })}>
        {steps.map((step: Step) =>
          this.stepMaker(step)
        )}
      </section>
    );
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote,
  stepper: state.stepper
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, StepperAction>) => ({
  setSelected: (selected: StepType) => dispatch(StepperActions.setSelected(selected))
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(Stepper);