import React from 'react';
import { connect } from 'react-redux';
import axios, { AxiosError } from 'axios';
import { Button, ButtonGroup } from 'reactstrap';
import errorResponseHandler from '../services/errorHandling'
import { getBaseUrl } from '../services/helper';

class TestPage extends React.PureComponent<{}, { causeError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { causeError: false };

    this.causeJsComponent = this.causeJsComponent.bind(this);
  }

  private cause500() {
    axios.get(getBaseUrl() + '/test/' + 0)
      .then(() => {
        alert('not bad 500');
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }

  private cause400() {
    axios.post(getBaseUrl() + '/test', 'bad', { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
      .then(() => {
        alert('not bad 500');
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }

  private causeJsComponent() {
    this.setState({ causeError: true });
  }

  private causeJsEvent() {
    throw Error('event error');
  }

  public render() {
    if (this.state.causeError) {
      throw Error('component error');
    }

    return (
      <div>
        <h1 className='mb-2'>Test Page to help test routing</h1>
        <h5 className='mb-2'>Test routing for different server side error types</h5>
        <ButtonGroup className='mb-2'>
          <Button onClick={this.cause500}>500 error</Button>
          <Button onClick={this.cause400}>400 error</Button>
          <Button onClick={this.causeJsComponent}>component error</Button>
          <Button onClick={this.causeJsEvent}>event error</Button>
        </ButtonGroup>
      </div>
    );
  }
}

export default connect()(TestPage);