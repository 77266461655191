// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ErrorInfo {
  level: string;
  index: number;
  field: string;
  errors: string[];
  hierarchy: ErrorHierachy;
}

export class Validation {
  errorsVisible: boolean = false;
  errors: ErrorInfo[] = [];
}

export class ErrorHierachy {
  constructor (level: string, index: number | undefined) {
    this.level = level;
    this.index = index;
  }

  level: string;
  index: number | undefined
  child: ErrorHierachy | undefined
}

export const initialState: Validation = new Validation();
