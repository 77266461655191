import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from './';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore } from 'redux-persist';
import submitService from '../services/submit';
import { composeWithDevTools } from 'redux-devtools-extension';

let store: any;

export function subscribeToQuoteChanges () {
  store.subscribe(() => {
    const state: ApplicationState = store.getState();
    submitService(state.site.id, state.quote, state.stepper);
  });
}

export default function configureStore(history: History, initialState?: ApplicationState, agencyId?: number): any {
  const middleware = [
    thunk,
    createRouterMiddleware(history)
  ];

  const rootReducer = combineReducers({
    ...reducers,
    router: createRouterReducer(history)
  });

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : window as any;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  function noop() {
    return Promise.resolve(null);
  }

  var noopStorage = {
    getItem: noop,
    setItem: noop,
    removeItem: noop
  };

  const persistConfig = {
    key: 'ConsumerV2_ApplicationState',
    storage: agencyId === 34302 ? storage : noopStorage,
    whitelist: ['quote', 'stepper'] // only these parts of ApplicationState will be persisted
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  store = createStore(persistedReducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)));

  let persistor = persistStore(store);

  return {
    store,
    persistor
  };
}
