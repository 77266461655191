// These must be the first lines per documentation
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import configureStore from './store/configureStore';

import App from './App';
import history from './services/historyWrapper';
import { PersistGate } from 'redux-persist/integration/react';

import { unregister } from './registerServiceWorker';
// not using and might be causing cache issues
unregister();

let agencyId: number = 0;
let ref1: string = '';
let ref2: string = '';
let ref3: string = '';
let ref4: string = '';
let ref5: string = '';

let rootElement = document.getElementById('root');
if (rootElement && rootElement.dataset) {
  if (rootElement.dataset.agencyid) {
    agencyId = parseInt(rootElement.dataset.agencyid as string, 10);
  }
  ref1 = rootElement.dataset.ref1 || '';
  ref2 = rootElement.dataset.ref2 || '';
  ref3 = rootElement.dataset.ref3 || '';
  ref4 = rootElement.dataset.ref4 || '';
  ref5 = rootElement.dataset.ref5 || '';
}

// Get the application-wide store instance, prepopulating with saved state where available.
const { store, persistor } = configureStore(history, undefined, agencyId);

const root = createRoot(rootElement!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReduxRouter history={history}>
        <App agencyId={agencyId} ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} />
      </ReduxRouter>
    </PersistGate>
  </Provider>
);