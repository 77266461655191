import React, { Fragment } from 'react';
import { Row, Col, ButtonGroup, Form, FormGroup, Input, FormFeedback, InputGroup } from 'reactstrap';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { ApplicationState } from '../../store';
import { HomeCharacteristics, homeownersType } from '../../store/Quote/state';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import Swipeable from '../Swipeable';
import { QuestionOption } from '../../store/QuestionOptions/state';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel, distanceToCoastStates } from '../../services/validationConfig';
import classNames from 'classnames';
import { isNil, includes } from 'lodash';

import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import HelpText from '../HelpText';

type OwnProps = {
  onRenovatedChange?: (renovated: boolean | null, hasHomeLosses: boolean | null) => void,
  onHomeLossesChange?: (renovated: boolean | null, hasHomeLosses: boolean | null) => void,
  onPreviousClick?: () => void,
  onNextClick?: () => void;
};

type QuestionGroupProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface QuestionGroupState {
  residenceType: string,
  yearsAtResidence: number | null,
  yearBuilt: number,
  squareFootage: number,
  construction: string,
  milesToCoast: number | null,
  feetToCoast: number | null,
  numberOfUnits: number,
  renovated: boolean | null,
  hasHomeLosses: boolean | null,
  roofUpdateYear: number | null,
  plumbingUpdateYear: number | null,
  electricalUpdateYear: number | null,
  heatingUpdateYear: number | null,
  isIE: boolean | null
}

class QuestionGroup1 extends React.PureComponent<QuestionGroupProps, QuestionGroupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      residenceType: this.props.quote.homeowners !== homeownersType.CondoOwner ? this.props.homeCharacteristics!.residenceType : 'Condo',
      yearsAtResidence: this.props.homeCharacteristics!.yearsAtResidence,
      yearBuilt: this.props.homeCharacteristics!.yearBuilt,
      squareFootage: this.props.homeCharacteristics!.squareFootage,
      construction: this.props.homeCharacteristics!.construction,
      milesToCoast: this.props.homeCharacteristics!.milesToCoast,
      feetToCoast: this.props.homeCharacteristics!.feetToCoast,
      numberOfUnits: this.props.homeCharacteristics!.numberOfUnits,
      renovated: this.props.site!.hideHomeRenovation! === true ? false : (this.props.quote.homeowners !== homeownersType.Renters ? this.props.homeCharacteristics!.renovated : false),
      hasHomeLosses: this.props.homeCharacteristics.hasHomeLosses,
      roofUpdateYear: this.props.homeCharacteristics!.roofUpdateYear,
      plumbingUpdateYear: this.props.homeCharacteristics!.plumbingUpdateYear,
      electricalUpdateYear: this.props.homeCharacteristics!.electricalUpdateYear,
      heatingUpdateYear: this.props.homeCharacteristics!.heatingUpdateYear,
      isIE: null
    };

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.renovationOnChange = this.renovationOnChange.bind(this);
    this.lossesOnChange = this.lossesOnChange.bind(this);

    this.isValid = this.isValid.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
    this.props.setIsValidFunc(this.isValid);
  }

  showDistanceToCoast: boolean = includes(distanceToCoastStates, this.props.quote.addressState);

  componentWillUnmount() {
    this.updateHomeCharacteristics();
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  componentDidMount() {
    if (this.state.isIE === null) {
      this.setState({ isIE: window.navigator.userAgent.indexOf("MSIE ") > 0 || navigator.appVersion.indexOf('Trident/') > -1})
    }
  }

  getValidationObject = (): any => {
    return {
      residenceType: this.state.residenceType,
      yearsAtResidence: this.state.yearsAtResidence,
      yearBuilt: this.state.yearBuilt,
      squareFootage: this.state.squareFootage,
      construction: this.state.construction,
      distanceToCoast: this.showDistanceToCoast ? Number(this.state.milesToCoast) + Number(this.state.feetToCoast) : null,
      milesToCoast: this.showDistanceToCoast ? Number(this.state.milesToCoast) : null,
      feetToCoast: this.showDistanceToCoast ? Number(this.state.feetToCoast) : null,
      numberOfUnits: this.state.numberOfUnits,
      renovated: this.state.renovated,
      hasHomeLosses: this.state.hasHomeLosses
    };
  };

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  updateHomeCharacteristics() {
    this.props.updateHomeCharacteristics({
      ...this.props.homeCharacteristics,
      residenceType: this.state.residenceType,
      yearsAtResidence: this.state.yearsAtResidence,
      yearBuilt: this.state.yearBuilt,
      squareFootage: this.state.squareFootage,
      construction: this.state.construction,
      milesToCoast: this.showDistanceToCoast ? Number(this.state.milesToCoast) : null,
      feetToCoast: this.showDistanceToCoast ? Number(this.state.feetToCoast) : null,
      numberOfUnits: this.state.numberOfUnits,
      renovated: this.state.renovated,
      hasHomeLosses: this.state.hasHomeLosses,
      roofUpdateYear: this.state.roofUpdateYear,
      plumbingUpdateYear: this.state.plumbingUpdateYear,
      electricalUpdateYear: this.state.electricalUpdateYear,
      heatingUpdateYear: this.state.heatingUpdateYear
    });
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObject(), validationLevel.HomeCharacteristics).isPageValid(showErrors);
  }

  handleChange(event: { target: { name: string; value: string; maxLength: string | number; }; }) {
    let { name, value, maxLength } = event.target;
    let saveValue: string | number | boolean | null;

    if (!isNil(maxLength) && maxLength > -1) {
      const max = Number(maxLength);
      if (value.length > max) value = value.substring(0, max);
    }

    // I wanted to be able to determine the type of the state object property at runtime
    // but I could not find a way to do that when some property values can initially be null.
    switch (name) {
      case 'yearBuilt':
      case 'squareFootage':
      case 'numberOfUnits':
        saveValue = Number(value.replace(/\D/g, '')); //remove unwanted characters from copy & paste
        break;
      case 'yearsAtResidence':
      case 'milesToCoast':
      case 'feetToCoast':
        if (value === '') {
          saveValue = null;
        }
        else {
          saveValue = Number(value.replace('.', ''));
        }
        break;
      case 'residenceType':
        if (value === 'Mobile Home') {
          this.setState({
            ...this.state,
            [name]: value,
            construction: 'Mobile Home'
          })
          return;
        }
        if (this.state.construction === 'Mobile Home') {
          this.setState({
            ...this.state,
            [name]: value,
            construction: ''
          })
          return;
        }
        saveValue = value;
        break;
      default:
        saveValue = value;
    }

    this.setState({
      ...this.state,
      [name]: saveValue
    });
  }

  renovationOnChange(event: { target: HTMLInputElement; }) {
    let renovated = event.target.value.toLowerCase() === 'true';

    this.setState({
      renovated
    });

    if (!renovated) {
      this.setState({
        roofUpdateYear: null,
        plumbingUpdateYear: null,
        electricalUpdateYear: null,
        heatingUpdateYear: null
      });
    }

    if (this.props.onRenovatedChange) {
      this.props.onRenovatedChange(renovated, this.state.hasHomeLosses);
    }
  }

  lossesOnChange(event: { target: HTMLInputElement; }) {
    let hasHomeLosses = event.target.value.toLowerCase() === 'true';

    this.setState({
      hasHomeLosses: hasHomeLosses
    });

    if (!hasHomeLosses) {
      this.props.updateHomeCharacteristics({
        ...this.props.homeCharacteristics,
        homeLosses: []
      })
    }

    if (this.props.onHomeLossesChange) {
      this.props.onHomeLossesChange(this.state.renovated, hasHomeLosses);
    }
  }

  render() {
    const { getErrorText, isFieldInvalid, getFieldProps } = this.props.validation(this.getValidationObject(), validationLevel.HomeCharacteristics);

    const residenceTypeOptions = (this.props.questionOptions["ResidenceType"] || [])
      .filter((option) => option.text !== 'Condo' || (option.text === 'Condo' && this.props.quote.homeowners === homeownersType.Renters));

    const isNotCondo = this.props.quote.homeowners !== homeownersType.CondoOwner;

    return (
      <Fragment>
        <h3 className='text-center'>
          Tell us about your home
        </h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              {isNotCondo &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.homeCharacteristics.residenceType} />
                  <div>
                    <select value={this.state.residenceType}
                      onChange={this.handleChange}
                      {...getFieldProps(validationConfig.homeCharacteristics.residenceType, 'custom-select')}
                      autoFocus>
                      <option value=''>{'<select>'}</option>
                      {
                      (residenceTypeOptions).map((option: QuestionOption, index: number) => {
                        return option.text !== 'Co-op' && (<option key={index} value={option.value}>{option.text}</option>)
                      })
                      }
                    </select>
                    <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.residenceType)}</FormFeedback>
                  </div>
                </FormGroup>
              }

              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.yearsAtResidence} />
                <Input type={this.state.isIE ? 'text' : 'number'} 
                  pattern='\d*'
                  maxLength={2}
                  onChange={this.handleChange}
                  value={!isNil(this.state.yearsAtResidence) ? this.state.yearsAtResidence : ''}
                  onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} //don't allow decimal
                  {...getFieldProps(validationConfig.homeCharacteristics.yearsAtResidence, 'col-6 col-md-3')}
                  autoFocus={!isNotCondo}>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.yearsAtResidence)}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.yearBuilt} />
                <Input type={this.state.isIE ? 'text' : 'number'} 
                  pattern='\d*'
                  maxLength={4}
                  onChange={this.handleChange}
                  value={this.state.yearBuilt || ''}
                  onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} //don't allow decimal
                  {...getFieldProps(validationConfig.homeCharacteristics.yearBuilt, 'col-6 col-md-3')}>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.yearBuilt)}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.squareFootage} />
                <Input type={this.state.isIE ? 'text' : 'number'} 
                  pattern='\d*'
                  maxLength={6}
                  onChange={this.handleChange}
                  value={this.state.squareFootage || ''}
                  onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} //don't allow decimal
                  {...getFieldProps(validationConfig.homeCharacteristics.squareFootage, 'col-6 col-md-3')}>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.squareFootage)}</FormFeedback>
              </FormGroup>

              { this.state.residenceType !== 'Mobile Home' &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.homeCharacteristics.construction} />
                  <HelpText text={validationConfig.homeCharacteristics.construction.helpText} helpId='construction' />
                  <div>
                    <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.homeCharacteristics.construction) })} >
                      {
                        (this.props.questionOptions["ConstructionType"] || [])
                          .map((option: QuestionOption, index: number) => (
                            <label className={classNames('btn btn-option', { active: option.value === this.state.construction })} data-toggle='buttons' key={index}>
                              <input type="radio" name={validationConfig.homeCharacteristics.construction.fieldName} onChange={this.handleChange} value={option.value} />
                              {option.text}
                            </label>
                          ))
                      }
                    </ButtonGroup>
                    <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.construction)}</FormFeedback>
                  </div>
                </FormGroup>
              }
              {
                (this.props.quote.homeowners === homeownersType.Renters || this.props.quote.homeowners === homeownersType.CondoOwner) &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.homeCharacteristics.numberOfUnits} />
                  <Input type={this.state.isIE ? 'text' : 'number'} 
                    pattern='\d*'
                    maxLength={3}
                    onChange={this.handleChange}
                    value={this.state.numberOfUnits || ''}
                    onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} //don't allow decimal
                    {...getFieldProps(validationConfig.homeCharacteristics.numberOfUnits, 'col-6 col-md-3')}>
                  </Input>
                  <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.numberOfUnits)}</FormFeedback>
                </FormGroup>
              }

              {
                this.showDistanceToCoast &&
                <FormGroup>
                  {/* using milesToCoast for the "for" attribute is intentional, it's the label for the first input*/}
                  <ValidationLabel config={validationConfig.homeCharacteristics.milesToCoast}>{validationConfig.homeCharacteristics.distanceToCoast.display}:</ValidationLabel>
                  <InputGroup>
                    <Input type={this.state.isIE ? 'text' : 'number'} 
                      pattern='\d*'
                      placeholder='Miles'
                      min={0}
                      maxLength={3}
                      onChange={this.handleChange}
                      value={this.state.milesToCoast || ''}
                      onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} //don't allow decimal
                      {...getFieldProps(validationConfig.homeCharacteristics.milesToCoast, 'col-4 col-md-3')}>
                    </Input>
                    <Input type={this.state.isIE ? 'text' : 'number'} 
                      pattern='\d*'
                      placeholder='Feet'
                      min={0}
                      maxLength={4}
                      onChange={this.handleChange}
                      value={this.state.feetToCoast || ''}
                      onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} //don't allow decimal
                      {...getFieldProps(validationConfig.homeCharacteristics.feetToCoast, 'col-4 col-md-3')}>
                    </Input>
                  </InputGroup>
                  {
                    /* Show the combined validation feedback if that isn't valid */
                    /* inputgroup and formfeedback don't work well together in bootstrap so manually control the display. */
                    isFieldInvalid(validationConfig.homeCharacteristics.distanceToCoast) &&
                    <FormFeedback className='d-block'>
                      {getErrorText(validationConfig.homeCharacteristics.distanceToCoast)}
                    </FormFeedback>
                  }
                  {
                    /* Else show the individual field validation feedback that isn't valid */
                    !isFieldInvalid(validationConfig.homeCharacteristics.distanceToCoast) &&
                    <Fragment>
                      <FormFeedback className={classNames({ 'd-block': isFieldInvalid(validationConfig.homeCharacteristics.milesToCoast) })}>
                        {getErrorText(validationConfig.homeCharacteristics.milesToCoast)}
                      </FormFeedback>
                      <FormFeedback className={classNames({ 'd-block': isFieldInvalid(validationConfig.homeCharacteristics.feetToCoast) })}>
                        {getErrorText(validationConfig.homeCharacteristics.feetToCoast)}
                      </FormFeedback>
                    </Fragment>
                  }
                </FormGroup>
              }

              {
                (this.props.quote.homeowners === homeownersType.Homeowner || this.props.quote.homeowners === homeownersType.CondoOwner) &&
                !this.props.site.hideHomeRenovation &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.homeCharacteristics.renovated} />
                  <div>
                    <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.homeCharacteristics.renovated) })} >
                      {
                        ([{ text: 'No', value: 'false' }, { text: 'Yes', value: 'true' }] as QuestionOption[])
                          .map((option: QuestionOption, index: number) => (
                            <label className={classNames('btn btn-option', { active: option.value === this.state.renovated + '' })} data-toggle='buttons' key={index}>
                              <input type="radio" name={validationConfig.homeCharacteristics.renovated.fieldName} onChange={this.renovationOnChange} value={option.value}
                                data-testid={'renovated-' + option.text} />
                              {option.text}
                            </label>
                          ))
                      }
                    </ButtonGroup>
                    <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.renovated)}</FormFeedback>
                  </div>
                </FormGroup>
              }        

              <FormGroup>
                <ValidationLabel config={validationConfig.homeCharacteristics.hasHomeLosses} />
                <div>
                  <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.homeCharacteristics.hasHomeLosses) })} >
                    {
                      ([{ text: 'No', value: 'false' }, { text: 'Yes', value: 'true' }] as QuestionOption[])
                        .map((option: QuestionOption, index: number) => (
                          <label className={classNames('btn btn-option', { active: option.value === this.state.hasHomeLosses + '' })} data-toggle='buttons' key={index}>
                            <input type="radio" name={validationConfig.homeCharacteristics.hasHomeLosses.fieldName} onChange={this.lossesOnChange} value={option.value}
                              data-testid={'hasHomeLosses-' + option.text} />
                            {option.text}
                          </label>
                        ))
                    }
                  </ButtonGroup>
                  <FormFeedback>{getErrorText(validationConfig.homeCharacteristics.hasHomeLosses)}</FormFeedback>
                </div>
              </FormGroup>
            </Form>
          </Swipeable>
        </div>

        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Fragment>
    );
  }

}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  quote: state.quote,
  homeCharacteristics: (state.quote.homeCharacteristics as HomeCharacteristics),
  questionOptions: state.questionOptions.options
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateHomeCharacteristics: (homeCharacteristics: HomeCharacteristics) => dispatch(QuoteActions.updateHomeCharacteristics(homeCharacteristics)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
});

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup1));
