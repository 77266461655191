import React, { Fragment } from 'react';
import { Row, Col, Label, ButtonGroup, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { Driver } from '../../store/Quote/state';
import { Vehicle, AssignedDriverType } from '../../store/Quote/Vehicle/vehicle';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import AddButton from '../AddButton';
import Swipeable from '../Swipeable';
import { QuestionOption } from '../../store/QuestionOptions/state';
import classNames from 'classnames';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import { isNil } from 'lodash';

import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import HelpText from '../HelpText';

type OwnProps = {
  currentIndex: number,
  onPreviousClick?: () => void,
  onNextClick?: () => void,
  onAddClick?: () => void;
};

type QuestionGroupProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface QuestionGroupState {
  assignedDriverId: string,
  use: string,
  milesToWork: number | null,
  annualMiles: number | null,
  includeCompColl: boolean | null;
}

class QuestionGroup2 extends React.PureComponent<QuestionGroupProps, QuestionGroupState> {
  constructor(props: any) {
    super(props);

    let currentVehicle = this.props.vehicles[this.props.currentIndex];

    if (!currentVehicle) {
      throw new Error('vehicle was not found');
    }

    this.state = {
      assignedDriverId: (props.drivers.length > 1) ? currentVehicle.assignedDriverId : props.drivers[0].id,
      use: currentVehicle.use,
      milesToWork: currentVehicle.milesToWork,
      annualMiles: currentVehicle.annualMiles,
      includeCompColl: currentVehicle.includeCompColl
    };

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.addClickHandler = this.addClickHandler.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.useOnChange = this.useOnChange.bind(this);

    this.isValid = this.isValid.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);

    this.props.setIsValidFunc(this.isValid);
  }

  componentWillUnmount() {
    this.updateVehicle();
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  getValidationObject = (): any => {
    let vehicles: any[] = [];

    this.props.vehicles.forEach((vehicle, index) => {
      if (index === this.props.currentIndex) {
        vehicles.push({
          assignedDriverId: this.state.assignedDriverId,
          use: this.state.use,
          milesToWork: this.state.milesToWork,
          annualMiles: this.state.annualMiles,
          includeCompColl: this.state.includeCompColl
        });
      }
      else {
        vehicles.push({
          assignedDriverId: vehicle.assignedDriverId,
          use: vehicle.use,
          milesToWork: vehicle.milesToWork,
          annualMiles: vehicle.annualMiles,
          includeCompColl: vehicle.includeCompColl
        });
      }
    })

    // validation object needs to be structured similar to quote in order to run driver assignment validation
    return {
      addressState: this.props.addressState,
      drivers: this.props.drivers,
      vehicles
    };
  };

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  addClickHandler() {
    if (this.props.onAddClick) {
      this.props.onAddClick();
    }
  }

  updateVehicle() {
    let currentVehicle = this.props.vehicles[this.props.currentIndex];

    // this should not happen but is needed to make TypeScript happy
    if (isNil(currentVehicle)) {
      throw new Error('Vehicle was not found in list.');
    }

    // if the current vehicle was assigned to a different driver and was the principal vehicle for that driver, update the first 
    // of the remaining vehicles for the old driver to be the new principal 
    if (currentVehicle.assignedDriverId !== '' && currentVehicle.assignedDriverId !== this.state.assignedDriverId &&
      currentVehicle.assignedDriverType === AssignedDriverType.Principal) {
      const newPrincipalVehicle = this.props.vehicles.find(vehicle => vehicle.assignedDriverId === currentVehicle!.assignedDriverId &&
        vehicle !== currentVehicle);
      if (!isNil(newPrincipalVehicle)) {
        this.props.updateVehicle({
          ...newPrincipalVehicle,
          assignedDriverType: AssignedDriverType.Principal
        });
      }
    }

    // update all vehicles assigned to this driver to have the right assignment type (first vehicle is principal, all others most frequent)
    // and update the other properties of the current vehicle
    let newDriverVehicles = this.props.vehicles.filter(vehicle => vehicle.assignedDriverId === this.state.assignedDriverId ||
      vehicle === currentVehicle);
    let assignedDriverType = AssignedDriverType.Principal;
    newDriverVehicles.forEach(vehicle => {
      if (vehicle === currentVehicle) {
        this.props.updateVehicle({
          ...currentVehicle,
          assignedDriverId: this.state.assignedDriverId,
          assignedDriverType: assignedDriverType,
          use: this.state.use,
          milesToWork: this.state.use === 'Work' ? this.state.milesToWork : null,
          annualMiles: this.state.annualMiles,
          includeCompColl: this.state.includeCompColl
        });
      }
      else if (vehicle.assignedDriverType !== assignedDriverType) {
        this.props.updateVehicle({
          ...vehicle,
          assignedDriverType: assignedDriverType
        });
      }

      assignedDriverType = AssignedDriverType.MostFrequent;
    });
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObject(), validationLevel.Vehicles).isPageValid(showErrors, this.props.currentIndex);
  }

  handleChange(event: { target: { name: string; value: string; maxLength: string | number; }; }) {
    let { name, value, maxLength } = event.target;
    let saveValue: string | number | boolean;

    if (!isNil(maxLength) && maxLength > -1) {
      const max = Number(maxLength);
      if (value.length > max) value = value.substring(0, max);
    }

    // I wanted to be able to determine the type of the state object property at runtime
    // but I could not find a way to do that when some property values can initially be null.
    switch (name) {
      case 'includeCompColl':
        saveValue = value.toLowerCase() === "true";
        break;
      case 'annualMiles':
      case 'milesToWork':
        value = value.replace(/\D/g, ''); //remove unwanted characters from copy & paste
        saveValue = Number(value);
        break;
      default:
        saveValue = value;
    }

    this.setState({
      ...this.state,
      [name]: saveValue
    });
  }

  handleDriverChange(driverId: string) {
    this.setState({
      ...this.state,
      assignedDriverId: driverId
    });
  }

  useOnChange(event: { target: HTMLInputElement; }) {
    let input = event.target;

    this.setState({
      use: input.value
    });

    if (input.value !== 'Work') {
      this.setState({
        milesToWork: 0
      });
    }
  }

  render() {
    const { getErrorText, isFieldInvalid, getFieldProps } = this.props.validation(this.getValidationObject(), validationLevel.Vehicles);
    const showDriverAssignment = this.props.drivers.length > 1;

    const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
      // don't allow decimal or enter (enter causes form to submit on this control and puts form data in url and cause site not found page)
      (e.key === '.' || e.which === 13) && e.preventDefault()
    };

    return (
      <Fragment>
        <h3 className='text-center mb-3'>
          Tell us a little more
        </h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              {showDriverAssignment &&
                <FormGroup>
                  <Label for={validationConfig.vehicles.assignedDriverId.fieldName}>{validationConfig.vehicles.assignedDriverId.display}</Label>
                  <div>
                    <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.vehicles.assignedDriverId, this.props.currentIndex) })} >
                      {
                        this.props.drivers.map((driver: Driver, index: number) => (
                          <label className={classNames('btn btn-option', { active: driver.id === this.state.assignedDriverId })} data-toggle='buttons' key={index}>
                            <input type="radio" name={validationConfig.vehicles.assignedDriverId.fieldName} onChange={() => this.handleDriverChange(driver.id)} value={driver.id}
                              autoFocus={(this.state.assignedDriverId === '' && index === 0) || (this.state.assignedDriverId !== '' && driver.id === this.state.assignedDriverId)} />
                            {driver.firstName + ' ' + driver.lastName}
                          </label>
                        ))
                      }
                    </ButtonGroup>
                    <FormFeedback>{getErrorText(validationConfig.vehicles.assignedDriverId, this.props.currentIndex)}</FormFeedback>
                  </div>
                </FormGroup>
              }

              <FormGroup>
                <Label for={validationConfig.vehicles.use.fieldName}>{validationConfig.vehicles.use.display}:</Label>
                <HelpText text={validationConfig.vehicles.use.helpText} helpId='use' />
                <div>
                  <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.vehicles.use, this.props.currentIndex) })} >
                    {
                      (this.props.questionOptions["Use"] || [])
                        .map((option: QuestionOption, index: number) => (
                          <label className={classNames('btn btn-option', { active: option.value === this.state.use })} data-toggle='buttons' key={index}>
                            <input type="radio" name={validationConfig.vehicles.use.fieldName} onChange={this.useOnChange} value={option.value}
                              autoFocus={!showDriverAssignment && ((this.state.use === '' && index === 0) || (this.state.use !== '' && option.value === this.state.use))} />
                            {option.text}
                          </label>
                        ))
                    }
                  </ButtonGroup>
                  <FormFeedback>{getErrorText(validationConfig.vehicles.use, this.props.currentIndex)}</FormFeedback>
                </div>
              </FormGroup>

              {
                this.state.use === 'Work' &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.vehicles.milesToWork} index={this.props.currentIndex} />
                  <Input type='number'
                    pattern='\d*'
                    maxLength={2}
                    onChange={this.handleChange}
                    value={this.state.milesToWork || ''}
                    {...{ onKeyPress }}
                    {...getFieldProps(validationConfig.vehicles.milesToWork, 'col-6 col-md-3', this.props.currentIndex)}>
                  </Input>
                  <FormFeedback>{getErrorText(validationConfig.vehicles.milesToWork, this.props.currentIndex)}</FormFeedback>
                </FormGroup>
              }

              <FormGroup>
                <ValidationLabel config={validationConfig.vehicles.annualMiles} index={this.props.currentIndex} />
                <HelpText text={validationConfig.vehicles.annualMiles.helpText} helpId='annualMiles' />
                <Input type='number'
                  pattern='\d*'
                  maxLength={5}
                  onChange={this.handleChange}
                  value={this.state.annualMiles || ''}
                  {...{ onKeyPress }}
                  {...getFieldProps(validationConfig.vehicles.annualMiles, 'col-6 col-md-3', this.props.currentIndex)}>
                </Input>
                <FormFeedback>{getErrorText(validationConfig.vehicles.annualMiles, this.props.currentIndex)}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label className="pr-xl-2" for={validationConfig.vehicles.includeCompColl.fieldName}>{validationConfig.vehicles.includeCompColl.display}
                  <HelpText text={validationConfig.vehicles.includeCompColl.helpText} helpId='includeCompColl' /></Label>
                <div>
                  <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.vehicles.includeCompColl, this.props.currentIndex) })} >
                    <label className={classNames('btn btn-option', { active: this.state.includeCompColl === true })} data-toggle='buttons'>
                      <input type="radio" name={validationConfig.vehicles.includeCompColl.fieldName} onChange={this.handleChange} value="true" />
                        Yes
                  </label>
                    <label className={classNames('btn btn-option', { active: this.state.includeCompColl === false })} data-toggle='buttons'>
                      <input type="radio" name={validationConfig.vehicles.includeCompColl.fieldName} onChange={this.handleChange} value="false" />
                        No
                  </label>
                  </ButtonGroup>
                  <FormFeedback>{getErrorText(validationConfig.vehicles.includeCompColl, this.props.currentIndex)}</FormFeedback>
                </div>
              </FormGroup>
            </Form>
          </Swipeable>
        </div>

        <Col className='text-right pr-0'>
          <AddButton isDisabled={!this.isValid(ShowErrors.NeverShow)} onClick={this.addClickHandler}
            isHidden={this.props.vehicles.length === 4}>
            Vehicle
          </AddButton>
        </Col>
        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Fragment>
    );
  }

}

const mapStateToProps = (state: ApplicationState) => ({
  questionOptions: state.questionOptions.options || {},
  addressState: state.quote.addressState,
  vehicles: state.quote.vehicles,
  drivers: state.quote.drivers
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateVehicle: (vehicle: Vehicle) => dispatch(QuoteActions.updateVehicle(vehicle)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
});

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup2));
