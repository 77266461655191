import { QuoteState } from "../store/Quote/state";
import queryString from 'query-string';
import { Location as HistoryLocation } from 'history'

export const getQueryStringPage = (location: Location | HistoryLocation) => {
  let queryStringPage: string = '';

  const parsedQuery = queryString.parse(location.search);
  if (typeof parsedQuery.crqPage === 'string') {
    queryStringPage = parsedQuery.crqPage;
  }

  return queryStringPage;
};

export const getQueryStringLob = (location: Location | HistoryLocation) => {
  let queryStringLob = '';

  const parsedQuery = queryString.parse(location.search);
  if (typeof parsedQuery.lob === 'string') {
    queryStringLob = parsedQuery.lob;
  }

  return queryStringLob;
};

export const getBaseUrl = () => {
  const storageKey = 'ConsumerV2_Base_Url';
  let baseUrl = sessionStorage.getItem(storageKey) || '';

  if (baseUrl.length === 0) {
    const scripts = document.getElementsByTagName('script');

    // check consumerv2 first in case this is embedded and user also has localhost for their testing
    baseUrl = findBaseUrl(scripts, 'consumerv2');

    // these are for debugging 
    if (baseUrl.length === 0) {
      baseUrl = findBaseUrl(scripts, 'vertafore.com');
    }
    if (baseUrl.length === 0) {
      baseUrl = findBaseUrl(scripts, 'localhost');
    }
    if (baseUrl.length === 0) {
      baseUrl = findBaseUrl(scripts, ':5001');
    }
  }

  if (baseUrl.length === 0) {
    throw Error('Unable to get base URL.');
  }
  else {
    sessionStorage.setItem(storageKey, baseUrl);
  }

  return baseUrl;
};

export const hasLob = (state?: QuoteState) => {
  const hasAuto = state!.isPersonalAuto;
  const hasHome = state!.homeowners.length > 0;

  return {
    hasAuto,
    hasHome
  }
};

export const getCurrentDate = (addYear?: number) => {
  let add: number = 0;
  if (addYear) {
    add = addYear;
  }

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear() + add;
  return (yyyy + '-' + mm + '-' + dd);
}

const findBaseUrl = (scripts: HTMLCollectionOf<HTMLScriptElement>, pathKey: string) => {
  let i = scripts.length;
  let scriptUrl;
  let baseUrl = '';

  // current script should be a the end, but sometimes not.
  // so starting at end and loop backwards
  while (i-- && i >= 0) {
    scriptUrl = scripts[i].src;
    if (scriptUrl) {
      let scriptUrlLower = scriptUrl.toLowerCase();
      if (scriptUrlLower.indexOf(pathKey) > -1) {
        // look for main script that is for pathKey because there might be another main script for another site if this is embedded
        let position = scriptUrlLower.indexOf('/static/js/main');
        if (position === -1) {
          // development build script name is different - sigh
          position = scriptUrlLower.indexOf('/static/js/bundle');
        }
        if (position > -1) {
          baseUrl = scriptUrl.substring(0, position);
          break;
        }
      }
    }
  }

  return baseUrl;
};

export function scrollIntoViewIfNeeded(element: Element, container: Element) {
  let rectElem = element.getBoundingClientRect();
  let rectContainer = container.getBoundingClientRect();

  if (rectElem.bottom > rectContainer.bottom) {
    element.scrollIntoView(false);
  }
  if (rectElem.top < rectContainer.top) {
    element.scrollIntoView();
  }
}
