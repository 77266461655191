import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import './AgencyHeader.scss';
import { Row, Col } from 'reactstrap';
import DigiCertSeal from './DigiCertSeal';
import classNames from 'classnames';
import WithMediaQuery from './WithMediaQuery';
import { AgencyInfoPosition } from '../store/Site/state';

type OwnProps = {
  isMobile: boolean;
}

type AgencyFooterProps =
  OwnProps &
  ReturnType<typeof mapStateToProps>;

class AgencyFooter extends React.PureComponent<AgencyFooterProps> {

  public render() {
    const { isMobile, site, site: { agencyInfoPosition } } = this.props;

    let agencyInfoClassName: string = 'agency-header__info';
    let secureSiteSealClassName: string = 'text-center';

    let quote = this.props.quote;

    let agencyLicense = '';
    if (quote.agencyLicense) {
      agencyLicense = 'Agency ' + quote.addressState + ' License # ' + quote.agencyLicense;
    }

    // if configured for iframe, don't show agency info and secure site seal takes up the whole width
    if (agencyInfoPosition === AgencyInfoPosition.None) {
      agencyInfoClassName = classNames('d-none', agencyInfoClassName);
      secureSiteSealClassName = classNames('col-12', secureSiteSealClassName);
    }
    // on mobile, agency info gets half and secure site seal gets half
    else if (isMobile) {
      agencyInfoClassName = classNames('col-6', agencyInfoClassName);
      secureSiteSealClassName = classNames('col-6', secureSiteSealClassName);
    }
    else if (agencyInfoPosition === AgencyInfoPosition.Left) {
      agencyInfoClassName = classNames('col-2', 'invisible', agencyInfoClassName);
      secureSiteSealClassName = classNames('col-10', secureSiteSealClassName);
    }
    else { // if (agencyInfoPosition === AgencyInfoPosition.Top) 
      agencyInfoClassName = classNames('d-none', agencyInfoClassName);
      secureSiteSealClassName = classNames('col-12', secureSiteSealClassName);
    }

    return (
      <Row className='agency-header__container my-3 ml-2'>
        <Col data-testid="agency-header-info" className={agencyInfoClassName}>
          <div>
            {site.agencyName}
          </div>
          {agencyLicense.length > 0 &&
            <div className='w-100'>
              {agencyLicense}
            </div>
          }
          <div>
            {site.address}
          </div>
          {site.address2 &&
            <div data-testid="agency-header-address2">
              {site.address2}
            </div>
          }
          <div>
            {site.cityStateZip}
          </div>
          {site.phone &&
            <div data-testid="agency-header-phone">
            <a href={"tel:+1" + site.phone}>{site.phone}</a>
            </div>
          }
        </Col>
        <Col data-testid='secureSiteSealImageCol' className={secureSiteSealClassName}>
          <DigiCertSeal />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  quote: state.quote
});

export default WithMediaQuery([{ PropName: "isMobile", Query: { maxWidth: 1024 } }])(connect(
  mapStateToProps
)(AgencyFooter));
