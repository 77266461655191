import React from 'react';
import { useMediaQuery } from 'react-responsive';

export interface MediaQueryItem {
  PropName: string,
  Query: any
}

const WithMediaQuery = (queries: MediaQueryItem[]) => (WrappedComponent: any) => {
  const newComponent = (props: any) => {
    const mediaProps: any = {};
    queries.forEach(q => {
      // eslint shows the call to useMediaQuery as an error but it really isn't so suppress the error
      // eslint-disable-next-line
      mediaProps[q.PropName] = useMediaQuery(q.Query);
    });
    return <WrappedComponent {...mediaProps} {...props} />;
  };

  newComponent.displayName = `WithMediaQuery (${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return newComponent;
}

export default WithMediaQuery;
