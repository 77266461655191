import React, { Fragment } from 'react';
import axios, { AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling'
import { getBaseUrl, scrollIntoViewIfNeeded } from '../../services/helper';
import { ListGroup, ListGroupItem, Label, FormFeedback } from 'reactstrap';
import Loader from '../Loader';
import classNames from 'classnames';
import validation, { ValidationProps } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';

import './VehiclePage.scss';

type OwnProps = {
  currentIndex: number,
  year: number,
  make: string,
  model: string,
  visible: boolean,
  onClick: (model: string) => void,
  tabKey: string,
  setTabKey: (key: string) => void,
  isLgScreen: boolean
}

type ModelListProps =
  ValidationProps & 
  OwnProps

interface ModelListState {
  loading: boolean,
  models: string[]
}

class ModelList extends React.PureComponent<ModelListProps, ModelListState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      models: []
    };

    this.getValidationObject = this.getValidationObject.bind(this);
  }

  elementRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.loadModels();
  }

  componentDidUpdate(prevProps: ModelListProps) {
    // if we changed year or make, reload models
    if (prevProps.year !== this.props.year || prevProps.make !== this.props.make) {
      this.setState({
        models: []
      })

      this.loadModels();
    }

    let element: Element | null;
    element = this.elementRef.current;
    if (element) {
      let activeElement = element.querySelector('.active');
      let containerElement = element.querySelector('.vehicle-page__list');
      if (activeElement && containerElement) {
        scrollIntoViewIfNeeded(activeElement, containerElement);
      }

      if (this.props.tabKey === 'model') {
        if (activeElement) {
          (activeElement as HTMLElement).focus();
        }
        else {
          const first = element.querySelector('.model-list__item:first-of-type');
          if (first) {
            (first as HTMLElement).focus();
          }
        }
        this.props.setTabKey('');
      }
    }
  }

  loadModels() {
    if (this.props.year !== 0 && this.props.make !== '') {
      this.setState({
        loading: true
      });

      axios.get(getBaseUrl() + `/VehicleInfo/Models?year=${this.props.year}&make=${encodeURIComponent(this.props.make)}`)
        .then((response) => {
          this.setState({
            models: response.data,
            loading: false
          });

          if (response.data.length === 1) {
            this.props.onClick(response.data[0]);
          }
        })
        .catch((error: AxiosError) => {
          errorResponseHandler(error);
        });
    }
  }

  getValidationObject = (): any => {
    return {
      year: this.props.year,
      make: this.props.make,
      model: this.props.model
    };
  };

  OnKeyDownNavigate = (e: React.KeyboardEvent<HTMLElement>) => {
    let element: HTMLElement | null = null;

    switch (e.which) {
      case 9: // tab
        e.preventDefault();
        if (e.shiftKey) {
          if (this.props.isLgScreen) {
            this.props.setTabKey('make');
          }
          else {
            let header: Element | null;
            header = document.querySelector('#agencyHeader') as Element;
            if (header) {
              (header as HTMLElement).focus();
            }
          }
        }
        else {
          if (this.props.isLgScreen) {
            this.props.setTabKey('body-style');
          }
          else {
            let continueButton: Element | null;
            continueButton = document.querySelector('#continueButton') as Element;
            if (continueButton) {
              (continueButton as HTMLElement).focus();
            }
          }
        }
        break;
      case 13: // enter
      case 32: // spacebar
        e.preventDefault();
        element = e.currentTarget as HTMLElement;
        if (element) {
          element!.click();
        }
        break;
      case 38: // up arrow
        e.preventDefault();
        element = e.currentTarget.previousElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
      case 40: // down arrow
        e.preventDefault();
        element = e.currentTarget.nextElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
    }
  }

  render() {
    if (!this.props.visible || this.props.year === 0 || this.props.make === '') {
      return null;
    }

    const { getErrorText, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Vehicles);
    //show the field required message if this field is invalid
    const isModelInvalid = isFieldInvalid(validationConfig.vehicles.model, this.props.currentIndex);
    //show the feedback placeholder if any field is invalid to keep things aligned
    const shouldShowFeedback = isFieldInvalid(validationConfig.vehicles.year, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.make, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.model, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.vin, this.props.currentIndex);

    return (
      <div ref={this.elementRef} className="mb-3 mb-md-0">
        <Label className="mt-2 mb-0">{validationConfig.vehicles.model.display}:</Label>
        <FormFeedback className={classNames("my-0", {
          'vehicle-page__list-feedback': isModelInvalid,
          'd-block': shouldShowFeedback
        })}>
          {getErrorText(validationConfig.vehicles.model, this.props.currentIndex) || <span>&nbsp;</span>}
        </FormFeedback>
        <div className="mt-2 vehicle-page__container vehicle-page__without-link">
          {
            this.state.loading &&
            <div className="vehicle-page__list border rounded bg-white">
              <Loader />
            </div>
          }
          {
            !this.state.loading && 
            <Fragment>
              <div className={classNames("vehicle-page__list border rounded bg-white", { 'is-invalid': isModelInvalid })}>
                {this.state.models.length > 0 &&
                  <ListGroup flush className="border-bottom">
                    {
                      this.state.models.map((model: string, index: number) => (
                        <ListGroupItem tag="button" type="button" action active={model === this.props.model} className='model-list__item'
                          onKeyDown={this.OnKeyDownNavigate} key={index} onClick={() => this.props.onClick(model)} >
                          {
                            /* add space around slashes so browser can word wrap if needed */
                            model.replace(/\//g, ' / ')
                          }
                        </ListGroupItem>
                      ))
                    }
                  </ListGroup>
                }
              </div>
            </Fragment>
          }
        </div>
      </div>
    )
  }
}

export default validation(ModelList);
