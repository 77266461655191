import { getCurrentDate } from './helper';
import { homeownersType } from '../store/Quote/state';
import { isNil, some } from 'lodash';
import { StepType } from '../store/Stepper/state';

export enum validationLevel {
  None = 'none',
  All = '',
  Drivers = 'drivers',
  Vehicles = 'vehicles',
  HomeCharacteristics = 'homeCharacteristics',
  Coverages = 'coverages',
  Incidents = 'incidents',
  HomeLosses = 'homeLosses',
  Rates = 'rates'
}

export const distanceToCoastStates = ['AK', 'AL', 'CA', 'CT', 'DC', 'DE', 'FL', 'GA', 'LA', 'MA', 'MD', 'ME', 'MS', 'NC', 'NH', 'NJ', 'NY', 'OR', 'RI', 'SC', 'TX', 'VA', 'WA'];

const employmentIndustryConfig: any = {
  isRequired: {}
};

const priorCarriorSkipCondition: any = { field: 'priorCarrier', config: { skip: { options: ['', 'None'] } } };
const autoSkipCondition: any = { field: 'lob', config: { skip: { options: ['PA'] } } };
const homeSkipCondition: any = { field: 'lob', config: { skip: { options: ['HO'] } } };
const packageSkipCondition: any = { field: 'lob', config: { skip: { options: ['PP'] } } };
const firstDriverCondition: any = { field: 'conditionIndex', config: { skip: { options: ['0'] } } };
const otherDriversCondition: any = { field: 'conditionIndex', config: { skip: { options: ['1', '2', '3'] } } };
const milesToWorkSkipCondition: any = { field: 'use', config: { skip: { options: ['', 'Business', 'Pleasure', 'Farm'] } } };

const distanceToCoastAppliesToCondition: any = { field: 'addressState', config: { appliesTo: { options: distanceToCoastStates } } };

const renovationUpdateSkipCondition: any = { field: 'renovated', config: { skip: { options: ['', 'false'] } } };
const renovationUpdateRequiredCondition = function (objToValidate: any) {
  return (isNil(objToValidate.roofUpdateYear) && isNil(objToValidate.plumbingUpdateYear) &&
    isNil(objToValidate.electricalUpdateYear) && isNil(objToValidate.heatingUpdateYear));
};

const isAccidentSkipCondition: any = { field: 'incidentType', config: { skip: { options: ['', '0', '2', '3'] } } };
const isViolationSkipCondition: any = { field: 'incidentType', config: { skip: { options: ['', '0', '1', '3'] } } };
const isComprehensiveLossSkipCondition: any = { field: 'incidentType', config: { skip: { options: ['', '0', '1', '2'] } } };
const isSpeedingSkipCondition: any = {
  field: 'violationType', config: {
    skip: {
      options: ['', 'Careless Driving', 'Defective Equipment', 'Driving on Suspended License', 'DUI', 'Failure to Obey Signal', 'Other Major', 'Other Minor', 'Not Listed'
      ]
    }
  }
}

// comp/collision are required if some of the vehicles have it included
const compCollRequiredCondition = function (objToValidate: any, rootObject: any) {
  return some(rootObject.vehicles, (vehicle) => vehicle.includeCompColl);
};

export const validationConfig: any = {
    [validationLevel.Drivers]: {
        firstName: {
            fieldName: 'firstName',
            display: 'First Name',
            step: StepType.ApplicantDrivers,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        lastName: {
            fieldName: 'lastName',
            display: 'Last Name',
            step: StepType.ApplicantDrivers,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        email: {
            fieldName: 'email',
            display: 'Email',
            step: StepType.ApplicantDrivers,
            questionGroup: 1,
            validators: { isRequired: { conditions: [otherDriversCondition] }, isValidEmail: {} }
        },
        phone: {
            fieldName: 'phone',
            display: 'Phone',
            step: StepType.ApplicantDrivers,
            questionGroup: 1,
            validators: { isRequired: { conditions: [otherDriversCondition] }, isValidPhone: {} }
        },
        preferredContact: {
            fieldName: 'preferredContact',
            display: 'Preferred Contact Method',
            step: StepType.ApplicantDrivers,
            questionGroup: 1,
            validators: { isRequired: { conditions: [otherDriversCondition] } }
        },
        relationship: {
            fieldName: 'relationship',
            display: 'Relationship to Driver #1',
            step: StepType.ApplicantDrivers,
            questionGroup: 1,
            validators: { isRequired: { conditions: [firstDriverCondition] } }
        },
        dateOfBirth: {
            fieldName: 'dateOfBirth',
            display: 'Date Of Birth',
            step: StepType.ApplicantDrivers,
            questionGroup: 1,
            validators: { isRequired: {}, isValidDate: { min: '1900-01-01', max: getCurrentDate() } }
        },
        gender: {
            fieldName: 'gender',
            display: 'Gender',
            step: StepType.ApplicantDrivers,
            questionGroup: 2,
            validators: { isRequired: {} }
        },
        maritalStatus: {
            fieldName: 'maritalStatus',
            display: 'Marital Status',
            step: StepType.ApplicantDrivers,
            questionGroup: 2,
            validators: { isRequired: {} }
        },
        ssn: {
            fieldName: 'ssn',
            display: 'Social Security Number',
            helpText: 'Social Security number is optional, however entering a social security number helps in providing accurate results.',
            step: StepType.ApplicantDrivers,
            questionGroup: 2,
            validators: { isValidSsn: {} }
        },
        employmentIndustry: {
            fieldName: 'employmentIndustry',
            display: 'Employment Industry',
            step: StepType.ApplicantDrivers,
            questionGroup: 2,
            validators: employmentIndustryConfig
        },
        occupation: {
            fieldName: 'occupation',
            display: 'Occupation',
            step: StepType.ApplicantDrivers,
            questionGroup: 2,
            validators: { isRequired: { conditions: [{ field: 'employmentIndustry', config: employmentIndustryConfig }] } }
        },
        hasIncidents: {
            fieldName: 'hasIncidents',
            display: 'Any Accidents, Violations or Losses in the Past 5 Years?',
            step: StepType.ApplicantDrivers,
            questionGroup: 2,
            validators: { isRequired: { conditions: [homeSkipCondition] } }
        }
    },
    [validationLevel.Vehicles]: {
        year: {
            fieldName: 'year',
            display: 'Year',
            step: StepType.Vehicles,
            questionGroup: 1,
            validators: {
                isValidNumber: {
                    min: 1976,
                    max: new Date().getFullYear() + 1
                }
            }
        },
        make: {
            fieldName: 'make',
            display: 'Make',
            step: StepType.Vehicles,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        model: {
            fieldName: 'model',
            display: 'Model',
            step: StepType.Vehicles,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        bodyStyle: {
            fieldName: 'bodyStyle',
            display: 'Body Style',
            step: StepType.Vehicles,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        vin: {
            fieldName: 'vin',
            display: 'Vehicle Identification Number',
            step: StepType.Vehicles,
            questionGroup: 1,
            validators: {
                isRequired: {},
                isValidVin: {}
            }
        },
        assignedDriverId: {
            fieldName: 'assignedDriverId',
            display: 'Who operates this Vehicle?',
            step: StepType.Vehicles,
            questionGroup: 2,
            validators: {
                isRequired: {},
                isValidAssignedDriver: {}
            }
        },
        use: {
            fieldName: 'use',
            display: 'Primary use',
            helpText: '<b>Pleasure Use</b> is when the vehicle is most often used for non-commuting activity, and if the vehicle is not used ' +
                'in the course of any business activity.<br /><b>To and From Work or School</b> is if the vehicle is regularly used for ' +
                'commuting to a workplace or school and the one-way commute distance is over 3 miles.<br /><b>Business Use</b> is if the ' +
                'vehicle is regularly used for occupational responsibilities, such as traveling between locations for sales calls or other ' +
                'business appointments.<br /><b>Farm Use</b> is if the vehicle is only used in farming or ranching activities.',
            step: StepType.Vehicles,
            questionGroup: 2,
            validators: { isRequired: {} }
        },
        milesToWork: {
            fieldName: 'milesToWork',
            display: 'Miles to work',
            step: StepType.Vehicles,
            questionGroup: 2,
            validators: {
                isRequired: {
                    conditions: [milesToWorkSkipCondition],
                },
                isValidNumber: {
                    conditions: [milesToWorkSkipCondition],
                    min: 1,
                    max: 99
                }
            }
        },
        annualMiles: {
            fieldName: 'annualMiles',
            display: 'Annual miles',
            helpText: 'What is the appoximate number of miles this vehicle is driven each year?',
            step: StepType.Vehicles,
            questionGroup: 2,
            validators: {
                isRequired: {},
                isValidNumber: { min: 1, max: 99999 }
            }
        },
        includeCompColl: {
            fieldName: 'includeCompColl',
            display: 'Would you like Comprehensive and/or Collision coverage on this Vehicle?',
            helpText: 'Comprehensive coverage (other than collision, otc, comp) covers your vehicle for losses resulting from incidents ' +
                'other than collision. For example, comprehensive insurance covers damage to your car if it is stolen; or damaged by flood, ' +
                'fire, or animals. It pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as high a ' +
                'deductible as you feel comfortable paying out of pocket. Coverage is limited to the terms and conditions contained in the ' +
                'policy. This is not required by a state, but if you have a loan or a lease then the lien holder will require it.<br/><br/>' +
                'Collision coverage (coll) covers damage to your car when your car hits, or is hit by, another vehicle, or other object. ' +
                'Pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as large a deductible as you feel ' +
                'comfortable paying out of pocket. For older cars, consider dropping this coverage, since coverage is normally limited to the ' +
                'cash value of your car. Coverage is limited to the terms and conditions contained in the policy. This is not required by a state, ' +
                'but if you have a loan or a lease then the lien holder will require it.',
            step: StepType.Vehicles,
            questionGroup: 2,
            validators: { isRequired: {} }
        }
    },
    [validationLevel.HomeCharacteristics]: {
        residenceType: {
            fieldName: 'residenceType',
            display: 'Residence Type',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        yearsAtResidence: {
            fieldName: 'yearsAtResidence',
            display: 'How many years have you lived at this residence?',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isRequired: {},
                isValidNumber: {
                    max: function (objToValidate: any) {
                        let year = new Date().getFullYear();
                        let yearBuilt = objToValidate.yearBuilt;
                        // Can't have lived at residence more than age of residence.
                        // The +1 accounts for cases where house was built in January and it is now December of same year
                        // where user would likely say they have lived in house 1 year.
                        return (year - yearBuilt + 1);
                    }
                }
            }
        },
        yearBuilt: {
            fieldName: 'yearBuilt',
            display: 'Year Built',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isRequired: {},
                isValidNumber: {
                    min: 1800,
                    max: new Date().getFullYear() + 1
                }
            }
        },
        squareFootage: {
            fieldName: 'squareFootage',
            display: 'Square Footage',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isRequired: {},
                isValidNumber: {
                    min: 1,
                    max: 999999
                }
            }
        },
        construction: {
            fieldName: 'construction',
            display: 'Construction Type',
            helpText: '<b>Frame</b> - home is built with exterior walls of wood, brick veneer, stone veneer, wood ironclad, or stucco on wood.' +
                '<br /><b>Masonry</b> - home is built with exterior walls of adobe, brick, concrete, concrete block, stone, tile, or similar materials.',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isRequired: {
                    conditions: [{ field: 'residenceType', config: { skip: { options: ['Mobile Home'] } } }]
                },
            }
        },
        numberOfUnits: {
            fieldName: 'numberOfUnits',
            display: 'Number of Units',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isRequired: {
                    conditions: [{ field: 'homeowners', config: { skip: { options: [homeownersType.Homeowner] } } }]
                },
                isValidNumber: {
                    conditions: [{ field: 'homeowners', config: { skip: { options: [homeownersType.Homeowner] } } }],
                    min: 1,
                    max: 999
                },
            }
        },
        distanceToCoast: {
            fieldName: 'distanceToCoast',
            display: 'Distance to Coast',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isValidNumber: {
                    conditions: [
                        distanceToCoastAppliesToCondition,
                        function (objToValidate: any) {
                            return (objToValidate.milesToCoast === 0 && objToValidate.feetToCoast === 0);
                        }
                    ],
                    min: 1
                }
            }
        },
        milesToCoast: {
            fieldName: 'milesToCoast',
            display: 'Miles to Coast',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isValidNumber: {
                    conditions: [
                        distanceToCoastAppliesToCondition,
                        function (objToValidate: any) {
                            return ((objToValidate.milesToCoast === 0 && objToValidate.feetToCoast === 0) ||
                                objToValidate.milesToCoast !== 0);
                        }
                    ],
                    min: 1,
                    max: 999
                }
            }
        },
        feetToCoast: {
            fieldName: 'feetToCoast',
            display: 'Feet to Coast',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: {
                isValidNumber: {
                    conditions: [
                        distanceToCoastAppliesToCondition,
                        function (objToValidate: any) {
                            return ((objToValidate.milesToCoast === 0 && objToValidate.feetToCoast === 0) ||
                                objToValidate.feetToCoast !== 0);
                        }
                    ],
                    min: 1,
                    max: 9999
                }
            }
        },
        renovated: {
            fieldName: 'renovated',
            display: 'Has the home been renovated/updated?',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        hasHomeLosses: {
            fieldName: 'hasHomeLosses',
            display: 'Have you had any homeowners claims in the last 5 years?',
            step: StepType.HomeCharacteristics,
            questionGroup: 1,
            validators: { isRequired: {} }
        },
        roofUpdateYear: {
            fieldName: 'roofUpdateYear',
            display: 'Roof updated year',
            step: StepType.HomeCharacteristics,
            questionGroup: 2,
            validators: {
                isRequired: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        renovationUpdateRequiredCondition
                    ]
                },
                isValidNumber: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        function (objToValidate: any) {
                            return (!isNil(objToValidate.roofUpdateYear));
                        }
                    ],
                    min: function (objToValidate: any) {
                        return objToValidate.yearBuilt;
                    },
                    max: new Date().getFullYear()
                }
            }
        },
        plumbingUpdateYear: {
            fieldName: 'plumbingUpdateYear',
            display: 'Plumbing updated year',
            step: StepType.HomeCharacteristics,
            questionGroup: 2,
            validators: {
                isRequired: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        renovationUpdateRequiredCondition
                    ]
                },
                isValidNumber: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        function (objToValidate: any) {
                            return (!isNil(objToValidate.plumbingUpdateYear));
                        }
                    ],
                    min: function (objToValidate: any) {
                        return objToValidate.yearBuilt;
                    },
                    max: new Date().getFullYear()
                }
            }
        },
        electricalUpdateYear: {
            fieldName: 'electricalUpdateYear',
            display: 'Electrical updated year',
            step: StepType.HomeCharacteristics,
            questionGroup: 2,
            validators: {
                isRequired: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        renovationUpdateRequiredCondition
                    ]
                },
                isValidNumber: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        function (objToValidate: any) {
                            return (!isNil(objToValidate.electricalUpdateYear));
                        }
                    ],
                    min: function (objToValidate: any) {
                        return objToValidate.yearBuilt;
                    },
                    max: new Date().getFullYear()
                }
            }
        },
        heatingUpdateYear: {
            fieldName: 'heatingUpdateYear',
            display: 'Heating updated year',
            step: StepType.HomeCharacteristics,
            questionGroup: 2,
            validators: {
                isRequired: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        renovationUpdateRequiredCondition
                    ]
                },
                isValidNumber: {
                    conditions: [
                        renovationUpdateSkipCondition,
                        function (objToValidate: any) {
                            return (!isNil(objToValidate.heatingUpdateYear));
                        }
                    ],
                    min: function (objToValidate: any) {
                        return objToValidate.yearBuilt;
                    },
                    max: new Date().getFullYear()
                }
            }
        },
    },
    [validationLevel.Coverages]: {
        priorCarrier: {
            fieldName: 'priorCarrier',
            display: 'Current Insurance Company',
            step: StepType.Coverages,
            questionGroup: 1,
            validators: {
                isRequired: {}
            }
        },
        packagePriorCarriersDifferent: {
            fieldName: 'packagePriorCarriersDifferent',
            display: 'Is current home insurance company different than auto',
            step: StepType.Coverages,
            questionGroup: 1,
            validators: {
                isRequired: {
                    conditions: [{ field: 'lob', config: { appliesTo: { options: ['PP'] } } }]
                }
            }
        },
        curCovLevel: {
            fieldName: 'curCovLevel',
            display: 'Current Auto Liability Coverage Level',
            helpText: '<b>Single Limit</b> auto insurance coverage allows you one single amount of coverage that can be used as needed, for either bodily ' +
                'injury or property damage. This one single amount is the most the insurance company will be obligated to pay for damages resulting ' +
                'from one single accident. It can also be termed combined single limit (CSL).<br /><b>Split Limit</b> coverage splits the coverage amount into 3 ' +
                'limits such as 25/50/10.Under the split limit coverage these numbers would mean that you have $25, 000 of bodily injury coverage per ' +
                'person per accident, $50, 000 total for all injuries per accident and a $10, 000 limit for property damage done in one accident. ' +
                '<br /><b>Bodily injury liability(BI)</b> covers other people\'s bodily injuries or death for which you are responsible. It also provides for a ' +
                'legal defense if another party in the accident files a lawsuit against you. Claims for bodily injury may be for such things as medical ' +
                'bills, loss of income or pain and suffering. In the event of a serious accident, you want enough insurance coverage to cover a judgment ' +
                'against you in a lawsuit, without jeopardizing your personal assets. Bodily injury liability covers injury to people, not your vehicle. ' +
                'Therefore, it is a good idea (and usually a company requirement) to have the same level for all of your cars. Bodily Injury Liability ' +
                'does NOT cover you or other people on your policy. Coverage is limited to the terms and conditions contained in the policy. It is ' +
                'mandatory in most states.',
            step: StepType.Coverages,
            questionGroup: 1,
            validators: {
                isRequired: {
                    conditions: [
                        homeSkipCondition,
                        priorCarriorSkipCondition
                    ]
                }
            }
        },
        curPolicyExpDate: {
            fieldName: 'curPolicyExpDate',
            display: 'Current Policy Expiration Date',
            step: StepType.Coverages,
            questionGroup: 1,
            validators: {
                isRequired: {
                    conditions: [
                        homeSkipCondition,
                        priorCarriorSkipCondition
                    ]
                },
                isValidDate: { min: '1900-01-01', max: getCurrentDate(1) }
            }
        },
        policyStartDate: {
            fieldName: 'policyStartDate',
            display: 'Policy Start Date',
            helpText: 'When would you like your new auto and/or home and/or package policy to start?',
            step: StepType.Coverages,
            questionGroup: 2,
            validators: { isRequired: {}, isValidDate: { min: getCurrentDate(), max: getCurrentDate(1) } }
        },
        bundleAutoHome: {
            fieldName: 'bundleAutoHome',
            display: 'Bundle Auto and Home Discount',
            helpText: 'Buying your auto and home insurance from a single carrier could result in a multi-policy premium discount.',
            step: StepType.Coverages,
            questionGroup: 2,
            validators: { isRequired: { conditions: [packageSkipCondition] } }
        },
        paperlessBilling: {
          fieldName: 'paperlessBilling',
            display: 'Would you like to go Paperless?',
            helpText: 'Receiving all communications and documents including billing electronically, could result in a paperless premium discount.',
            step: StepType.Coverages,
            questionGroup: 2,
            validators: { isRequired: { conditions: [packageSkipCondition] } }
        },
        smokersInHousehold: {
            fieldName: 'smokersInHousehold',
            display: 'Are there any smokers in the household?',
            step: StepType.Coverages,
            questionGroup: 2,
            validators: { isRequired: { conditions: [autoSkipCondition] } }
        },
        compDed: {
            fieldName: 'compDed',
            display: 'Comprehensive Deductible',
            helpText: 'Comprehensive coverage (other than collision, otc, comp) covers your vehicle for losses resulting from incidents ' +
                'other than collision. For example, comprehensive insurance covers damage to your car if it is stolen; or damaged by flood, ' +
                'fire, or animals. It pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as high a ' +
                'deductible as you feel comfortable paying out of pocket. Coverage is limited to the terms and conditions contained in the ' +
                'policy. This is not required by a state, but if you have a loan or a lease then the lien holder will require it.',
            step: StepType.Coverages,
            questionGroup: 3,
            validators: { isRequired: { conditions: [homeSkipCondition, compCollRequiredCondition] } }
        },
        collDed: {
            fieldName: 'collDed',
            display: 'Collision Deductible',
            helpText: 'Collision coverage (coll) covers damage to your car when your car hits, or is hit by, another vehicle, or other object. ' +
                'Pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as large a deductible as you feel ' +
                'comfortable paying out of pocket. For older cars, consider dropping this coverage, since coverage is normally limited to the ' +
                'cash value of your car. Coverage is limited to the terms and conditions contained in the policy. This is not required by a state, ' +
                'but if you have a loan or a lease then the lien holder will require it.',
            step: StepType.Coverages,
            questionGroup: 3,
            validators: { isRequired: { conditions: [homeSkipCondition, compCollRequiredCondition] } }
        },
        homeDed: {
            fieldName: 'homeDed',
            display: 'Homeowner Deductible',
            helpText: 'The deductible is the amount you must pay out of pocket before the insurance pays. Note: Typically, the higher the ' +
                'deductible, the lower the premium but the more out of pocket you will pay if there is a claim.',
            step: StepType.Coverages,
            questionGroup: 3,
            validators: { isRequired: { conditions: [autoSkipCondition] } }
        },
        homeCoverage: {
            fieldName: 'homeCoverage',
            display: 'Homeowner Coverage',
            step: StepType.Coverages,
            questionGroup: 3,
            validators: { isRequired: { conditions: [autoSkipCondition] } }
        },
        selectedPlan: {
            fieldName: 'selectedPlan',
            display: 'Select a Coverage Plan',
            step: StepType.Coverages,
            questionGroup: 3,
            validators: { isRequired: {} }
        }
    },
    [validationLevel.Incidents]: {
        incidentType: {
            fieldName: 'incidentType',
            display: 'Type of Incident',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {}
            }
        },
        incidentDate: {
            fieldName: 'incidentDate',
            display: 'Date of Incident',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {}, isValidDate: { min: '2000-01-01', max: getCurrentDate() }
            }
        },
        accidentType: {
            fieldName: 'accidentType',
            display: 'Type of Accident',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {
                    conditions: [isAccidentSkipCondition]
                }
            }
        },
        violationType: {
            fieldName: 'violationType',
            display: 'Type of Violation',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {
                    conditions: [isViolationSkipCondition]
                }
            }
        },
        actualSpeed: {
            fieldName: 'actualSpeed',
            display: 'Actual Speed',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {
                    conditions: [isViolationSkipCondition, isSpeedingSkipCondition]
                }
            }
        },
        postedLimit: {
            fieldName: 'postedLimit',
            display: 'Posted Limit',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {
                    conditions: [isViolationSkipCondition, isSpeedingSkipCondition]
                }
            }
        },
        lossType: {
            fieldName: 'lossType',
            display: 'Type of Loss',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {
                    conditions: [isComprehensiveLossSkipCondition]
                }
            }
        },
        lossAmount: {
            fieldName: 'lossAmount',
            display: 'Loss Amount',
            step: StepType.ApplicantDrivers,
            questionGroup: 3,
            validators: {
                isRequired: {
                    conditions: [isComprehensiveLossSkipCondition]
                }
            }
        },
    },
    [validationLevel.HomeLosses]: {
        lossCause: {
            fieldName: 'lossCause',
            display: 'Claim Type',
            step: StepType.HomeCharacteristics,
            questionGroup: 3,
            validators: {
                isRequired: {}
            }
        },
        lossDate: {
            fieldName: 'lossDate',
            display: 'Claim Date',
            step: StepType.HomeCharacteristics,
            questionGroup: 3,
            validators: {
                isRequired: {}, isValidDate: { min: '2000-01-01', max: getCurrentDate() }
            }
        }
    },
    [validationLevel.Rates]: {
        buyNowImInterested: {
            fieldName: 'buyNowImInterested',
            helpText: 'Select the <b>I’m Interested</b> button to send an email to the agency ' +
                'letting them know you are interested in this carrier for this quote! <br/><br/>' +
                'Select the <b>Buy Now</b> to complete the purchase of this insurance.',
            step: StepType.Rates,
            validators: {
            }
        },
        ImInterested: {
            fieldName: 'ImInterested',
            helpText: 'Select the <b>I’m Interested</b> button to send an email to the agency ' +
                'letting them know you are interested in this carrier for this quote!',
            step: StepType.Rates,
            validators: {
            }
        }
    }
};
