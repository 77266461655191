import React, { Fragment } from 'react';
import { Row, Col, Form, FormGroup, FormFeedback, Card, CardHeader, CardText, CardBody, Input, InputGroup } from 'reactstrap';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import Swipeable from '../Swipeable';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import { QuestionOption } from '../../store/QuestionOptions/state';
import { Coverages, coveragePlan, homeownersType, LineOfBusinessType } from '../../store/Quote/state';
import { hasLob, getBaseUrl } from '../../services/helper';
import errorResponseHandler from '../../services/errorHandling';
import axios, { AxiosError } from 'axios';
import { CoveragePlans, CoverageLobs, CoverageOption } from '../../models/coverages';
import classNames from 'classnames';
import './CoveragePage.scss';
import { isNil, some } from 'lodash';
import { GoPrimitiveDot as Bullet } from 'react-icons/go'
import HelpText from '../HelpText';

type OwnProps = {
  onPreviousClick?: () => void,
  onNextClick?: () => void
}

type QuestionGroup3Props =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface QuestionGroup3State {
  [x: string]: any;
  loading: boolean,
  planCount: number,
  coveragePlans: CoveragePlans,
  compDed: string,
  collDed: string,
  homeDed: string,
  homeCoverage: number | null,
  selectedPlan: coveragePlan,
  hoveredPlan: coveragePlan
}

class QuestionGroup3 extends React.PureComponent<QuestionGroup3Props, QuestionGroup3State> {
  constructor(props: any) {
    super(props);

    const { coverages } = this.props.quote;

    this.state = {
      loading: false,
      planCount: 0,
      coveragePlans: {} as CoveragePlans,
      compDed: coverages.compDed,
      collDed: coverages.collDed,
      homeDed: coverages.homeDed,
      homeCoverage: coverages.homeCoverage,
      selectedPlan: coverages.selectedPlan,
      hoveredPlan: coverages.selectedPlan
    }

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
    this.loadCoverages = this.loadCoverages.bind(this);

    this.props.setIsValidFunc(this.isValid);
  }

  getValidationObject = (): any => {
    // validation object needs to be structured similar to quote in order to valide comp/collision deductibles
    return {
      vehicles: this.props.quote.vehicles,
      coverages: {
        compDed: this.state.compDed,
        collDed: this.state.collDed,
        homeCoverage: this.state.homeCoverage,
        homeDed: this.state.homeDed,
        selectedPlan: this.state.selectedPlan
      }
    };
  };

  componentDidMount() {
    this.loadCoverages();
  }

  componentWillUnmount() {
    this.props.updateCoverages({
      ...this.props.quote.coverages,
      compDed: this.state.compDed,
      collDed: this.state.collDed,
      homeDed: this.state.homeDed,
      homeCoverage: this.state.homeCoverage,
      selectedPlan: this.state.selectedPlan
    });
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow) {
    return this.props.validation(this.getValidationObject(), validationLevel.Coverages).isPageValid(showErrors);
  }

  loadCoverages() {
    this.setState({
      loading: true
    });

    const { id, enableStateMinPlan, enableStandardPlan, enableBasicPlan, enablePreferredPlan } = this.props.site;
    const { homeowners, lineOfBusiness, addressState } = this.props.quote;

    axios.get(`${getBaseUrl()}/coverages/${id}/${lineOfBusiness}/${addressState}?homeownerType=${homeowners}` +
      `&isStateMin=${enableStateMinPlan}&isStandard=${enableStandardPlan}&isBasic=${enableBasicPlan}&isPreferred=${enablePreferredPlan}`)
      .then((response) => {
        const plans: CoveragePlans = response.data;
        let planList: coveragePlan[] = [];

        if (plans.stateMinOptions !== null) {
          planList.push(coveragePlan.StateMin);
        }
        if (plans.standardOptions !== null) {
          planList.push(coveragePlan.Standard);
        }
        if (plans.basicOptions !== null) {
          planList.push(coveragePlan.Basic);
        }
        if (plans.preferredOptions !== null) {
          planList.push(coveragePlan.Preferred);
        }

        this.setState({
          loading: false,
          planCount: planList.length,
          coveragePlans: plans,
          selectedPlan: planList.length === 1 ? planList[0] : this.state.selectedPlan
        });
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  handleChange(event: { target: { name: string; value: string; maxLength: string | number; }; }) {
    let { name, value, maxLength } = event.target
    let saveValue: string | number;

    if (!isNil(maxLength) && maxLength > -1) {
      const max = Number(maxLength);
      if (value.length > max) value = value.substring(0, max);
    }

    // I wanted to be able to determine the type of the state object property at runtime
    // but I could not find a way to do that when some property values can initially be null.
    switch (name) {
      case 'homeCoverage':
        value = value.replace(/\D/g, ''); //remove unwanted characters from copy & paste
        saveValue = Number(value);
        break;
      default:
        saveValue = value;
    }

    this.setState({
      ...this.state,
      [name]: saveValue
    }, () => {
      if (name === 'compDed' && saveValue === 'No Cov') {
        this.setState({
          ...this.state,
          collDed: 'No Cov'
        });
      }
    });
  }

  homeNameLookup = (key: homeownersType): string => {
    const homeLookup: { [key: string]: string } = {
      [homeownersType.Homeowner]: 'Homeowner',
      [homeownersType.Renters]: 'Renter',
      [homeownersType.CondoOwner]: 'Condo Owner',
      [homeownersType.None]: ''
    }
    return homeLookup[key];
  };

  buildPlan = (planName: string, lobData: CoverageLobs | null, plan: coveragePlan) => {
    if (lobData === null) {
      return null;
    }

    const hasAuto = lobData.paOptions.length > 0;
    const hasHome = lobData.hoOptions.length > 0;
    const isPackage = hasAuto && hasHome;
    const planSelected = this.state.selectedPlan === plan;
    const planHovered = this.state.hoveredPlan === plan;

    const writeLines = (options: CoverageOption[]) => {
      return (
        options.map((o: CoverageOption, index: number) => {
          return (
            <div key={index} style={{ paddingLeft: '2em', textIndent: '-2em' }}>
              {o.indent && <Bullet className='mr-1' />}
              {o.value} <span className='text-muted'>{o.display}</span>
            </div>
          );
        })
      );
    };

    return (
      <label className='col mb-0' onMouseEnter={() => this.setState({ hoveredPlan: plan })} onMouseLeave={() => this.setState({ hoveredPlan: coveragePlan.None })}>
        <Card data-testid={'plan' + plan} className={classNames('h-100', {
          'coverage-page__plans--selected': planSelected || planHovered, 'coverage-page__plans--unselected': !planSelected && !planHovered
        })}>
          <input type='radio' name='selectedPlan' value={plan} onChange={this.handleChange} />
          <CardHeader className='font-weight-bold'>{planName}</CardHeader>
          <CardBody>
            <CardText tag='div'>
              {isPackage &&
                <div className='font-italic font-weight-bold'>Auto</div>
              }
              {writeLines(lobData.paOptions)}
              {isPackage &&
                <div className='font-italic font-weight-bold mt-3'>{this.homeNameLookup(this.props.quote.homeowners)}s</div>
              }
              {writeLines(lobData.hoOptions)}
            </CardText>
          </CardBody>
        </Card>
      </label>
    );
  }

  render() {
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Coverages);
    const { hasAuto, hasHome } = hasLob(this.props.quote);
    const { enableStateMinPlan, enableStandardPlan, enableBasicPlan, enablePreferredPlan,
      stateMinPlanName, standardPlanName, basicPlanName, preferredPlanName } = this.props.site;
    const planInvalid = isFieldInvalid(validationConfig.coverages.selectedPlan);
    let lobClass = 'col-12';
    let questionClass = 'col-12 col-sm-6';
    let formClass = 'col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6';
    // only works as object that gets spread
    let plansClass: any = {};

    const isCompCollIncluded = some(this.props.quote.vehicles, (vehicle) => vehicle.includeCompColl);

    let homeCovText = '';
    let homeDedText = '';
    let homeCovError: any;
    let homeDedError: any;
    let homeCovHelpText: any;
    if (hasHome) {    
      homeCovText = this.props.quote.homeowners === homeownersType.Homeowner ? 'Estimated Value of Home' : 'Estimated Value of Contents';
      homeDedText = `${this.homeNameLookup(this.props.quote.homeowners)}${this.props.quote.homeowners === homeownersType.Renters ? 's' : ''} Deductible`;
      homeCovError = getErrorText(validationConfig.coverages.homeCoverage);
      if (homeCovError !== null) {
        homeCovError = homeCovError[0].props.children.replace(validationConfig.coverages.homeCoverage.display, homeCovText);
      }
      homeDedError = getErrorText(validationConfig.coverages.homeDed);
      if (homeDedError !== null) {
        homeDedError = homeDedError[0].props.children.replace(validationConfig.coverages.homeDed.display, homeDedText);
      }
      homeCovHelpText = 'The amount it would cost to replace or rebuild your home in the event it is completely destroyed. It covers the ' +
        'value of the dwelling itself and does not include the land or other structures such as a detached garage or fence. Personal Property ' +
        'includes items not permanently attached to the structure itself. It is the amount it would cost to replace your personal possessions ' +
        'due to a major disaster such as fire or theft.';
    }

    // if showing both auto and home, do them in columns, else just let home be in a row
    if (this.props.quote.lineOfBusiness === LineOfBusinessType.Package && isCompCollIncluded) {
      lobClass = 'col-12 col-sm-6';
      questionClass = 'col-12';
    }

    switch (this.state.planCount) {
      case 2:
        formClass = 'col-lg-9 col-xl-8';
        plansClass = { sm: 2, xs: 1 };
        break;
      case 3:
        formClass = 'col-12';
        plansClass = { md: 3, sm: 2, xs: 1 };
        break;
      case 4:
        formClass = 'col-12';
        plansClass = { lg: 4, sm: 2, xs: 1 };
        break;
      default:
        lobClass = 'col-12';
        questionClass = 'col-12';
        break;
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
      // don't allow decimal or enter (enter causes form to submit on this control and puts form data in url and cause site not found page)
      (e.key === '.' || e.which === 13) && e.preventDefault()
    };

    return (
      <Fragment>
        <h3 className='text-center'>Coverages and Deductibles</h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className={classNames(formClass, 'bg-light pt-2')}
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              <div className='form-row'>
                {hasAuto && isCompCollIncluded && <div className={classNames('form-row', lobClass)}>
                  <FormGroup className={questionClass}>
                    <ValidationLabel config={validationConfig.coverages.compDed} />
                    <HelpText text={validationConfig.coverages.compDed.helpText} helpId='compDed' />
                    <select
                      value={this.state.compDed}
                      autoFocus
                      onChange={this.handleChange}
                      {...getFieldProps(validationConfig.coverages.compDed, 'custom-select d-block coverage-page__group3-inputs')}>
                      <option value=''>{'<select>'}</option>
                      {
                        (this.props.questionOptions['COMP'] || []).map((option: QuestionOption, index: number) => (
                          <option key={index} value={option.value}>{option.text}</option>
                        ))
                      }
                    </select>
                    <FormFeedback>{getErrorText(validationConfig.coverages.compDed)}</FormFeedback>
                  </FormGroup>
                  <FormGroup className={questionClass}>
                    <ValidationLabel config={validationConfig.coverages.collDed} />
                    <HelpText text={validationConfig.coverages.collDed.helpText} helpId='collDed' />
                    <select
                      value={this.state.collDed}
                      onChange={this.handleChange}
                      disabled={this.state.compDed === 'No Cov'}
                      {...getFieldProps(validationConfig.coverages.collDed, 'custom-select d-block coverage-page__group3-inputs')}>
                      <option value=''>{'<select>'}</option>
                      {
                        (this.props.questionOptions['COLL'] || []).map((option: QuestionOption, index: number) => (
                          <option key={index} value={option.value}>{option.text}</option>
                        ))
                      }
                    </select>
                    <FormFeedback>{getErrorText(validationConfig.coverages.collDed)}</FormFeedback>
                  </FormGroup>
                </div>}
                {hasHome && <div className={classNames('form-row', lobClass)}>
                  <FormGroup className={questionClass}>
                    <ValidationLabel config={validationConfig.coverages.homeCoverage}>{homeCovText}:</ValidationLabel>
                    <HelpText text={homeCovHelpText} helpId='homeCoverage' />
                    <InputGroup className='pl-0 coverage-page__group3-inputs'>
                      <div className='input-group-prepend'><span className='input-group-text'>$</span></div>
                      <Input
                        pattern='\d*'
                        type='number'
                        {...getFieldProps(validationConfig.coverages.homeCoverage)}
                        value={this.state.homeCoverage ? this.state.homeCoverage : ''}
                        min={1}
                        max={this.props.site.useLongerDwellingValue ? 99999999 : 9999999}
                        maxLength={this.props.site.useLongerDwellingValue ? 8 : 7}                       
                        autoFocus={!hasAuto || (hasAuto && !isCompCollIncluded)}
                        {...{ onKeyPress }}
                        onChange={this.handleChange}>
                      </Input>
                    </InputGroup>
                    {/* inputgroup and formfeedback don't work well together in bootstrap.  so, manually control the display. */}
                    <FormFeedback className={classNames({ 'd-block': isFieldInvalid(validationConfig.coverages.homeCoverage) })}>
                      {homeCovError}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup className={questionClass}>
                    <ValidationLabel config={validationConfig.coverages.homeDed}>{homeDedText}:</ValidationLabel>
                    <HelpText text={validationConfig.coverages.homeDed.helpText} helpId='homeDed' />
                    <select
                      value={this.state.homeDed}
                      onChange={this.handleChange}
                      {...getFieldProps(validationConfig.coverages.homeDed, 'custom-select d-block coverage-page__group3-inputs')}>
                      <option value=''>{'<select>'}</option>
                      {
                        (this.props.questionOptions['Deductible'] || []).map((option: QuestionOption, index: number) => (
                          <option key={index} value={option.value}>{option.text}</option>
                        ))
                      }
                    </select>
                    <FormFeedback>{homeDedError}</FormFeedback>
                  </FormGroup>
                </div>}
              </div>

              {this.state.planCount > 1 &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.coverages.selectedPlan} />
                  <div className={classNames('form-control h-100', { 'is-invalid py-0 pl-0': planInvalid, 'border border-0 bg-light p-0': !planInvalid })}>
                    <Row className='coverage-page__plans--row' {...plansClass}>
                      {enableStateMinPlan && this.buildPlan(stateMinPlanName!, this.state.coveragePlans.stateMinOptions, coveragePlan.StateMin)}
                      {enableBasicPlan && this.buildPlan(basicPlanName!, this.state.coveragePlans.basicOptions, coveragePlan.Basic)}
                      {enableStandardPlan && this.buildPlan(standardPlanName!, this.state.coveragePlans.standardOptions, coveragePlan.Standard)}
                      {enablePreferredPlan && this.buildPlan(preferredPlanName!, this.state.coveragePlans.preferredOptions, coveragePlan.Preferred)}
                    </Row>
                  </div>
                  <FormFeedback>{getErrorText(validationConfig.coverages.selectedPlan)}</FormFeedback>
                </FormGroup>
              }
            </Form>
          </Swipeable>
        </div>

        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onNextClick={this.nextClickHandler} onPreviousClick={this.previousClickHandler} />
          </Col>
        </Row>
      </Fragment >
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  quote: state.quote,
  questionOptions: state.questionOptions.options || {}
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateCoverages: (coverages: Coverages) =>
    dispatch(QuoteActions.updateCoverages(coverages)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
});

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(QuestionGroup3));