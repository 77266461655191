import React, { Fragment } from 'react';
import { ApplicationState } from '../../store';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { Action as ValidationAction, actionCreators as ValidationActions } from '../../store/Validation/actions';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import Stepper from '../Stepper';
import { StepType } from '../../store/Stepper/state';
import QuestionGroup1 from './QuestionGroup1';
import QuestionGroup2 from './QuestionGroup2';
import QuestionGroup3 from './QuestionGroup3';
import { hasLob } from '../../services/helper';
import { validationConfig } from '../../services/validationConfig';

enum QuestionGroups {
  CurrentInsurance = 1,
  PolicyDiscounts = 2,
  CoveragesDeductibles = 3
}

type CoveragePageProps =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface DriverPageState {
  currentQuestionGroup: number,
}

class CoveragePage extends React.PureComponent<CoveragePageProps, DriverPageState> {
  constructor(props: any) {
    super(props);

    let startQuestionGroup = QuestionGroups.CurrentInsurance;

    if (this.props.isBackPage) {
      if (this.props.validationErrors && this.props.validationErrors.length > 0) {
        let error = this.props.validationErrors[0];
        startQuestionGroup = validationConfig[error.level][error.field].questionGroup;
      }
      else {
        startQuestionGroup = this.totalGroups;
      }
      this.props.clearIsBackPage();
    }

    this.state = {
      currentQuestionGroup: startQuestionGroup
    }

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
  }

  totalGroups: number = 3;

  previousClickHandler() {
    this.props.updateErrorsVisible(false);

    if (this.state.currentQuestionGroup === 1) {
      const { hasHome } = hasLob(this.props.quote);
      this.props.changeStep(StepType.Coverages, hasHome ? StepType.HomeCharacteristics : StepType.Vehicles);
    }
    else {
      this.setState({
        currentQuestionGroup: this.state.currentQuestionGroup - 1
      });
    }
  }

  nextClickHandler() {
    if (this.state.currentQuestionGroup === this.totalGroups) {
      // last page, goto next step
      this.props.changeStep(StepType.Coverages, StepType.Rates);
    }
    else {
      this.setState({
        currentQuestionGroup: this.state.currentQuestionGroup + 1
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Stepper />

        {this.state.currentQuestionGroup === QuestionGroups.CurrentInsurance &&
          <QuestionGroup1 onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler} />
        }
        {this.state.currentQuestionGroup === QuestionGroups.PolicyDiscounts &&
          <QuestionGroup2 onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler} />
        }
        {this.state.currentQuestionGroup === QuestionGroups.CoveragesDeductibles &&
          <QuestionGroup3 onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler} />
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote,
  isBackPage: state.stepper.isBackPage,
  validationErrors: state.validation.errors
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, StepperAction | ValidationAction>) => ({
  changeStep: (current: StepType, selected: StepType) => dispatch(StepperActions.changeStep(current, selected)),
  clearIsBackPage: () => dispatch(StepperActions.clearIsBackPage()),
  updateErrorsVisible: (errorsVisible: boolean) => dispatch(ValidationActions.updateErrorsVisible(errorsVisible))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(CoveragePage as any);
