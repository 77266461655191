import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive'
import axios, { AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling'
import { getBaseUrl, scrollIntoViewIfNeeded } from '../../services/helper';
import { Button, Row, Col, ListGroupItem, ListGroup, Label, FormFeedback } from 'reactstrap';
import Loader from '../Loader';
import { Make } from '../../models/symbols/make';
import classNames from 'classnames';
import validation, { ValidationProps } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';

import './VehiclePage.scss';
import './MakeList.scss';

type OwnProps = {
  currentIndex: number,
  year: number,
  make: string,
  visible: boolean,
  onClick: (make: string) => void,
  tabKey: string,
  setTabKey: (key: string) => void
}

type MakeListProps =
  ValidationProps &
  OwnProps

interface MakeListState {
  loading: boolean,
  showAllMakes: boolean,
  makes: string[],
  popularMakes: Make[]
}

class MakeList extends React.PureComponent<MakeListProps, MakeListState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      showAllMakes: false,
      makes: [],
      popularMakes: []
    };

    this.toggleShowAllMakes = this.toggleShowAllMakes.bind(this);

    this.getValidationObject = this.getValidationObject.bind(this);
  }

  elementRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.loadMakes();
  }

  componentDidUpdate(prevProps: MakeListProps, prevState: MakeListState) {
    // if we changed year reload all makes
    if (prevProps.year !== this.props.year) {
      this.setState({
        makes: [],
        popularMakes: []
      })

      this.loadMakes();
    }

    // if we changed between short makes list and all makes, reload list only if we don't already have it
    if (prevState.showAllMakes !== this.state.showAllMakes) {
      if ((this.state.showAllMakes && this.state.makes.length === 0) ||
        (!this.state.showAllMakes && this.state.popularMakes.length === 0)) {
        this.loadMakes();
      }
    }

    let element: Element | null;
    element = this.elementRef.current;
    if (element) {
      let activeElement = element.querySelector('.active');
      let containerElement = element.querySelector('.vehicle-page__list');
      if (activeElement && containerElement) {
        scrollIntoViewIfNeeded(activeElement, containerElement);
      }

      if (this.props.tabKey === 'make') {
        if (activeElement) {
          (activeElement as HTMLElement).focus();
        }
        else {
          const first = element.querySelector('.make-list__item:first-of-type');
          if (first) {
            (first as HTMLElement).focus();
          }
        }
        this.props.setTabKey('');
      }
    }
  }

  loadMakes() {
    if (this.props.year !== 0) {
      this.setState({
        loading: true
      });

      if (this.state.showAllMakes) {
        axios.get(getBaseUrl() + '/VehicleInfo/Makes?year=' + this.props.year)
          .then((response) => {
            this.setState({
              loading: false,
              makes: response.data
            });
          })
          .catch((error: AxiosError) => {
            errorResponseHandler(error);
          });
      }
      else {
        axios.get(getBaseUrl() + '/VehicleInfo/PopularMakes?year=' + this.props.year)
          .then((response) => {
            this.setState({
              loading: false,
              popularMakes: response.data
            });

            // if the current make is not in the popular list, show the full list
            if (this.props.make !== '' && response.data.map((m: Make) => m.makeDesc).indexOf(this.props.make) === -1) {
              this.setState({
                showAllMakes: true
              });
            }
          })
          .catch((error: AxiosError) => {
            errorResponseHandler(error);
          });
      }
    }
  }

  toggleShowAllMakes() {
    this.setState({
      showAllMakes: !this.state.showAllMakes
    });
  }

  OnKeyDownNavigateMobile = (e: React.KeyboardEvent<HTMLElement>) => {
    let element: HTMLElement | null = null;

    switch (e.which) {
      case 9: // tab
        e.preventDefault();
        if (e.shiftKey) {
          let header: Element | null;
          header = document.querySelector('#agencyHeader') as Element;
          if (header) {
            (header as HTMLElement).focus();
          }
        }
        else {
          let continueButton: Element | null;
          continueButton = document.querySelector('#continueButton') as Element;
          if (continueButton) {
            (continueButton as HTMLElement).focus();
          }
        }
        break;
      case 13: // enter
      case 32: // spacebar
        e.preventDefault();
        element = e.currentTarget as HTMLElement;
        if (element) {
          element!.click();
        }
        break;
      case 37: // left arrow
        e.preventDefault();
        try {
          element = e.currentTarget.parentElement!.previousElementSibling!.firstElementChild! as HTMLElement;
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
      case 38: // up arrow
        e.preventDefault();
        try {
          // go up 3 at a time to prev row
          element = e.currentTarget.parentElement!.previousElementSibling!.previousElementSibling!.previousElementSibling!.firstElementChild! as HTMLElement;
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
      case 39: //right arrow
        e.preventDefault();
        try {
          element = e.currentTarget.parentElement!.nextElementSibling!.firstElementChild! as HTMLElement;
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
      case 40: // down arrow
        e.preventDefault();
        try {
          // go down 3 at a time to next row
          element = e.currentTarget.parentElement!.nextElementSibling!.nextElementSibling!.nextElementSibling!.firstElementChild! as HTMLElement;
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
    }
  }

  /* Mobile view (xs and sm} */
  MobileShortList = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    if (isMobile && !this.state.showAllMakes && this.state.popularMakes.length > 0) {
      return (
        <Row className="no-gutters">
          {
            this.state.popularMakes.map((make: Make, index: number) => {
              let className = classNames(
                "px-0", "px-sm-auto", "py-1", "py-sm-2", "list-group-item", "w-100", "make-list__item", {
                active: make.makeDesc === this.props.make
              });
              return (
                <Col xs={4} key={index}>
                  <button type='button' onClick={() => this.props.onClick(make.makeDesc)} className={className} onKeyDown={this.OnKeyDownNavigateMobile}>
                    <img src={getBaseUrl() + '/images/VehicleMakes/' + make.shortName.toLowerCase() + '.png'} alt={make.makeDesc} className="make-list__image" />
                    <div className="make-list__display-name" >{make.shortName}</div>
                  </button>
                </Col>
              );
            })
          }
        </Row>
      )
    }
    else {
      return null;
    }
  }

  OnKeyDownNavigate = (e: React.KeyboardEvent<HTMLElement>) => {
    let element: HTMLElement | null = null;

    switch (e.which) {
      case 9: // tab
        e.preventDefault();
        if (e.shiftKey) {
          this.props.setTabKey('year');
        }
        else {
          this.props.setTabKey('model');
        }
        break;
      case 13: // enter
      case 32: // spacebar
        e.preventDefault();
        element = e.currentTarget as HTMLElement;
        if (element) {
          element!.click();
        }
        break;
      case 38: // up arrow
        e.preventDefault();
        element = e.currentTarget.previousElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
      case 40: // down arrow
        e.preventDefault();
        element = e.currentTarget.nextElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
    }
  }

  /* Tablet/Desktop view (md and larger} */
  DesktopShortList = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    if (isDesktop && !this.state.showAllMakes && this.state.popularMakes.length > 0) {
      return (
        <ListGroup flush className="border-bottom">
          {
            this.state.popularMakes.map((make: Make, index: number) => {
              return (
                <ListGroupItem tag="button" type="button" action active={make.makeDesc === this.props.make} className="pr-0 make-list__item"
                  onKeyDown={this.OnKeyDownNavigate} key={index} onClick={() => this.props.onClick(make.makeDesc)}>
                  <img src={getBaseUrl() + '/images/VehicleMakes/' + make.shortName.toLowerCase() + '.png'} alt={make.makeDesc} className="make-list__image" />
                  <div className="make-list__display-name" >{make.makeDesc}</div>
                </ListGroupItem>
              )
            })
          }
        </ListGroup>
      )
    }
    else {
      return null;
    }
  }

  /* show all makes (all devices) */
  AllMakesList = () => {
    if (this.state.showAllMakes && this.state.makes.length > 0) {
      return (
        <ListGroup flush className="border-bottom">
          {
            this.state.makes.map((make: string, index: number) => {
              return (
                <ListGroupItem tag="button" type="button" action active={make === this.props.make} className='make-list__item'
                  onKeyDown={this.OnKeyDownNavigate} key={index} onClick={() => this.props.onClick(make)}>
                  {make}
                </ListGroupItem>
              )
            })
          }
        </ListGroup>
      )
    }
    else {
      return null;
    }
  }

  getValidationObject = (): any => {
    return {
      year: this.props.year,
      make: this.props.make
    };
  };

  render() {
    if (!this.props.visible || this.props.year === 0) {
      return null;
    }

    const { getErrorText, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Vehicles);
    //show the field required message if this field is invalid
    const isMakeInvalid = isFieldInvalid(validationConfig.vehicles.make, this.props.currentIndex);
    //show the feedback placeholder if any field is invalid to keep things aligned
    const shouldShowFeedback = isFieldInvalid(validationConfig.vehicles.year, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.make, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.model, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.vin, this.props.currentIndex);

    return (
      <div ref={this.elementRef}>
        <Label className="mt-2 mb-0">{validationConfig.vehicles.make.display}:</Label>
        <FormFeedback className={classNames("my-0", {
          'vehicle-page__list-feedback': isMakeInvalid,
          'd-block': shouldShowFeedback
        })}>
          {getErrorText(validationConfig.vehicles.make, this.props.currentIndex) || <span>&nbsp;</span>}
        </FormFeedback>
        <div className="mt-2 vehicle-page__container vehicle-page__with-link">
          {
            this.state.loading &&
            <div className="vehicle-page__list border bg-white mb-3 mb-md-0">
              <Loader />
            </div>
          }
          {
            !this.state.loading &&
            <Fragment>
              <div className={classNames("vehicle-page__list border rounded bg-white", { 'is-invalid': isMakeInvalid })}>
                <this.MobileShortList />
                <this.DesktopShortList />
                <this.AllMakesList />
              </div>

              <Button tabIndex={-1} color="link" className="align-bottom pt-2" onClick={this.toggleShowAllMakes}>
                {this.state.showAllMakes
                  ? "Show Popular Makes"
                  : "Show All Makes"
                }
              </Button>
            </Fragment>
          }
        </div>
      </div>
    )
  }
}

export default validation(MakeList);
