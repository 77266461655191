import { QuestionOptions, initialState } from './state';
import { Actions as QuestionOptionsActions, Action as QuestionOptionsAction } from './actions';
import { Action, Reducer } from 'redux';

export const reducer: Reducer<QuestionOptions> = (state: QuestionOptions = initialState, incomingAction: Action): QuestionOptions => {
  const action = incomingAction as QuestionOptionsAction;

  switch (action.type) {
    case QuestionOptionsActions.REQUEST_QUESTION_OPTIONS:
      return {
        ...state,
        isLoading: true,
        agencyId: action.agencyId,
        lineOfBusiness: action.lineOfBusiness,
        state: action.state,
        options: {}
      };
    case QuestionOptionsActions.RECEIVE_QUESTION_OPTIONS:
      return {
        ...state,
        options: action.questionOptions,
        isLoading: false
      };
  }

  return state;
};
