import { AppThunkAction } from "..";
import { homeownersType, Driver, Coverages, HomeCharacteristics, RateStatus } from "./state";
import { Vehicle } from "./Vehicle/vehicle";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum Actions {
  START_QUOTE = 'START_QUOTE',
  ADD_DRIVER = 'ADD_DRIVER',
  UPDATE_DRIVER = 'UPDATE_DRIVER',
  REMOVE_DRIVER = 'REMOVE_DRIVER',
  ADD_VEHICLE = 'ADD_VEHICLE',
  UPDATE_VEHICLE = 'UPDATE_VEHICLE',
  REMOVE_VEHICLE = 'REMOVE_VEHICLE',
  UPDATE_HOME_CHARACTERISTICS = 'UPDATE_HOME_CHARACTERISTICS',
  UPDATE_COVERAGES = 'UPDATE_COVERAGES',
  UPDATE_RATING = 'UPDATE_RATING'
}

export interface StartQuote {
  type: Actions.START_QUOTE;
  isPersonalAuto: boolean;
  homeowners: homeownersType;
  fullAddress: string;
  addressState: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zip: string;
  addressVerified: boolean;
  ref1: string;
  ref2: string;
  ref3: string;
  ref4: string;
  ref5: string;
  agencyLicense: string;
}

export interface AddDriver {
  type: Actions.ADD_DRIVER;
}

export interface UpdateDriver {
  type: Actions.UPDATE_DRIVER;
  driver: Driver;
}

export interface RemoveDriver {
  type: Actions.REMOVE_DRIVER;
  index: number;
}

export interface AddVehicle {
  type: Actions.ADD_VEHICLE;
}

export interface UpdateVehicle {
  type: Actions.UPDATE_VEHICLE;
  vehicle: Vehicle;
}

export interface RemoveVehicle {
  type: Actions.REMOVE_VEHICLE;
  index: number;
}

export interface UpdateHomeCharacteristics {
  type: Actions.UPDATE_HOME_CHARACTERISTICS;
  homeCharacteristics: HomeCharacteristics;
}

export interface UpdateCoverages {
  type: Actions.UPDATE_COVERAGES;
  coverages: Coverages;
}

export interface UpdateRating {
  type: Actions.UPDATE_RATING;
  correlationId: string | null;
  rateStatus: RateStatus;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

export type Action = StartQuote
  | AddDriver
  | UpdateDriver
  | RemoveDriver
  | AddVehicle
  | UpdateVehicle
  | RemoveVehicle
  | UpdateHomeCharacteristics
  | UpdateCoverages
  | UpdateRating

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

var startQuote = (isPersonalAuto: boolean, homeowners: homeownersType, fullAddress: string, addressState: string, addressLine1: string,
  addressLine2: string, city: string, zip: string, addressVerified: boolean, agencyLicense: string): AppThunkAction<StartQuote> => (dispatch, getState) => {
    const appState = getState();

    dispatch({
      type: Actions.START_QUOTE,
      isPersonalAuto: isPersonalAuto,
      homeowners: homeowners,
      fullAddress: fullAddress,
      addressState: addressState,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      zip: zip,
      addressVerified: addressVerified,

      ref1: appState.startupParameters.ref1 || '',
      ref2: appState.startupParameters.ref2 || '',
      ref3: appState.startupParameters.ref3 || '',
      ref4: appState.startupParameters.ref4 || '',
      ref5: appState.startupParameters.ref5 || '',
      agencyLicense: agencyLicense
    });

  };

const addDriver = (): AddDriver => ({
  type: Actions.ADD_DRIVER
});

const updateDriver = (driver: Driver): UpdateDriver => ({
  type: Actions.UPDATE_DRIVER,
  driver
});

const removeDriver = (index: number): RemoveDriver => ({
  type: Actions.REMOVE_DRIVER,
  index
});

const addVehicle = (): AddVehicle => ({
  type: Actions.ADD_VEHICLE
});

const updateVehicle = (vehicle: Vehicle): UpdateVehicle => ({
  type: Actions.UPDATE_VEHICLE,
  vehicle
});

const removeVehicle = (index: number): RemoveVehicle => ({
  type: Actions.REMOVE_VEHICLE,
  index
});

const updateHomeCharacteristics = (homeCharacteristics: HomeCharacteristics): UpdateHomeCharacteristics => ({
  type: Actions.UPDATE_HOME_CHARACTERISTICS,
  homeCharacteristics
});

const updateCoverages = (coverages: Coverages): UpdateCoverages => ({
  type: Actions.UPDATE_COVERAGES,
  coverages
});

const updateRating = (correlationId: string | null, rateStatus: RateStatus): UpdateRating => ({
  type: Actions.UPDATE_RATING,
  correlationId,
  rateStatus
});

export const actionCreators = {
  startQuote,
  addDriver,
  updateDriver,
  removeDriver,
  addVehicle,
  updateVehicle,
  removeVehicle,
  updateHomeCharacteristics,
  updateCoverages,
  updateRating
};
