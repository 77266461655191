import React from 'react';
import axios, { AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling'
import { getBaseUrl, scrollIntoViewIfNeeded } from '../../services/helper';
import { Row, Col, ListGroupItem, ListGroup } from 'reactstrap';
import Loader from '../Loader';
import { Carrier } from '../../models/carrier';
import '../../custom.scss';
import './CoveragePage.scss';
import './PopularPriorCarriers.scss';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { LineOfBusinessType } from '../../store/Quote/state';

type OwnProps = {
  carrier: string,
  onClick: (carrier: string) => void,
  tabKey: string,
  setTabKey: (key: string) => void,
  isDesktop: boolean
};

type PopularPriorCarriersProps =
  OwnProps &
  ReturnType<typeof mapStateToProps>;

interface PopularPriorCarriersState {
  loading: boolean,
  carriers: string[],
  popularCarriers: Carrier[]
}

class PopularPriorCarriers extends React.PureComponent<PopularPriorCarriersProps, PopularPriorCarriersState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      carriers: [],
      popularCarriers: []
    };
  }

  elementRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.loadCarriers();
  }

  componentDidUpdate(prevProps: PopularPriorCarriersProps, prevState: PopularPriorCarriersState) {
    let element: Element | null;
    element = this.elementRef.current;
    if (element) {
      let activeElement = element.querySelector('.active');
      let containerElement = element.querySelector('.coverage-page__list');
      if (activeElement && containerElement) {
        scrollIntoViewIfNeeded(activeElement, containerElement);
      }

      if (this.props.tabKey === 'carrier') {
        let temp: HTMLElement | null;

        if (activeElement) {
          temp = activeElement as HTMLElement;
          // for some reason this component needs nextElementSibling to work
          if (this.props.isDesktop) {
            temp = temp.nextElementSibling! as HTMLElement;
          }
          else {
            temp = temp.parentElement!.nextElementSibling!.firstElementChild! as HTMLElement;
          }
          temp.focus();
        }
        else {
          const first = element.querySelector('.carrier-list__item:first-of-type');
          if (first) {
            temp = first as HTMLElement;
            // for some reason this component needs nextElementSibling to work
            if (this.props.isDesktop) {
              temp = temp.nextElementSibling! as HTMLElement;
            }
            else {
              temp = temp.parentElement!.nextElementSibling!.firstElementChild! as HTMLElement;
            }
            temp.focus();
          }
        }
        this.props.setTabKey('');
      }
    }
  }

  loadCarriers() {
    this.setState({
      loading: true
    });

    let carrierLob: string;
    if (this.props.lineOfBusiness === LineOfBusinessType.Package) {
      carrierLob = 'PA';
    }
    else {
      carrierLob = this.props.lineOfBusiness;
    }

    if (carrierLob !== LineOfBusinessType.NotSet) {
      // make api call for popular carriers for the line of business
      axios.get(getBaseUrl() + '/priorCarriers/popular/' + carrierLob + '/' + this.props.addressState)
        .then((response) => {
          this.setState({
            loading: false,
            popularCarriers: response.data
          });
        })
        .catch((error: AxiosError) => {
          errorResponseHandler(error);
        });
    }
  }

  OnKeyDownNavigateMobile = (e: React.KeyboardEvent<HTMLElement>) => {
    let element: HTMLElement | null = null;

    switch (e.which) {
      case 9: // tab
        if (!e.shiftKey) {
          e.preventDefault();
          let nextElement: Element | null;

          nextElement = document.querySelector('.coverage-page__curCovLevel') as Element;
          if (nextElement === null) {
            nextElement = document.querySelector('.coverage-page__pkgPriorCarriersDiff:first-of-type') as Element;
            if (nextElement === null) {
              nextElement = document.querySelector('#curEstPrem') as Element;
              if (nextElement === null) {
                nextElement = document.querySelector('#continueButton') as Element;
              }
            }
          }

          if (nextElement) {
            (nextElement as HTMLElement).focus();
          }
        }
        break;
      case 13: // enter
      case 32: // spacebar
        e.preventDefault();
        element = e.currentTarget as HTMLElement;
        if (element) {
          element!.click();
        }
        break;
      case 37: // left arrow
        e.preventDefault();
        try {
          element = e.currentTarget.parentElement!.previousElementSibling!.firstElementChild! as HTMLElement;
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
      case 38: // up arrow
        e.preventDefault();

        try {
          const carrierNumber = Number(e.currentTarget.dataset.carriernumber);
          const isNoneButton = carrierNumber === 100;

          if (isNoneButton) {
            // go up 2 at a time to prev row
            // NONE to column 3
            element = e.currentTarget.parentElement!.previousElementSibling!.previousElementSibling!.firstElementChild! as HTMLElement;
          }
          else {
            // go up 3 at a time to prev row
            // OTHER to column 1
            element = e.currentTarget.parentElement!.previousElementSibling!.previousElementSibling!.previousElementSibling!.firstElementChild! as HTMLElement;
          }
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
      case 39: //right arrow
        e.preventDefault();
        try {
          element = e.currentTarget.parentElement!.nextElementSibling!.firstElementChild! as HTMLElement;
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
      case 40: // down arrow
        e.preventDefault();

        try {
          let isMove2 = false;
          const carrierNumber = Number(e.currentTarget.dataset.carriernumber);
          if (carrierNumber > 12) {
            isMove2 = carrierNumber % 3 === 2 || carrierNumber % 3 === 0;
          }

          if (isMove2) {
            // go down 2 at a time to next row
            // column 2 to OTHER, column 3 to NONE
            element = e.currentTarget.parentElement!.nextElementSibling!.nextElementSibling!.firstElementChild! as HTMLElement;
          }
          else {
            // go down 3 at a time to next row
            element = e.currentTarget.parentElement!.nextElementSibling!.nextElementSibling!.nextElementSibling!.firstElementChild! as HTMLElement;
          }
        } catch (e) {
          element = null;
        }
        if (element) {
          element.focus();
        }
        break;
    }
  }

  /* Mobile view (xs and sm} */
  MobileShortList = () => {
    const isOther = this.props.carrier === 'Other' ? 'active' : '';
    const isNone = this.props.carrier === 'None' ? 'active' : '';

    if (!this.props.isDesktop) {
      return (
        <Row className='no-gutters'>
          {
            this.state.popularCarriers.map((carrier: Carrier, index: number) => {
              let className = classNames(
                "px-0", "px-sm-auto", "py-1", "py-sm-2", "list-group-item", "w-100", "carrier-list__item", {
                active: carrier.carrierDesc === this.props.carrier
              });
              return (
                <Col xs={4} key={index}>
                  <button type='button' onClick={() => this.props.onClick(carrier.carrierDesc)} className={className}
                    onKeyDown={this.OnKeyDownNavigateMobile}
                    data-carriernumber={index + 1}
                    autoFocus={(this.props.carrier === '' && index === 0) || (this.props.carrier !== '' && carrier.carrierDesc === this.props.carrier)} >
                    <img src={getBaseUrl() + '/images/PriorCarrierImages/' + carrier.imgName} alt={carrier.carrierDesc} className="carrier-list__image" />
                    <div className="carrier-list__display-name" >{carrier.shortName.toUpperCase()}</div>
                  </button>
                </Col>
              );
            })
          }

          <Col xs={6}>
            <button type='button' onClick={() => this.props.onClick('Other')}
              onKeyDown={this.OnKeyDownNavigateMobile}
              data-carriernumber={99}
              className={"px-0 px-sm-auto py-1 py-sm-2 list-group-item w-100 carrier-list__item " + isOther}>
              <div className="carrier-list__display-name my-3" style={{ fontSize: '1em' }}>OTHER</div>
            </button>
          </Col>
          <Col xs={6}>
            <button type='button' onClick={() => this.props.onClick('None')}
              onKeyDown={this.OnKeyDownNavigateMobile}
              data-carriernumber={100}
              className={"px-0 px-sm-auto py-1 py-sm-2 list-group-item w-100 carrier-list__item " + isNone}>
              <div className="carrier-list__display-name my-3" style={{ fontSize: '1em' }}>NONE</div>
            </button>
          </Col>
        </Row>
      )
    }
    else {
      return null;
    }
  }

  OnKeyDownNavigate = (e: React.KeyboardEvent<HTMLElement>) => {
    let element: HTMLElement | null = null;

    switch (e.which) {
      case 9: // tab
        if (!e.shiftKey) {
          e.preventDefault();
          let nextElement: Element | null;

          nextElement = document.querySelector('.coverage-page__curCovLevel') as Element;
          if (nextElement === null) {
            nextElement = document.querySelector('.coverage-page__pkgPriorCarriersDiff:first-of-type') as Element;
            if (nextElement === null) {
              nextElement = document.querySelector('#curEstPrem') as Element;
              if (nextElement === null) {
                nextElement = document.querySelector('#continueButton') as Element;
              }
            }
          }

          if (nextElement) {
            (nextElement as HTMLElement).focus();
          }
        }
        break;
      case 13: // enter
      case 32: // spacebar
        e.preventDefault();
        element = e.currentTarget as HTMLElement;
        if (element) {
          element!.click();
        }
        break;
      case 38: // up arrow
        e.preventDefault();
        element = e.currentTarget.previousElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
      case 40: // down arrow
        e.preventDefault();
        element = e.currentTarget.nextElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
    }
  }

  /* Tablet/Desktop view (md and larger} */
  DesktopShortList = () => {
    if (this.props.isDesktop) {
      return (
        <div className='coverage-page__list border rounded'>
          <ListGroup flush>
            {
              this.state.popularCarriers.map((carrier: Carrier, index: number) => {
                return (
                  <ListGroupItem type='button' tag='button' action active={carrier.carrierDesc === this.props.carrier} key={index} onClick={() => this.props.onClick(carrier.carrierDesc)} style={{ height: '58px' }}
                    autoFocus={(this.props.carrier === '' && index === 0) || (this.props.carrier !== '' && carrier.carrierDesc === this.props.carrier)}
                    onKeyDown={this.OnKeyDownNavigate} className='carrier-list__item'>
                    <img src={getBaseUrl() + '/images/PriorCarrierImages/' + carrier.imgName} alt={carrier.carrierDesc} className='carrier-list__image' />
                    <div className='carrier-list__display-name'>{carrier.shortName.toUpperCase()}</div>
                  </ListGroupItem>
                )
              })
            }
            <ListGroupItem type='button' tag='button' action active={'Other' === this.props.carrier} style={{ height: '58px' }}
              onClick={() => this.props.onClick('Other')} onKeyDown={this.OnKeyDownNavigate} className='carrier-list__item'>
              OTHER
          </ListGroupItem>
            <ListGroupItem type='button' tag='button' action active={'None' === this.props.carrier} style={{ height: '58px' }}
              onClick={() => this.props.onClick('None')} onKeyDown={this.OnKeyDownNavigate} className='carrier-list__item'>
              NONE
          </ListGroupItem>
          </ListGroup>
        </div>
      )
    }
    else {
      return null;
    }
  }

  render() {
    return (
      <div ref={this.elementRef}>
        {
          this.state.loading &&
          <Loader />
        }
        {
          !this.state.loading &&
          <div>
            <this.MobileShortList />
            <this.DesktopShortList />
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  lineOfBusiness: state.quote.lineOfBusiness,
  addressState: state.quote.addressState
});

export default connect(mapStateToProps)(PopularPriorCarriers);
