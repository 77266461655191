import { ErrorInfo } from "./state";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum Actions {
  UPDATE_ERRORS_VISIBILE = 'UPDATE_ERRORS_VISIBILE',
  UPDATE_ERRORS = 'UPDATE_ERRORS'
}

export interface UpdateErrorsVisible {
  type: Actions.UPDATE_ERRORS_VISIBILE,
  errorsVisible: boolean
}

export interface UpdateErrors {
  type: Actions.UPDATE_ERRORS,
  errors: ErrorInfo[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

export type Action = UpdateErrorsVisible
  | UpdateErrors

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const updateErrorsVisible = (errorsVisible: boolean): UpdateErrorsVisible => ({
  type: Actions.UPDATE_ERRORS_VISIBILE,
  errorsVisible
});

const updateErrors = (errors: ErrorInfo[]): UpdateErrors => ({
  type: Actions.UPDATE_ERRORS,
  errors
});

export const actionCreators = {
  updateErrorsVisible,
  updateErrors
};
