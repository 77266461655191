// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export const initialState: StartupParameters = {
  agencyId: 0,
  ref1: '',
  ref2: '',
  ref3: '',
  ref4: '',
  ref5: '',
};

export interface StartupParameters {
  agencyId: number;
  ref1: string;
  ref2: string;
  ref3: string;
  ref4: string;
  ref5: string;
}