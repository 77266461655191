import React from 'react';
import { FaSpinner } from 'react-icons/fa';

import './Loader.scss';

class LoaderResults extends React.PureComponent<{isMobile: boolean}, {}> {
  public render() {
    if (this.props.isMobile) {
      return (
        <div className="justify-content-center align-items-center">
          <FaSpinner className="spin mb-2" size={16} />
          <span className='ml-2 text-nowrap' style={{ fontSize: '1rem' }}>Getting Rates...</span>
        </div>
      );
    }

    return (
      <div className="justify-content-center align-items-center">
        <FaSpinner className="spin mb-3" size={32}/> 
        <span className='ml-2 text-nowrap' style={{fontSize: '2rem'}}>Getting Rates...</span>
      </div>
    );
  }
}

export default LoaderResults;