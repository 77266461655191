import React, { Fragment } from 'react';
import { Row, Col, Label, Input, Form, FormGroup, FormFeedback, InputGroup, ButtonGroup } from 'reactstrap';
import axios, { AxiosError } from 'axios';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../store';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { Driver, Incident, IncidentType, AccidentType } from '../../store/Quote/state';
import { QuestionOption } from '../../store/QuestionOptions/state';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import AddButton from '../AddButton';
import Swipeable from '../Swipeable';
import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import './DriverPage.scss';
import { GoTrashcan } from 'react-icons/go';
import { hasLob, getCurrentDate, getBaseUrl } from '../../services/helper';
import classNames from 'classnames';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import errorResponseHandler from '../../services/errorHandling';
import { isNil } from 'lodash';

type OwnProps = {
  currentIndex: number,
  onPreviousClick?: () => void,
  onNextClick?: () => void,
  onAddClick?: () => void,
}

type DriverPageIncidentsProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface DriverPageIncidentsState {
  incidents: Incident[],
  lossTypes: string[],
  isIE: boolean | null
}

class DriverPageIncidents extends React.PureComponent<DriverPageIncidentsProps, DriverPageIncidentsState> {
  constructor(props: any) {
    super(props);

    let currentDriver = this.props.drivers[this.props.currentIndex];

    if (!currentDriver) {
      throw new Error('driver was not found');
    }

    this.state = {
      incidents: currentDriver.incidents.length < 1 ? [new Incident()] : currentDriver.incidents,
      lossTypes: [],
      isIE: null
    }

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.addClickHandler = this.addClickHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleIncidentChange = this.handleIncidentChange.bind(this);
    this.handleAccidentChange = this.handleAccidentChange.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.renderIncident = this.renderIncident.bind(this);
    this.renderAccidentIncident = this.renderAccidentIncident.bind(this);
    this.renderViolationIncident = this.renderViolationIncident.bind(this);
    this.renderLossIncident = this.renderLossIncident.bind(this);
    this.buildOptions = this.buildOptions.bind(this);
    this.getLossTypeOptions = this.getLossTypeOptions.bind(this);
    this.onAddIncident = this.onAddIncident.bind(this);
    this.onRemoveIncident = this.onRemoveIncident.bind(this);
    this.isValid = this.isValid.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);

    this.props.setIsValidFunc(this.isValid);
  }

  getValidationObject = (index: number): any => {
    return {
      incidentType: this.state.incidents[index].incidentType,
      incidentDate: this.state.incidents[index].incidentDate,
      accidentType: this.state.incidents[index].accidentType,
      violationType: this.state.incidents[index].violationType,
      actualSpeed: this.state.incidents[index].actualSpeed,
      postedLimit: this.state.incidents[index].postedLimit,
      lossType: this.state.incidents[index].lossType,
      lossAmount: this.state.incidents[index].lossAmount,
    };
  };

  getValidationObjectList = (): any => {
    let list: any = []

    for (var i = 0; i < this.state.incidents.length; i++) {
      list.push(this.getValidationObject(i));
    }

    return list;
  }


  componentWillUnmount() {
    this.updateDriver();
  }

  componentDidMount() {
    if (this.state.isIE === null) {
      this.setState({ isIE: window.navigator.userAgent.indexOf("MSIE ") > 0 || navigator.appVersion.indexOf('Trident/') > -1 })
    }
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);

    if (this.state.lossTypes.length < 1) {
      this.getLossTypeOptions();
    }
  }

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  addClickHandler() {
    if (this.props.onAddClick) {
      this.props.onAddClick();
    }
  }

  updateDriver() {
    let currentDriver = this.props.drivers[this.props.currentIndex];

    if (currentDriver) {
      this.props.updateDriver({
        ...currentDriver,
        incidents: this.state.incidents
      });
    }
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObjectList(), validationLevel.Incidents).isPageValid(showErrors);
  }

  handleIncidentChange(event: { target: { name: string; value: string; }; }, index: number) {
    let updatedIncidents: Incident[] = [...this.state.incidents];
    let updatedIncident: Incident = { ...updatedIncidents[index] };

    updatedIncident.incidentType = event.target.value as IncidentType;
    updatedIncident.accidentType = AccidentType.None;
    updatedIncident.bodilyInjuryAmount = 0;
    updatedIncident.propertyDamageAmount = 0;
    updatedIncident.violationType = '';
    updatedIncident.actualSpeed = null;
    updatedIncident.postedLimit = null;
    updatedIncident.lossType = '';
    updatedIncident.lossAmount = null;
    updatedIncident.deathOccurred = null;

    updatedIncidents[index] = updatedIncident;
    this.setState({
      incidents:  updatedIncidents
    })
  }

  handleAccidentChange(event: { target: { name: string; value: string; }; }, index: number) {
    let updatedIncidents: Incident[] = [...this.state.incidents];
    let updatedIncident: Incident = { ...updatedIncidents[index] };
    updatedIncident.accidentType = event.target.value as AccidentType;
    updatedIncidents[index] = updatedIncident;

    if (updatedIncident.accidentType === AccidentType.AtFaultWithNoInjury) {
      updatedIncident.deathOccurred = null;
    }

    this.setState({
      incidents: updatedIncidents
    });
  }

  handleDeathOccurredChange(event: { target: HTMLInputElement; }, index: number) {
    let deathOccurred = event.target.value.toLowerCase() === 'true';
    let updatedIncidents: Incident[] = [...this.state.incidents];
    let updatedIncident: Incident = { ...updatedIncidents[index] };
    updatedIncident.deathOccurred = deathOccurred;
    updatedIncidents[index] = updatedIncident;

    this.setState({
      incidents: updatedIncidents
    })
  }

  handleChange(event: { target: { name: string; value: string; maxLength?: string |number }; }, index: number) {
    let { name, value, maxLength } = event.target;
    let saveValue: string | number | boolean | null;

    if (!isNil(maxLength) && maxLength > -1) {
      const max = Number(maxLength);
      if (value.length > max) value = value.substring(0, max);
    }

    // I wanted to be able to determine the type of the state object property at runtime
    // but I could not find a way to do that when some property values can initially be null.
    switch (name) {
      case 'bodilyInjuryAmount':
      case 'propertyDamageAmount':
      case 'actualSpeed':
      case 'postedLimit':
      case 'lossAmount':
        if (value === '' || value === null) {
          saveValue = null;
        }
        else {
          value = value.replace(/\D/g, ''); //remove unwanted characters from copy & paste
          saveValue = Number(value);
        }
        break;
      default:
        saveValue = value;
    }

    let updatedIncidents: Incident[] = [...this.state.incidents];
    let updatedIncident: Incident = { ...updatedIncidents[index] };
    updatedIncident[name] = saveValue;
    updatedIncidents[index] = updatedIncident;

    if (name === 'violationType' && updatedIncident.violationType !== 'Speeding') {
      updatedIncident.actualSpeed = null;
      updatedIncident.postedLimit = null;
    }

    this.setState({
      incidents: updatedIncidents
    })
  }

  handleDate(event: { target: { name: string; value: string; }; }, index: number) {
    const { name } = event.target;

    //fallback for IE 10 and other browsers that might not support date as an input type
    if ((document.getElementsByName(name)[index]! as HTMLInputElement).type === 'text') {
      let dateDisplay = event.target.value;
      if (this.state.incidents[index].incidentDate.length < dateDisplay.length) {
        dateDisplay = dateDisplay.replace(/[^0-9]/g, '').slice(0, 8);
        if (dateDisplay.length >= 5) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2, 4) + '/' + dateDisplay.slice(4);
        }
        else if (dateDisplay.length >= 3) {
          dateDisplay = dateDisplay.slice(0, 2) + '/' + dateDisplay.slice(2);
        }
        if (dateDisplay.length === 2 || dateDisplay.length === 5) {
          dateDisplay = dateDisplay + '/';
        }
      }
      event.target.value = dateDisplay;
    }

    let updatedIncidents: Incident[] = [...this.state.incidents];
    let updatedIncident: Incident = { ...updatedIncidents[index] };
    updatedIncident.incidentDate = event.target.value;
    updatedIncidents[index] = updatedIncident;

    this.setState({
      incidents: updatedIncidents
    })
  }

  onRemoveIncident(index: number) {
    let incidents: Incident[] = [...this.state.incidents];
    incidents.splice(index, 1);
    this.setState({
      incidents
    });
  }

  onAddIncident() {
    let incidents: Incident[] = [...this.state.incidents];
    incidents.push(new Incident());
    this.setState({
      incidents
    });
  }

  getLossTypeOptions() {
    let lossTypes: string[] = [];
    axios.get(getBaseUrl() + '/getLossTypeOptions/' + this.props.quote.addressState)
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          lossTypes[i] = response.data[i].optionText
        }
        this.setState({
          lossTypes: lossTypes
        });
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }

  buildOptions(optionsArray: string[]) {
    var optionsJSX = [];
    for (var i = 0; i < optionsArray.length; i++) {
      if (optionsArray[i] !== '<select>')
      optionsJSX.push(<option key={optionsArray[i]} value={optionsArray[i]}>{optionsArray[i]}</option>)
    }
    return optionsJSX;
  }

  onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    // don't allow decimal or enter (enter causes form to submit on this control and puts form data in url and cause site not found page)
    (e.key === '.' || e.which === 13) && e.preventDefault()
  };

  renderIncident(index: number) {
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(index), validationLevel.Incidents);
    const incidentInvalid = isFieldInvalid(validationConfig.incidents.incidentType, index);

    return (
      <Fragment key={index}>
        <Row>
          <Col className='text-left'>
            <h5>
              Incident #{index + 1}
            </h5>
          </Col>
          <Col className='text-right'>
            <Label className={classNames('btn border border-dark', { 'd-none': this.state.incidents.length === 1 })}>
              <GoTrashcan size='20' />
              <input className='d-none' type='button' onClick={() => this.onRemoveIncident(index)}></input>
            </Label>
          </Col>
        </Row>
        <FormGroup>
          <Label>{validationConfig.incidents.incidentType.display}:</Label>
          <div>
            <ButtonGroup className={classNames('btn-group-toggle driver-page__incidentType ', { 'is-invalid': incidentInvalid, 'border border-0 bg-light': !incidentInvalid })}>
              <label className={'btn btn-option ' + (this.state.incidents[index].incidentType === IncidentType.Accident ? 'active' : '')}
                id='accidentLabel' data-toggle='buttons'>
                <div><input type="radio" name={validationConfig.incidents.incidentType.fieldName} id="accident" onChange={(e) => this.handleIncidentChange(e, index)}
                  value={IncidentType.Accident} />Accident</div>
              </label>
              <label className={'btn btn-option ' + (this.state.incidents[index].incidentType === IncidentType.Violation ? 'active' : '')}
                id='violationLabel' data-toggle='buttons'>
                <div><input type="radio" name={validationConfig.incidents.incidentType.fieldName} id="violation" onChange={(e) => this.handleIncidentChange(e, index)}
                  value={IncidentType.Violation} />Violation</div>
              </label>
              <label className={'btn btn-option ' + (this.state.incidents[index].incidentType === IncidentType.ComprehensiveLoss ? 'active' : '')}
                id='comprehensiveLossLabel' data-toggle='buttons'>
                <div><input type="radio" name={validationConfig.incidents.incidentType.fieldName} id="loss" onChange={(e) => this.handleIncidentChange(e, index)}
                  value={IncidentType.ComprehensiveLoss} />Comprehensive Loss</div>
              </label>
            </ButtonGroup>
            <FormFeedback>{getErrorText(validationConfig.incidents.incidentType, index)}</FormFeedback>
          </div>
        </FormGroup>

        <FormGroup>
          <ValidationLabel config={validationConfig.incidents.incidentDate} index={index} />
          <Input type='date'
            onChange={(e) => this.handleDate(e, index)}
            placeholder='mm/dd/yyyy'
            value={this.state.incidents[index].incidentDate}
            min='2000-01-01'
            max={getCurrentDate()}
            {...getFieldProps(validationConfig.incidents.incidentDate, undefined, index)}></Input>
          <FormFeedback>{getErrorText(validationConfig.incidents.incidentDate, index)}</FormFeedback>
        </FormGroup>

        {
          this.state.incidents[index].incidentType === IncidentType.Accident && this.renderAccidentIncident(index)
        }

        {
          this.state.incidents[index].incidentType === IncidentType.Violation && this.renderViolationIncident(index)
        }

        {
          this.state.incidents[index].incidentType === IncidentType.ComprehensiveLoss && this.renderLossIncident(index)
        }
      </Fragment>
    )
  }

  renderAccidentIncident(index: number) {
    const { getErrorText, isFieldInvalid } = this.props.validation(this.getValidationObject(index), validationLevel.Incidents);
    const accidentInvalid = isFieldInvalid(validationConfig.incidents.accidentType, index);  

    return (
      <Fragment>
        <Fragment>
          <FormGroup>
            <Label>{validationConfig.incidents.accidentType.display}:</Label>
            <div>
              <ButtonGroup className={classNames('btn-group-toggle text-nowrap driver-page__accidentType ', { 'is-invalid': accidentInvalid, 'border border-0 bg-light': !accidentInvalid })}>
                <label className={'btn btn-option ' + (this.state.incidents[index].accidentType === AccidentType.AtFaultWithInjury ? 'active' : '')}
                  id='aFWILabel' data-toggle='buttons'>
                  <div><input type="radio" name={validationConfig.incidents.accidentType.fieldName} id="accident" onChange={(e) => this.handleAccidentChange(e, index)}
                    value={IncidentType.Accident} />At Fault<br/>With Injury</div>
                </label>
                <label className={'btn btn-option ' + (this.state.incidents[index].accidentType === AccidentType.AtFaultWithNoInjury ? 'active' : '')}
                  id='aFWILabel' data-toggle='buttons'>
                  <div><input type="radio" name={validationConfig.incidents.accidentType.fieldName} id="violation" onChange={(e) => this.handleAccidentChange(e, index)}
                    value={IncidentType.Violation} />At Fault<br/>With No Injury</div>
                </label>
                <label className={'btn btn-option ' + (this.state.incidents[index].accidentType === AccidentType.NotAtFault ? 'active' : '')}
                  id='nAFLabel' data-toggle='buttons' style={{ width: '6.5rem' }}>
                  <div><input type="radio" name={validationConfig.incidents.accidentType.fieldName} id="loss" onChange={(e) => this.handleAccidentChange(e, index)}
                    value={IncidentType.ComprehensiveLoss} />Not At<br/>Fault</div>
                </label>
              </ButtonGroup>
              <FormFeedback>{getErrorText(validationConfig.incidents.accidentType, index)}</FormFeedback>
            </div>
          </FormGroup>

          <Row>
            <FormGroup>
              <Label className='pl-3'>Bodily Injury Loss Amount: <small>(optional)</small></Label>
              <InputGroup className='col-6 driver-page__dollar'>
                <div className='input-group-prepend'><span className='input-group-text'>$</span></div>
                <Input type={this.state.isIE ? 'text' : 'number'}
                  name='bodilyInjuryAmount'
                  value={this.state.incidents[index].bodilyInjuryAmount !== 0 ? this.state.incidents[index].bodilyInjuryAmount : ''}
                  maxLength={7}
                  pattern='\d*'
                  onChange={(e) => this.handleChange(e, index)}
                  {...{ onKeyPress: this.onKeyPress }}>
                </Input>
              </InputGroup>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup>
              <Label className='pl-3'>Property Damage Amount: <small>(optional)</small></Label>
              <InputGroup className='col-6 driver-page__dollar'>
                <div className='input-group-prepend'><span className='input-group-text'>$</span></div>
                <Input type={this.state.isIE ? 'text' : 'number'}
                  name='propertyDamageAmount'
                  value={this.state.incidents[index].propertyDamageAmount !== 0 ? this.state.incidents[index].propertyDamageAmount : ''}
                  maxLength={7}
                  pattern='\d*'
                  onChange={(e) => this.handleChange(e, index)}
                  {...{ onKeyPress: this.onKeyPress }}>
                </Input>
              </InputGroup>
            </FormGroup>
          </Row>
        </Fragment>

        {
          this.state.incidents[index].accidentType !== AccidentType.AtFaultWithNoInjury && this.state.incidents[index].accidentType !== AccidentType.None &&
          <FormGroup>
            <Label for='deathOccurred'>Death Occurred: <small>(optional)</small></Label>
            <div>
              <ButtonGroup className={classNames('btn-group-toggle')} >
                {
                  ([{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }] as QuestionOption[])
                    .map((option: QuestionOption, i: number) => (
                        <label className={classNames('btn btn-option', {active: option.value === this.state.incidents[index].deathOccurred  + ''})} data-toggle='buttons' key={i}>
                        <input type="radio" name='deathOccurred' onChange={(e) => this.handleDeathOccurredChange(e, index)} value={option.value} />
                        {option.text}
                      </label>
                    ))
                }
              </ButtonGroup>
            </div>
          </FormGroup>
        }
      </Fragment>
    )
  }

  renderViolationIncident(index: number) {
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(index), validationLevel.Incidents);

    return (
      <Fragment>
        <Row>
          <FormGroup>
            <ValidationLabel config={validationConfig.incidents.violationType} index={index} className='pl-3' />
            <select {...getFieldProps(validationConfig.incidents.violationType, 'custom-select ml-3 col-10', index)}
              onChange={(e) => this.handleChange(e, index)}
              value={this.state.incidents[index].violationType} name='violationType'>
              <option value=''>{'<select>'}</option>
              <option value='Careless Driving'>Careless Driving</option>
              <option value='Defective Equipment'>Defective Equipment</option>
              <option value='Driving on Suspended License'>Driving on Suspended License</option>
              <option value='DUI'>DUI</option>
              <option value='Failure to Obey Signal'>Failure to Obey Signal</option>
              <option value='Speeding'>Speeding</option>
              <option value='Other Major'>Other Major</option>
              <option value='Other Minor'>Other Minor</option>
              <option value='Not Listed'>Not Listed</option>
            </select>
            <FormFeedback className='pl-3'>{getErrorText(validationConfig.incidents.violationType, index)}</FormFeedback>
          </FormGroup>
        </Row>

        {
          this.state.incidents[index].violationType === 'Speeding' &&
          <Fragment>
            <FormGroup>
              <ValidationLabel config={validationConfig.incidents.actualSpeed} index={index} className='ml-0 pl-0' />
              <InputGroup className='col-6 ml-0 pl-0'>
                <Input type={this.state.isIE ? 'text' : 'number'}
                  {...getFieldProps(validationConfig.incidents.actualSpeed, undefined, index)}
                  value={this.state.incidents[index].actualSpeed}
                  maxLength={3}
                  pattern='\d*'
                  onChange={(e) => this.handleChange(e, index)}
                  {...{ onKeyPress: this.onKeyPress }}>
                </Input>
                <div className='input-group-append'><span className='input-group-text'>mph</span></div>
              </InputGroup>
              <FormFeedback className={classNames({ 'd-block': isFieldInvalid(validationConfig.incidents.actualSpeed, index) })}>{getErrorText(validationConfig.incidents.actualSpeed, index)}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <ValidationLabel config={validationConfig.incidents.postedLimit} index={index} className='ml-0 pl-0' />
              <InputGroup className='col-6 ml-0 pl-0'>
                <Input type={this.state.isIE ? 'text' : 'number'}
                  {...getFieldProps(validationConfig.incidents.postedLimit, undefined, index)}
                  value={this.state.incidents[index].postedLimit}
                  maxLength={3}
                  pattern='\d*'
                  onChange={(e) => this.handleChange(e, index)}
                  {...{ onKeyPress: this.onKeyPress }}>
                </Input>
                <div className='input-group-append'><span className='input-group-text'>mph</span></div>
              </InputGroup>
              <FormFeedback className={classNames({ 'd-block': isFieldInvalid(validationConfig.incidents.postedLimit, index) })}>{getErrorText(validationConfig.incidents.postedLimit, index)}</FormFeedback>
            </FormGroup>
          </Fragment>
        }
      </Fragment>
    )
  }

  renderLossIncident(index: number) {
    const { getErrorText, getFieldProps, isFieldInvalid } = this.props.validation(this.getValidationObject(index), validationLevel.Incidents);

    return (
      <Fragment>
        <Row>
          <FormGroup>
            <ValidationLabel config={validationConfig.incidents.lossType} index={index} className='pl-3' />
            <select {...getFieldProps(validationConfig.incidents.lossType, 'custom-select ml-3 col-10', index)}
              onChange={(e) => this.handleChange(e, index)}
              value={this.state.incidents[index].lossType} name='lossType'>
              <option value=''>{'<select>'}</option>
              {this.buildOptions(this.state.lossTypes)}
            </select>
            <FormFeedback className='pl-3'>{getErrorText(validationConfig.incidents.lossType, index)}</FormFeedback>
          </FormGroup>
        </Row>

        <FormGroup>
          <ValidationLabel config={validationConfig.incidents.lossAmount} index={index} className='ml-0 pl-0' />
          <InputGroup className='ml-0 pl-0 col-4 driver-page__dollar'>
            <div className='input-group-prepend'><span className='input-group-text'>$</span></div>
            <Input type={this.state.isIE ? 'text' : 'number'}
              {...getFieldProps(validationConfig.incidents.lossAmount, undefined, index)}
              value={this.state.incidents[index].lossAmount ? this.state.incidents[index].lossAmount : ''}
              maxLength={7}
              pattern='\d*'
              onChange={(e) => this.handleChange(e, index)}
              {...{ onKeyPress: this.onKeyPress }}>
            </Input>
          </InputGroup>
          <FormFeedback className={classNames({ 'd-block': isFieldInvalid(validationConfig.incidents.lossAmount, index) })}>{getErrorText(validationConfig.incidents.lossAmount, index)}</FormFeedback>
        </FormGroup>
      </Fragment>
    )
  }

  render() {
    const { hasAuto } = hasLob(this.props.quote);
    const items = [];

    for (var i = 0; i < this.state.incidents.length; i++) {
      items.push(this.renderIncident(i))
    }

    return (
      <Fragment>
        <h3 className='text-center mb-3'>
          Incidents
        </h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>

              {
                items
              }

              <Col xs='3' sm='5' md='7' className='text-left pl-0 mb-3'>
                <AddButton isDisabled={false} onClick={this.onAddIncident}
                  isHidden={this.state.incidents.length === 7}>
                  Incident
              </AddButton>
              </Col>
            </Form>
          </Swipeable>
        </div>

        {hasAuto &&
          <Col className='text-right pr-0'>
            <AddButton isDisabled={!this.isValid(ShowErrors.NeverShow)} onClick={this.addClickHandler}
              isHidden={this.props.drivers.length === 4}>
              Driver
              </AddButton>
          </Col>
        }
        <hr />
        <Row>          
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler} />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  questionOptions: state.questionOptions.options,
  quote: state.quote,
  drivers: state.quote.drivers
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateDriver: (driver: Driver) => dispatch(QuoteActions.updateDriver(driver)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
})

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(DriverPageIncidents));
