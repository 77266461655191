import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { ApplicationState } from './store';
import { actionCreators as siteActionCreators, Action as SiteAction } from './store/Site/actions';
import { actionCreators as questionOptionsActionCreators, Action as QuestionOptionsAction } from './store/QuestionOptions/actions';
import { actionCreators as startupParametersActionCreators, Action as StartupParametersAction } from './store/StartupParameters/actions';

import Layout from './components/Layout';
import MasterPage from './components/MasterPage';
import Loader from './components/Loader';
import SiteNotFound from './components/SiteNotFound';
import { clearCrqPage } from './services/historyWrapper';

import './custom.scss';
import { LineOfBusinessType } from './store/Quote/state';
import { StepType } from './store/Stepper/state';
import { getQueryStringPage } from './services/helper';
import { StartupParameters } from './store/StartupParameters/state';

type OwnProps = {
  agencyId: number,
  ref1?: string,
  ref2?: string,
  ref3?: string,
  ref4?: string,
  ref5?: string
}

type AppProps =
  OwnProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

interface AppState {
  siteKeyLoaded: boolean;
}


class App extends React.PureComponent<AppProps, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      siteKeyLoaded: false
    }
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    //Start watching for changes
    this.createMutationObserver();

    if (this.props.stepper.active === StepType.None) {
      clearCrqPage();
    }

    if (!this.props.site.isLoading) {

      if (this.props.agencyId !== 0) {
        this.props.requestSite(this.props.agencyId);        
      }

      var siteKey = this.getQueryVariable("sitekey")
      if (this.props.agencyId === 0 && siteKey) {
        this.props.requestSiteBySiteKey(siteKey)

        this.setState({
          siteKeyLoaded: true
        });
      }

    }

    let startupParameters: StartupParameters = {
      agencyId: this.props.agencyId,
      ref1: this.props.ref1 || '',
      ref2: this.props.ref2 || '',
      ref3: this.props.ref3 || '',
      ref4: this.props.ref4 || '',
      ref5: this.props.ref5 || ''
    };
    this.props.setStartupParameters(startupParameters);

    let { agencyId,
      quote: { addressState,
        lineOfBusiness
      } } = this.props;
    if (agencyId !== 0 && addressState !== '' && lineOfBusiness !== '') {
      this.props.requestQuestionOptions(lineOfBusiness, addressState, agencyId);
    }
  }

  public componentDidUpdate(prevProps: AppProps) {
    // If trying to go to a page inside of quote when the active step is None (indicating a new quote) then redirect back to start page.
    if (this.props.stepper.active === StepType.None && getQueryStringPage(window.location) !== '') {
      clearCrqPage();
    }

    let { agencyId,
          quote: { addressState,
        lineOfBusiness
      } } = this.props;

    if ((agencyId !== 0 || this.state.siteKeyLoaded) && addressState !== '' && lineOfBusiness !== '') {
      this.props.requestQuestionOptions(lineOfBusiness, addressState, agencyId);
    }
  }

  contentHeight = 0;

  createMutationObserver() {
    if (window.parent !== window.self) {
      let MutationObserver = window.MutationObserver;
      if (MutationObserver) {
        // watch for changes
        const mutationObserver = new MutationObserver(mutations => {
          // the +2 here is just to account for any rounding differences that might occur and give a tiny bit of padding
          let newHeight = document.documentElement.offsetHeight + 2;
          this.setContentHeight(newHeight);
        });
        mutationObserver.observe(document.body, {
          attributes: true,
          attributeOldValue: false,
          characterData: true,
          characterDataOldValue: false,
          childList: true,
          subtree: true
        });
      }
    }
  }

  setContentHeight(newHeight: number) {
    if (this.contentHeight !== newHeight) {
      this.contentHeight = newHeight;

      window.parent.postMessage({
        topic: 'crqResize',
        height: newHeight
      }, '*');
    }
  }

  getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0].toLowerCase()) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
}

  render() {
    if (this.props.site.isLoading || this.props.questionOptions.isLoading) {
      return (
        <div className="vh-100">
          <Loader />
        </div>
      );
    }
    else if ((this.props.agencyId === 0 && !this.state.siteKeyLoaded) || ((!this.props.site.isLoading && this.props.site.id !== this.props.agencyId)
      && !this.state.siteKeyLoaded) || (isNaN(this.props.site.id) && this.state.siteKeyLoaded)) {
      return <SiteNotFound />;
    }
    else if ( !this.props.site.crqEnabled) {
      return <SiteNotFound />;
    }
    else {
      return (
        <Layout>
          {/*  20 minute session timeout */}
          <MasterPage timeout={1000 * 60 * 20} />
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  questionOptions: state.questionOptions,
  quote: state.quote,
  stepper: state.stepper
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, SiteAction | QuestionOptionsAction | StartupParametersAction>) => ({
  requestSite: (agencyId: number) =>
    dispatch(siteActionCreators.requestSite(agencyId)),
  requestSiteBySiteKey: (siteKey: string) =>
    dispatch(siteActionCreators.requestSiteBySiteKey(siteKey)),
  requestQuestionOptions: (lineOfBusiness: LineOfBusinessType, state: string, agencyId: number) =>
    dispatch(questionOptionsActionCreators.requestQuestionOptions(lineOfBusiness, state, agencyId)),
  setStartupParameters: (startupParameters: StartupParameters) =>
    dispatch(startupParametersActionCreators.setStartupParameters(startupParameters))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
