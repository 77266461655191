import React, { Fragment } from 'react';
import { Row, Col, Label, Input, Form, FormGroup, FormFeedback, InputGroup, Button, ButtonGroup } from 'reactstrap';
import { AiOutlineLock } from 'react-icons/ai';
import { GoEye, GoEyeClosed } from 'react-icons/go';
import axios, { AxiosError } from 'axios';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../store';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import { gender, Driver, LineOfBusinessType, Incident } from '../../store/Quote/state';
import { QuestionOption } from '../../store/QuestionOptions/state';
import PreviousNextButtons from '../PreviousNextButtons';
import ValidationLabel from '../ValidationLabel';
import AddButton from '../AddButton';
import HelpText from '../HelpText';
import Swipeable from '../Swipeable';
import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import { getBaseUrl, hasLob } from '../../services/helper';
import classNames from 'classnames';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import errorResponseHandler from '../../services/errorHandling';

type occupationIndustry = {
  optionText: string,
  optionNum: number;
}

type OwnProps = {
  currentIndex: number,
  onPreviousClick?: () => void,
  onNextClick?: () => void,
  onAddClick?: () => void,
  handleIncidents?: (handleIncidents: boolean) => void
}

type DriverPagePrivateQuestionsProps =
  ValidationProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface DriverPagePrivateQuestionsState {
  gender: gender,
  maritalStatus: string,
  ssn: string,
  driversLicenseNum: string,
  employmentIndustry: string,
  occupation: string,
  hasIncidents: boolean | null,
  ssnMasked: string,
  occupationIndustriesDropDown: occupationIndustry[],
  occupationIndustryNum: number,
  occupationsDropDown: string[],
  maritalStatusDropDown: string[],
  driversLicenseMaskShow: boolean
}

class DriverPagePrivateQuestions extends React.PureComponent<DriverPagePrivateQuestionsProps, DriverPagePrivateQuestionsState> {
  constructor(props: any) {
    super(props);

    let currentDriver = this.props.drivers[this.props.currentIndex];

    if (!currentDriver) {
      throw new Error('Driver was not found');
    }

    this.state = {
      gender: currentDriver.gender,
      maritalStatus: currentDriver.maritalStatus,
      ssn: currentDriver.ssn,
      driversLicenseNum: currentDriver.driversLicenseNum,
      employmentIndustry: currentDriver.employmentIndustry,
      occupation: currentDriver.occupation,
      hasIncidents: currentDriver.hasIncidents,
      ssnMasked: '',
      occupationIndustriesDropDown: [],
      occupationIndustryNum: 0,
      occupationsDropDown: [],
      maritalStatusDropDown: [],
      driversLicenseMaskShow: true
    }

    this.previousClickHandler = this.previousClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.addClickHandler = this.addClickHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSSN = this.handleSSN.bind(this);
    this.handleIncidents = this.handleIncidents.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.populateOccupations = this.populateOccupations.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isValidForAdd = this.isValidForAdd.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
    this.toggleDriversLicenseMask = this.toggleDriversLicenseMask.bind(this);
    this.getValidationObjectForAdd = this.getValidationObjectForAdd.bind(this);

    this.props.setIsValidFunc(this.isValid);
  }

  getValidationObject = (): any => {
    return {
      gender: this.state.gender,
      maritalStatus: this.state.maritalStatus,
      ssn: this.state.ssn,
      employmentIndustry: this.state.employmentIndustry,
      occupation: this.state.occupation,
      hasIncidents: this.state.hasIncidents,
    };
  };

  getValidationObjectForAdd = (): any => {
    var basic = this.getValidationObject();
    let currentDriver = this.props.drivers[this.props.currentIndex];

    return {
      ...currentDriver,
      ...basic,     
      incidents: (this.state.hasIncidents === true && currentDriver!.incidents.length === 0) ? [new Incident()] : currentDriver!.incidents
    };
  }

  componentWillUnmount() {
    this.updateDriver();
  }

  componentDidUpdate() {
    this.isValid(ShowErrors.ShowIfVisible);

    if (this.props.handleIncidents) {
      this.props.handleIncidents(this.state.hasIncidents ? this.state.hasIncidents : false);
    }

    if (this.state.occupationIndustriesDropDown.length < 1) {
      this.populateOccupationIndustries();
      this.handleSSN(this.state.ssn);
    }
  }

  previousClickHandler() {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  }

  nextClickHandler() {
    if (this.props.onNextClick && this.isValid()) {
      this.props.onNextClick();
    }
  }

  addClickHandler() {
    if (this.props.onAddClick) {
      this.props.onAddClick();
    }
  }

  updateDriver() {
    let currentDriver = this.props.drivers[this.props.currentIndex];

    if (currentDriver) {
      this.props.updateDriver({
        ...currentDriver,
        gender: this.state.gender,
        maritalStatus: this.state.maritalStatus,
        ssn: this.state.ssn,
        driversLicenseNum: this.state.driversLicenseNum,
        employmentIndustry: this.state.employmentIndustry,
        occupation: this.state.occupation,
        hasIncidents: this.state.hasIncidents
      });
    }
  }

  isValid(showErrors: ShowErrors = ShowErrors.AlwaysShow): boolean {
    return this.props.validation(this.getValidationObject(), validationLevel.Drivers).isPageValid(showErrors, this.props.currentIndex);
  }

  isValidForAdd() {
    return this.props.validation(this.getValidationObjectForAdd(), validationLevel.Drivers).isPageValid(ShowErrors.NeverShow, this.props.currentIndex);
  }

  handleChange(event: { target: { name: string; value: string; }; }) {
    const { name, value } = event.target
    this.setState({
      ...this.state,
      [name]: value
    });
  }

  handleIncidents(event: { target: HTMLInputElement; }) {
    let hasIncidents = event.target.value.toLowerCase() === 'true';

    this.setState({
      hasIncidents: hasIncidents
    });

    if (!hasIncidents) {
      let currentDriver = this.props.drivers[this.props.currentIndex];

      if (currentDriver) {
        this.props.updateDriver({
          ...currentDriver,
          incidents: []
        })
      }
    }

    if (this.props.handleIncidents) {
      this.props.handleIncidents(hasIncidents);
    }
  }

  handleIndustryChange(event: { target: { name: string; value: string; }; }) {
    const { name, value } = event.target;
    var selectedIndustry;

    for (var i = 0; i < this.state.occupationIndustriesDropDown.length; i++) {
      if (this.state.occupationIndustriesDropDown[i].optionText === value) {
        selectedIndustry = this.state.occupationIndustriesDropDown[i];
        break;
      }
    }

    if (selectedIndustry) {
      this.setState({
        ...this.state,
        [name]: value,
        occupation: '',
        occupationIndustryNum: selectedIndustry.optionNum
      }, () => {
        this.populateOccupations();
      });
    }
  }

  toggleDriversLicenseMask() {
    this.setState({
      driversLicenseMaskShow: !this.state.driversLicenseMaskShow
    })
  }

  handleSSN(input: string) {
    if (input.length < this.state.ssnMasked.length) {
      var difference = this.state.ssnMasked.length - input.length;
      this.setState({
        ssn: this.state.ssn.substr(0, this.state.ssn.length - difference),
        ssnMasked: this.state.ssnMasked.substr(0, this.state.ssnMasked.length - difference)
      })
      return;
    }

    var nextNumber = input.substr(input.length - 1);
    var currentSSN = (this.state.ssn + nextNumber).replace(/[^0-9]/g, '');
    var maskedSSN = '';

    for (var i = 0; i < currentSSN.length; i++) {
      if (maskedSSN.length < 5) {
        maskedSSN += '*';
      }
      else if (maskedSSN.length < currentSSN.length - 1) {
        maskedSSN += currentSSN.substr(i, 1);
      }
      else {
        maskedSSN += nextNumber;
      }
    }

    if (currentSSN.length > 5) {
      currentSSN = currentSSN.substr(0, 3) + '-' + currentSSN.substr(3, 2) + '-' + currentSSN.substr(5, 4);
      maskedSSN = maskedSSN.substr(0, 3) + '-' + maskedSSN.substr(3, 2) + '-' + maskedSSN.substr(5, 4);

    }
    else if (currentSSN.length <= 5 && currentSSN.length > 3) {
      currentSSN = currentSSN.substring(0, 3) + '-' + currentSSN.substr(3, 2);
      maskedSSN = maskedSSN.substring(0, 3) + '-' + maskedSSN.substr(3, 2);
    }

    this.setState({
      ssn: currentSSN,
      ssnMasked: maskedSSN
    })
  }

  populateOccupationIndustries() {
    let industries: occupationIndustry[] = [];
    axios.get(getBaseUrl() + '/occupationIndustry')
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          industries[i] = {
            optionText: response.data[i].optionText,
            optionNum: response.data[i].orderNum
          }
        }
        this.setState({
          occupationIndustriesDropDown: industries
        }, () => {
          if (this.state.employmentIndustry !== '') {
            const item = this.state.occupationIndustriesDropDown.find(i => i.optionText === this.state.employmentIndustry);
            if (item) {
              this.setState({
                occupationIndustryNum: item!.optionNum
              }, () => {
                 this.populateOccupations();
              });
            }
          }
        });
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }

  populateOccupations() {
    let occupations: string[] = [];
    axios.get(getBaseUrl() + '/occupation/' + this.state.occupationIndustryNum)
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          occupations[i] = response.data[i].optionText;
        }
        this.setState({
          occupationsDropDown: occupations,
        }, () => {
          if (occupations.length === 1) {
            this.setState({
              occupation: occupations[0]
            })
          }
        });
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }

  buildOptions(optionsArray: string[]) {
    var optionsJSX = [];
    for (var i = 0; i < optionsArray.length; i++) {
      optionsJSX.push(<option key={optionsArray[i]} value={optionsArray[i]}>{optionsArray[i]}</option>)
    }
    return optionsJSX;
  }

  render() {
    const { getErrorText, isFieldInvalid, getFieldProps } = this.props.validation(this.getValidationObject(), validationLevel.Drivers);
    const occupationDisabled = this.state.employmentIndustry === '' || this.state.employmentIndustry === '<select>';
    let dlIcon: any = <GoEye />;
    let dlTitle: string = 'show';
    const genderInvalid = isFieldInvalid(validationConfig.drivers.gender, this.props.currentIndex);
    const { hasAuto } = hasLob(this.props.quote);

    if (!this.state.driversLicenseMaskShow) {
      dlIcon = <GoEyeClosed />;
      dlTitle = 'hide';
    }

    return (
      <Fragment>
        <h3 className='text-center mb-3'>
          A few more questions
        </h3>

        <div className='row justify-content-center align-items-center'>
          <Swipeable className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 bg-light pt-2'
            onSwipeLeft={this.nextClickHandler} onSwipeRight={this.previousClickHandler}>
            <Form>
              <FormGroup>
                <Label>{validationConfig.drivers.gender.display}:</Label>
                <div>
                  <ButtonGroup className={classNames('btn-group-toggle driver-page__gender', { 'is-invalid': genderInvalid })}>
                    {
                      (this.props.questionOptions["Gender"] || [])
                        .map((option: QuestionOption, index: number) => (
                          <label className={classNames('btn btn-option', { active: option.text === this.state.gender })} data-toggle='buttons' key={index} data-testid={`gender${option.value}`}>
                            <input type="radio" name={validationConfig.drivers.gender.fieldName} onChange={this.handleChange} value={option.text}
                              autoFocus={(this.state.gender === '' && index === 0) || (this.state.gender !== '' && option.text === this.state.gender)} />
                            {option.text}
                          </label>
                        ))
                    }
                  </ButtonGroup>
                  <FormFeedback>{getErrorText(validationConfig.drivers.gender, this.props.currentIndex)}</FormFeedback>
                </div>
              </FormGroup>
              <div className='form-row'>
                <FormGroup className='col-md-9'>
                  <ValidationLabel config={validationConfig.drivers.maritalStatus} index={this.props.currentIndex} />
                  <select {...getFieldProps(validationConfig.drivers.maritalStatus, 'custom-select', this.props.currentIndex)}
                    onChange={this.handleChange}
                    value={this.state.maritalStatus}>
                    <option value=''>{'<select>'}</option>
                    {
                      (this.props.questionOptions['MaritalStatus'] || []).map((option: QuestionOption, index: number) => (
                        <option key={index} value={option.value}>{option.text}</option>
                      ))
                    }
                  </select>
                  <FormFeedback>{getErrorText(validationConfig.drivers.maritalStatus, this.props.currentIndex)}</FormFeedback>
                </FormGroup>
              </div>
              <div className='form-row'>
                <FormGroup className={classNames('col-md-9', { 'd-none': this.props.site.hideSSN })}>
                  <ValidationLabel config={validationConfig.drivers.ssn} index={this.props.currentIndex}><AiOutlineLock style={{ marginBottom: '4px' }} />{validationConfig.drivers.ssn.display}: <small>(optional)</small></ValidationLabel>
                  <HelpText text={validationConfig.drivers.ssn.helpText} helpId='ssn' />
                  <Input type='text'
                    onChange={(e) => this.handleSSN(e.target.value)}
                    pattern='[0-9]*'
                    maxLength={11}
                    value={this.state.ssnMasked}
                    {...getFieldProps(validationConfig.drivers.ssn, undefined, this.props.currentIndex)}></Input>
                  <FormFeedback>{getErrorText(validationConfig.drivers.ssn, this.props.currentIndex)}</FormFeedback>
                </FormGroup>
              </div>
              <div className='form-row'>
                <FormGroup className={classNames('col-md-9', { 'd-none': this.props.site.hideDriverLicense })}>
                  <Label for='driversLicenseNum'><AiOutlineLock style={{ marginBottom: '4px' }} />Driver License Number: <small>(optional)</small></Label>
                  <HelpText text='Drivers License number is optional, however entering driver’s license helps in providing accurate results.' helpId='dl'/>
                  <InputGroup>
                    <Input type={this.state.driversLicenseMaskShow ? 'password' : 'text'}
                      id='driversLicenseNum'
                      className='form-control'
                      onChange={this.handleChange}
                      value={this.state.driversLicenseNum}
                      name='driversLicenseNum'
                      data-testid='driversLicenseNum'
                      maxLength={25} />
                      <Button outline color='secondary' onClick={this.toggleDriversLicenseMask} tabIndex={-1} title={dlTitle} data-testid='DLInputVisible'>
                        {dlIcon}
                      </Button>
                  </InputGroup>
                </FormGroup>
              </div>
              <FormGroup>
                <ValidationLabel config={validationConfig.drivers.employmentIndustry} index={this.props.currentIndex} />
                <select {...getFieldProps(validationConfig.drivers.employmentIndustry, 'custom-select', this.props.currentIndex)}
                  onChange={this.handleIndustryChange}
                  value={this.state.employmentIndustry}>
                  {this.buildOptions(this.state.occupationIndustriesDropDown.map(i => i.optionText))}
                </select>
                <FormFeedback>{getErrorText(validationConfig.drivers.employmentIndustry, this.props.currentIndex)}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <ValidationLabel config={validationConfig.drivers.occupation} index={this.props.currentIndex} />
                <select {...getFieldProps(validationConfig.drivers.occupation, 'custom-select', this.props.currentIndex)}
                  onChange={this.handleChange}
                  value={this.state.occupation} disabled={occupationDisabled}>
                  { (this.state.occupationsDropDown.length !== 1) &&
                    < option > { '<select>'}</option>
                  }
                  {this.buildOptions(this.state.occupationsDropDown)}
                </select>
                <FormFeedback>{getErrorText(validationConfig.drivers.occupation, this.props.currentIndex)}</FormFeedback>
              </FormGroup>
              {
                (this.props.quote.lineOfBusiness === LineOfBusinessType.PersonalAuto || this.props.quote.lineOfBusiness === LineOfBusinessType.Package) &&
                <FormGroup>
                  <ValidationLabel config={validationConfig.drivers.hasIncidents} index={this.props.currentIndex} />
                  <div>
                    <ButtonGroup className={classNames('btn-group-toggle', { 'is-invalid': isFieldInvalid(validationConfig.drivers.hasIncidents, this.props.currentIndex) })} >
                      {
                        ([{ text: 'No', value: 'false' }, { text: 'Yes', value: 'true' }] as QuestionOption[])
                          .map((option: QuestionOption, index: number) => (
                            <label className={classNames('btn btn-option', { active: option.value === this.state.hasIncidents + '' })} data-toggle='buttons' key={index}>
                              <input type="radio" name={validationConfig.drivers.hasIncidents} onChange={this.handleIncidents} value={option.value} />
                              {option.text}
                            </label>
                          ))
                      }
                    </ButtonGroup>
                    <FormFeedback>{getErrorText(validationConfig.drivers.hasIncidents, this.props.currentIndex)}</FormFeedback>
                  </div>
                </FormGroup>
              }             
            </Form>
          </Swipeable>        
        </div>

        {hasAuto &&
          <Col className='text-right pr-0'>
            <AddButton isDisabled={!this.isValidForAdd()} onClick={this.addClickHandler}
              isHidden={this.props.drivers.length === 4}>
              Driver
            </AddButton>
          </Col>
        }
        <hr />
        <Row>
          <Col xs='9' sm='7' md='5' className='text-right offset-3 offset-sm-5 offset-md-7'>
            <PreviousNextButtons onPreviousClick={this.previousClickHandler} onNextClick={this.nextClickHandler} />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  questionOptions: state.questionOptions.options,
  quote: state.quote,
  drivers: state.quote.drivers
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  updateDriver: (driver: Driver) => dispatch(QuoteActions.updateDriver(driver)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
})

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(DriverPagePrivateQuestions));
