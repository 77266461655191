import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { AgencyInfoPosition } from '../store/Site/state';

import './AgencyHeader.scss';
import { useMediaQuery } from 'react-responsive';
import { Row } from 'reactstrap';

type AgencyHeaderProps =
  ReturnType<typeof mapStateToProps>;

class AgencyHeader extends React.PureComponent<AgencyHeaderProps> {

  MobileHeader = () => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });

    let site = this.props.site;

    // show mobile header if on a mobile device AND
    // setup shows logo position as top OR left (hide if configured for iframe)
    if (isMobile &&
        (site.agencyInfoPosition === AgencyInfoPosition.Top || site.agencyInfoPosition === AgencyInfoPosition.Left)) {
      return (
        <div data-testid="agency-header-container" className='text-center'>
          {site.logoDataBase64 &&
            <div data-testid="agency-header-logo" style={{ display: "inline-block" }}>
            <a id='agencyHeader' href={this.props.site.return2URL}>
              <img src={"data:image/image;base64, " + site.logoDataBase64} alt="Agency Logo" style={{ maxWidth: '200px', maxHeight: 'auto', paddingTop: '10px' }}/>
            </a>
            </div>
          }
        </div>
      )
    }
    return null;
  }

  DesktopHeader = () => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });

    var i = new Image();
    i.onload = function () {
      if (i.naturalWidth < i.naturalHeight) {
        if (document.getElementById('agencyLogo')) {
          document.getElementById('agencyLogo')!.style.maxHeight = '350px';
          document.getElementById('agencyLogo')!.style.maxWidth = 'auto';
        }
      }
    }

    i.src = 'data:image/jpg;base64,' + this.props.site.logoDataBase64;

    if (isMobile) {
      return (null);
    }

    let site = this.props.site;
    let quote = this.props.quote;

    let agencyLicense = '';
    if (quote.agencyLicense) {
      agencyLicense = 'Agency ' + quote.addressState + ' License # ' + quote.agencyLicense;
    }

    let containerClassName = 'agency-header__container';
    let infoClassName = 'agency-header__info';

    switch (site.agencyInfoPosition) {
      case AgencyInfoPosition.Top:
        containerClassName += '--top pt-3 align-items-center';
        infoClassName += '--top';
        break;
      case AgencyInfoPosition.Left:
        containerClassName += '--left ml-0 pl-0 pt-3';
        infoClassName += '--left pt-1';
        break;
      default:
        containerClassName = '';
        infoClassName = '';
    }

    if (site.agencyInfoPosition === AgencyInfoPosition.Top || site.agencyInfoPosition === AgencyInfoPosition.Left) {
      return (
        <Row data-testid="agency-header-container" className={containerClassName}>
          {site.logoDataBase64 &&
            <Row data-testid="agency-header-logo" style={{ display: "inline-block" }}>
            <a id='agencyHeader' href={this.props.site.return2URL}>
              <img id='agencyLogo' src={"data:image/image;base64, " + site.logoDataBase64} alt="Agency Logo" style={{ maxWidth: site.agencyInfoPosition === AgencyInfoPosition.Top ? '350px' : '150px', maxHeight: 'auto' }} />
            </a>
          </Row>
          }
          <Row data-testid="agency-header-info" className={infoClassName}>
            <div className='w-100'>
              {site.agencyName}
            </div>
            {agencyLicense.length > 0 &&
              <div className='w-100'>
                {agencyLicense}
              </div>
            }
            <div className='w-100'>
              {site.address}
            </div>
            {site.address2 &&
              <div className='w-100' data-testid="agency-header-address2">
                {site.address2}
              </div>
            }
            <div className='w-100'>
              {site.cityStateZip}
            </div>
            {site.phone &&
              <div className='w-100' data-testid="agency-header-phone">
              <a href={"tel:+1" + site.phone}>{site.phone}</a>
              </div>
            }
          </Row>
        </Row>
      );
    }
    else {
      return null;
    }
  }

  public render() {
    return (     
      <Fragment>
        <this.MobileHeader />
        <this.DesktopHeader />
      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  site: state.site,
  quote: state.quote
  });

export default connect(
  mapStateToProps
)(AgencyHeader);
