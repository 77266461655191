// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { LineOfBusinessType } from "../Quote/state";

export const initialState: QuestionOptions = {
  isLoading: false,
  agencyId: 0,
  state: '',
  lineOfBusiness: LineOfBusinessType.NotSet,
  options: {}
};

export interface QuestionOption {
  text: string,
  value: string
}

export interface QuestionOptions {
  isLoading: boolean;
  agencyId: number,
  state: string,
  lineOfBusiness: LineOfBusinessType,
  options: Record<string, QuestionOption[]>
}