import { AppThunkAction } from './../';
import { Site } from './state';
import axios, { AxiosResponse, AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling';
import * as Promise from 'promise';
import { getBaseUrl } from '../../services/helper';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export enum Actions {
  REQUEST_SITE = 'REQUEST_SITE',
  RECEIVE_SITE = 'RECEIVE_SITE',
  REQUEST_SITE_BY_SITE_KEY = 'REQUEST_SITE_BY_SITE_KEY'
}

interface RequestSiteAction {
  type: Actions.REQUEST_SITE
}

interface ReceiveSiteAction {
  type: Actions.RECEIVE_SITE;
  site: Site;
}

interface RequestSiteBySiteKeyAction {
  type: Actions.REQUEST_SITE_BY_SITE_KEY
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type Action = RequestSiteAction | ReceiveSiteAction | RequestSiteBySiteKeyAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

var requestSite = (id: number): AppThunkAction<Action> => (dispatch, getState) => {
  // Only load data if it's something we don't already have (and are not already loading)
  const appState = getState();
  if (appState && appState.site && appState.site.id !== id) {
    dispatch({ type: Actions.REQUEST_SITE });

    return axios.get(getBaseUrl() + '/sites/' + id)
      .then((response: AxiosResponse<Site>) => {
        dispatch(receiveSite(response.data));
      })
      .catch((error: AxiosError) => {
        dispatch(receiveSite({} as Site));
        if (error.response && error.response.status !== 404) {
          errorResponseHandler(error);
        }
      });
  }
  else {
    return Promise.resolve();
  }
};

var requestSiteBySiteKey = (siteKey: string): AppThunkAction<Action> => (dispatch, getState) => {
  // Only load data if it's something we don't already have (and are not already loading)
  const appState = getState();
  if (appState && appState.site && appState.site.siteKey !== siteKey) {
    dispatch({ type: Actions.REQUEST_SITE_BY_SITE_KEY });

    return axios.get(getBaseUrl() + '/sites/BySiteKey/' + siteKey)
      .then((response: AxiosResponse<Site>) => {
        dispatch(receiveSite(response.data));
      })
      .catch((error: AxiosError) => {
        dispatch(receiveSite({} as Site));
        if (error.response && error.response.status !== 404) {
          errorResponseHandler(error);
        }
      });
  }
  else {
    return Promise.resolve();
  }
};

var receiveSite = (site: Site): ReceiveSiteAction => {
  return {
    type: Actions.RECEIVE_SITE,
    site: site
  }
};


export const actionCreators = {
  requestSite,
  receiveSite,
  requestSiteBySiteKey
};

